<div class="custom-input">
  <div class="option">
    <div *ngIf="matchCountry">
      <h3>Choose country</h3>
      <app-dropdown-search
        placeholder="Search country"
        [searchable]="true"
        [options]="countryList"
        [selected]="advancedSearch.countryCode"
        (selectedCallback)="selectCountry($event)"
        trailingIcon="chevron-down"
      ></app-dropdown-search>
    </div>
    <div class="top">
      <h3>Add custom input</h3>
      <p>Placeholder text explaining how it works</p>
    </div>

    <app-input
      (inputs)="customInput($event)"
      placeholder="Agent input"
      [value]="advancedSearch.qualification"
    ></app-input>
    <div>
      <p class="body-large">Importance</p>
      <div class="selection-line">
        <div
          [class]="{ active: findIndex() >= i }"
          *ngFor="let qI of qualificationImportance; let i = index"
        >
          <span class="line brand--Lightest"></span>
          <span
            [attr.value]="qI"
            (click)="selectImportancy(qI)"
            class="dot brand--Lightest"
          ></span>
        </div>
      </div>
      <div class="description">
        <p class="baseline_small">Not important</p>
        <p class="baseline_small">Very important</p>
      </div>
    </div>
  </div>
</div>
