<div
  [style]="style"
  class="button body-large"
  [class]="class"
  [attr.disabled]="disable"
>
  <div [class]="{ reverseIcon: leadingIcon === 'refresh' }" [style]="style">
    <i-tabler
      *ngIf="leadingIcon"
      [name]="leadingIcon"
      [class]="{ spinner: spinner }"
    ></i-tabler>
    <p *ngIf="label" class="label">{{ label }}</p>
    <p class="trailingInfo" *ngIf="trailingInfo">{{ trailingInfo }}</p>
    <i-tabler
      *ngIf="trailingIcon"
      [name]="trailingIcon"
      [class]="{ spinner: spinner }"
      matTooltipClass="mat-tooltip "
      matTooltipPosition="left"
      [matTooltip]="tooltipText(trailingIcon)"
    ></i-tabler>
  </div>
</div>
