<ng-container *ngIf="tier">
  <ng-container
    *ngIf="isRecruited(tier.status?.contractStatus); else consultants"
  >
    <app-recruited-steps
      [tier]="tier"
      [trailingIcon]="trailingIcon ? trailingIcon.icon : ''"
      (callbackFunction)="callbackFunction.emit()"
    ></app-recruited-steps>
  </ng-container>

  <ng-template #consultants>
    <app-badge
      (callbackFunction)="callbackFunction.emit()"
      [icon]="getIcon()"
      [label]="getRecruitedStatus().label"
      [color]="getRecruitedStatus().class"
      [trailingIcon]="trailingIcon"
    ></app-badge>
  </ng-template>
</ng-container>
