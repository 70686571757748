import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProProfile, filterOption, list } from 'src/interface/shared.interface';
import { ContextService } from '../platform/context.service';
import { matchConsultants } from 'src/interface/shared.interface';
import { tier } from 'src/interface/shared.interface';

@Injectable({
  providedIn: 'root',
})
export class HelperFunctionsService {
  constructor(private router: Router) {}
  recruitedStatus = [
    'Verification',
    'Prescreening',
    'Interview',
    'Evaluation',
    'Completed',
  ];

  // Adds 'https://' to a URL if it doesn't already start with 'http://' or 'https://'
  addHttpOrHttps(url: string): string {
    if (!/^https?:\/\//i.test(url)) {
      return 'https://' + url;
    }
    return url;
  }

  // Determines the status label based on the tier status
  getStatus(tier: tier) {
    if (['Tier3WithNotice', 'Tier3'].includes(tier.tierStatus)) {
      return 'Premium';
    }
    if (['Tier2WithNotice', 'Tier2'].includes(tier.tierStatus)) {
      return 'Approved';
    }
    return 'Candidate';
  }

  // Calculates and returns the time difference between the given date and the current date
  getCompletedDate(date: string, short?: boolean, amountDays?: number) {
    const date1 = new Date(date);
    const date2 = new Date();
    const diff = Math.abs(date1.getTime() - date2.getTime());
    let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    diffDays = amountDays ?? diffDays;
    if (diffDays > 30) {
      const months = Math.floor(diffDays / 30);
      if (months > 12) {
        const years = Math.floor(months / 12);
        if (years === 1) {
          return years + (short ? ' year' : ' year');
        }
        return years + (short ? ' years' : ' years');
      }
      return months + (short ? 'm' : ' months');
    }
    if (diffDays > 365) {
      return Math.floor(diffDays / 30) + (short ? 'm' : ' months');
    }
    return diffDays + (short ? '' : ' days');
  }

  // Returns the full country name based on the country code
  getCountry(value: string) {
    // Regex to match two uppercase letters (ISO 3166-1 alpha-2 format)
    const regex = /^[A-Z]{2}$/;

    // Validate the format of the country code
    if (!regex.test(value)) {
      return '';
    }
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return value ? regionNames.of(value) : value;
  }

  // Filters the list of candidates based on the selected filter options
  filteringList(filterList: filterOption[], contextService: ContextService) {
    const allCandidatesList = contextService.profileData
      .filter((x) => {
        return filterList.every((list) => {
          if (list.selected.length > 0) {
            if (list.type === 'countryCode') {
              return list.selected.some((z) => x.countryCode === z);
            }
            if (list.type === 'industries') {
              return list.selected.some((z) => x.industryIds.includes(z));
            }
            if (list.type === 'skills') {
              return list.selected.some((z) => x.skillIds.includes(z));
            }

            if (list.type === 'availability') {
              if (['1', '2', '3', '4'].includes(list.monthRange!)) {
                let range = 0;
                if (list.monthRange === '2') {
                  range = 30;
                }
                if (list.monthRange === '3') {
                  range = 45;
                }
                if (list.monthRange === '4') {
                  range = 60;
                }
                const d = new Date();
                d.setDate(d.getDate() + range);
                const selectedDate = new Date(x.availabilityDate);
                if (d.valueOf() > selectedDate.valueOf()) {
                  return true;
                }
              } else {
                if (list.monthRange) {
                  const d = new Date(list.monthRange);
                  const selectedDate = new Date(x.availabilityDate);
                  if (d.valueOf() > selectedDate.valueOf()) {
                    return true;
                  }
                }
              }
              return false;
            }
            if (list.type === 'technologies') {
              return list.selected.some((z) => x.technologyIds.includes(z));
            }
            if (list.type === 'jobFamily') {
              return list.selected.some(
                (y) =>
                  y ===
                  contextService.familiesData.find(
                    (z) => z.displayName === x.jobFamily
                  )?.id
              );
            }
            if (list.type === 'levelWork') {
              return list.selected.some(
                (y) =>
                  y ===
                  contextService.jobLevel.find(
                    (z) => z.displayName === x.levelOfExpertise
                  )?.id
              );
            }
            if (list.type === 'yearExperience') {
              return list.selected.some(
                (y) =>
                  y ===
                  contextService.yearExperience.find(
                    (z) => z.value === x.levelOfExperience
                  )?.id
              );
            }
            if (list.type === 'asConsultant') {
              return list.selected.some(
                (y) =>
                  y ===
                  [
                    { id: '1', value: true },
                    { id: '2', value: false },
                  ].find((z) => z.value === x.previouslyWorkedAsConsultant)?.id
              );
            }
            if (list.type === 'labels') {
              return list.selected.some(
                (y) =>
                  y ===
                  [
                    { label: '7NConsultant', id: '1' },
                    { label: '', id: '2' },
                  ].find((z) => {
                    if (x.labels.length > 0) {
                      return x.labels.includes(z.label);
                    } else {
                      return z.label === '';
                    }
                  })?.id
              );
            }
            if (list.type === 'campaign') {
              return list.selected.some(
                (y) =>
                  y ===
                  contextService.campaign.find(
                    (z) =>
                      (z.empty && !x.campaign) || z.displayName === x.campaign
                  )?.id
              );
            }
          }
          return true;
        });
      })
      .sort((a: any, b: any) => b.sortDate - a.sortDate);

    return allCandidatesList;
  }

  // Navigates to the specified route with optional query parameters
  route(route: string[], query?: object) {
    if (query) {
      this.router.navigate(route, { queryParams: query });
      return;
    }
    this.router.navigate(route, {
      queryParams: {},
    });
  }

  // Converts a text string to a URL-friendly slug
  slugify(text: string) {
    text = text.replace(/^\s+|\s+$/g, ''); // trim
    text = text.toLowerCase();

    // Remove accents, swap ñ for n, etc.
    const from = 'àáäâæåðèéëêìíïîòóöôùúüûñçýø·/_,:;';
    const to = 'aaaaaadeeeeiiiioooouuuuncyo------';
    for (let i = 0, l = from.length; i < l; i++) {
      text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    text = text
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return text;
  }

  // Compares two objects by their creation time
  createdAtTime(a: any, b: any) {
    if (a.createdAtTime > b.createdAtTime) {
      return -1;
    }
    if (a.createdAtTime < b.createdAtTime) {
      return 1;
    }
    return 0;
  }

  // Compares two objects by their display names
  compare(a: any, b: any) {
    if (a.displayName < b.displayName) {
      return -1;
    }
    if (a.displayName > b.displayName) {
      return 1;
    }
    return 0;
  }

  // Filters unique professional profiles based on display name
  uniqueProProfile(array: ProProfile[]) {
    return array.filter((item, pos, self) => {
      return self.findIndex((x) => x.displayName === item.displayName) === pos;
    });
  }

  // Deeply compares two objects for equality
  deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 == null ||
      obj2 == null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  // Converts a date to ISO 8601 format
  getDateISO8601(date: Date) {
    const getMonth = date.getMonth() + 1;
    return (
      date.getFullYear() +
      '-' +
      (getMonth < 10 ? '0' + getMonth : '' + getMonth) +
      '-' +
      (date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate())
    );
  }

  // Gets the current hour in Central European Time (CET)
  getCETTime(): number {
    // Get the current date and time
    const now: Date = new Date();

    // Define options for the CET time zone
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Europe/Berlin',
      hour: '2-digit',
      hour12: false,
    };

    // Get the current hour in CET
    const cetHourString: string = new Intl.DateTimeFormat(
      'en-US',
      options
    ).format(now);

    // Parse the CET hour string to a number
    const cetHour: number = parseInt(cetHourString, 10);

    return cetHour;
  }

  // Formats a timestamp to a readable date and time string
  getDate(time: number, hourMinutes = true, monthYear = false) {
    const d = new Date(0);
    d.setUTCSeconds(time);

    const secondDate: Date = d;
    const month = secondDate.toLocaleString('default', { month: 'short' });

    return (
      (monthYear
        ? ''
        : (secondDate.getDate() < 10 ? '0' : '') + secondDate.getDate()) +
      ' ' +
      month +
      ' ' +
      secondDate.getFullYear() +
      (hourMinutes
        ? ' - ' +
          (secondDate.getHours() < 10 ? '0' : '') +
          secondDate.getHours() +
          ':' +
          (secondDate.getMinutes() < 10 ? '0' : '') +
          secondDate.getMinutes()
        : '')
    );
  }

  // Generates a list of options based on the type and amount
  listOfOptions(type: string, amount?: number, array?: string[]) {
    let list: any[] = [];
    for (let i = 0; i < amount!; i++) {
      list.push(i + 1);
    }
    if (type === 'years') {
      list = [];
      const d = new Date();
      for (let i = 0; i < amount!; i++) {
        list.push(d.getFullYear() + i);
      }
    }
    if (type === 'stringMonth') {
      list = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
    }
    if (type === 'month') {
      list = [
        { monthIndex: 1, id: 1, displayName: 'January', year: '' },
        { monthIndex: 2, id: 2, displayName: 'February', year: '' },
        { monthIndex: 3, id: 3, displayName: 'March', year: '' },
        { monthIndex: 4, id: 4, displayName: 'April', year: '' },
        { monthIndex: 5, id: 5, displayName: 'May', year: '' },
        { monthIndex: 6, id: 6, displayName: 'June', year: '' },
        { monthIndex: 7, id: 7, displayName: 'July', year: '' },
        { monthIndex: 8, id: 8, displayName: 'August', year: '' },
        { monthIndex: 9, id: 9, displayName: 'September', year: '' },
        { monthIndex: 10, id: 10, displayName: 'October', year: '' },
        { monthIndex: 11, id: 11, displayName: 'November', year: '' },
        { monthIndex: 12, id: 12, displayName: 'December', year: '' },
      ];
      const monthList: {
        monthIndex: number;
        id: number;
        displayName: string;
        year: string;
      }[] = [];
      array?.forEach((val) => {
        const d = new Date(val);
        if (!d.getDate()) {
          return;
        }
        let monthInfo = list.find((x: any) => x.id === d.getMonth() + 1);
        monthInfo = JSON.parse(JSON.stringify(monthInfo));
        monthInfo.year = d.getFullYear();
        monthInfo.id = monthInfo.id + '' + d.getFullYear();
        monthList.push(monthInfo);
      });
      return monthList;
    }
    if (type === 'monthDate') {
      list = [
        { monthIndex: 1, id: 1, displayName: 'January', year: '' },
        { monthIndex: 2, id: 2, displayName: 'February', year: '' },
        { monthIndex: 3, id: 3, displayName: 'March', year: '' },
        { monthIndex: 4, id: 4, displayName: 'April', year: '' },
        { monthIndex: 5, id: 5, displayName: 'May', year: '' },
        { monthIndex: 6, id: 6, displayName: 'June', year: '' },
        { monthIndex: 7, id: 7, displayName: 'July', year: '' },
        { monthIndex: 8, id: 8, displayName: 'August', year: '' },
        { monthIndex: 9, id: 9, displayName: 'September', year: '' },
        { monthIndex: 10, id: 10, displayName: 'October', year: '' },
        { monthIndex: 11, id: 11, displayName: 'November', year: '' },
        { monthIndex: 12, id: 12, displayName: 'December', year: '' },
      ];
      const monthList: {
        monthIndex: number;
        id: number;
        displayName: string;
        year: string;
      }[] = [];
      array?.forEach((val) => {
        const d = new Date(val);
        if (!d.getDate()) {
          return;
        }
        let monthInfo = list.find((x: any) => x.id === d.getMonth() + 1);
        monthInfo = JSON.parse(JSON.stringify(monthInfo));
        monthInfo.year = d.getFullYear();
        monthInfo.id =
          d.getFullYear() +
          '-' +
          (monthInfo.id < 10 ? '0' : '') +
          monthInfo.id +
          '-' +
          '01';
        monthList.push(monthInfo);
      });
      return monthList;
    }
    return list;
  }

  // Generates a range of dates between the start and end dates
  dateRange(startDate: string, endDate: string) {
    const start = startDate.split('-');
    const end = endDate.split('-');
    const startYear = parseInt(start[0]);
    const endYear = parseInt(end[0]);
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        const displayMonth = month < 10 ? '0' + month : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }
    return dates;
  }

  // Determines the sales status and corresponding icon and display name
  getSaleStatus(value: { salesStatus: string; displayName: string }) {
    const badgeInfo: {
      icon: { type: string; icon: string };
      displayName: string;
    } = {
      icon: { type: '', icon: '' },
      displayName: '-',
    };
    if (!value?.salesStatus) {
      return badgeInfo;
    }
    if (value.salesStatus.toLowerCase() === 'added') {
      badgeInfo.icon = { type: 'svg', icon: '/assets/svg/check-list.svg' };
      badgeInfo.displayName = 'Added';
    }
    if (value.salesStatus.toLowerCase() === 'contacted') {
      badgeInfo.icon = { type: 'icon', icon: 'send' };
      badgeInfo.displayName = 'Contacted';
    }
    if (value.salesStatus.toLowerCase() === 'cvsent') {
      badgeInfo.icon = { type: 'icon', icon: 'file-cv' };
      badgeInfo.displayName = 'CV Sent';
    }
    if (value.salesStatus.toLowerCase() === 'snterviewscheduled') {
      badgeInfo.icon = { type: 'icon', icon: 'calendar-clock' };
      badgeInfo.displayName = 'Interview Scheduled';
    }
    if (value.salesStatus.toLowerCase() === 'interviewediyiustomer') {
      badgeInfo.icon = { type: 'icon', icon: 'calendar-check' };
      badgeInfo.displayName = 'Interviewed';
    }
    if (value.salesStatus.toLowerCase() === 'customerapproved') {
      badgeInfo.icon = { type: 'icon', icon: 'checks' };
      badgeInfo.displayName = 'Customer Approved';
    }
    if (value.salesStatus.toLowerCase() === 'notsold') {
      badgeInfo.icon = { type: 'icon', icon: 'xbox-x' };
      badgeInfo.displayName = 'Not Sold';
    }
    if (value.salesStatus.toLowerCase() === 'sold') {
      badgeInfo.icon = { type: 'svg', icon: '/assets/svg/seal-check.svg' };
      badgeInfo.displayName = 'Sold';
    }
    return badgeInfo;
  }

  /**
   * Function to remove special characters from a search phrase string
   * Letters, marks, numbers, and space separator are allowed
   * @param strVal
   *
   * Uses Unicode categories
   * https://www.regular-expressions.info/unicode.html#category
   */
  sanitizeSearchPhrase(strVal: string): string {
    const cleaned = strVal.replace(/[^\p{Letter}\p{Mark}\p{Number}\p{Space_Separator}]/gu, ' ');
    return cleaned.replace(/[\s]+/g, ' ').trim();
  }
}
