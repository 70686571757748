<section *ngIf="consultant">
  <div class="bundle-remarks-general">
    <div *ngIf="consultant.remarks" class="block remarks">
      <p class="body-large bold">Remarks</p>
      <div class="message warning--Lighter">
        <div class="icon">
          <i-tabler class="medium" name="info-circle"></i-tabler>
        </div>
        <div class="description body-small">
          There’s a remark on this person - please read the description to know
          more
          <app-button
            trailingIcon="external-link"
            label="Go to description"
            [class]="['ghost', 'link', 'body-small']"
            (click)="gotTo(consultant.crmLink)"
          ></app-button>
        </div>
      </div>
    </div>
    <div class="block general">
      <p class="body-large bold">General information</p>
      <div>
        <div>
          <i-tabler class="medium" name="calendar"></i-tabler>
          <p class="body-small">
            Available
            <span class="bold">{{ getDate(consultant.availability) }}</span>
          </p>
        </div>
        <div>
          <i-tabler class="medium" name="briefcase"></i-tabler>
          <p class="body-small">
            <span class="bold"
              >{{ consultant.experience.yearsOfExperience }} years</span
            >
            of experience
          </p>
        </div>
        <div>
          <i-tabler class="medium" name="cash"></i-tabler>
          <p class="body-small">
            {{ consultant.expectedRate.currency }}
            {{ consultant.expectedRate.ratePerHour }}
            / hour
          </p>
        </div>
        <div class="workLocation">
          <i-tabler class="medium" name="map"></i-tabler>
          <div>
            <p class="body-small">Preferred working:</p>
            <div>
              <div
                *ngFor="
                  let workLocation of consultant.workLocations;
                  let i = index
                "
              >
                <div class="country" (click)="expandStates(i)">
                  <p class="body-small bold">{{ workLocation.name }}</p>
                  <i-tabler
                    *ngIf="workLocation.states.length > 0"
                    class="small"
                    [name]="
                      'chevron-' + (expandState.includes(i) ? 'up' : 'down')
                    "
                  ></i-tabler>
                </div>
                <ng-container *ngIf="expandState.includes(i)">
                  <p
                    class="body-small state"
                    *ngFor="let state of workLocation.states"
                  >
                    {{ state }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bundle-contact-owner">
    <div class="contact block">
      <p class="body-large bold">Contact</p>
      <div>
        <div>
          <i-tabler class="medium" name="mail"></i-tabler>
          <p class="body-small textLink ellipsis" [attr.title]="consultant.email">
            {{ consultant.email }}
          </p>
        </div>
        <div>
          <i-tabler class="medium" name="phone"></i-tabler>
          <p class="body-small">
            {{ consultant.phoneNumber }}
          </p>
        </div>
        <div>
          <i-tabler class="medium" name="map-pin"></i-tabler>
          <p class="body-small">
            {{
              getCity(consultant.location.city) +
              getCountryState(consultant.location.stateOrProvince) +
              getCountry(consultant.location.countryCode)
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="owner block">
      <p class="body-large bold">Owner</p>
      <div>
        <div *ngIf="consultant.salesAgent">
          <app-avatar class="xs" [by]="consultant.salesAgent"></app-avatar>
          <p class="body-small">
            {{ consultant.salesAgent.firstName }}
            {{ consultant.salesAgent.middleName }}
            {{ consultant.salesAgent.lastName }}
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="consultant.matchingType === 'Files'" class="cvFile block">
      <p class="body-large bold">CV File</p>
      <ng-container *ngIf="!consultant.cvFile; else CVFile">
        <p class="body-large textDisabled">No available files</p>
      </ng-container>
      <ng-template #CVFile>
        <app-button
          *ngIf="consultant.cvFile"
          [class]="['brand--Lighter', 'textLink', 'small', 'body-small']"
          [label]="consultant.cvFile.filename"
          [disable]="!consultant.cvFile"
          [trailingIcon]="'download'"
          (click)="this.downloadUrl.emit()"
        ></app-button>
      </ng-template>
    </div>
  </div>
</section>
