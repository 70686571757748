import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo';
import { requestMatches } from 'src/interface/shared.interface';
import { routeParams } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit {
  salesRequests: requestMatches[];
  selectedRequest: requestMatches;
  rawOptions: requestMatches[];
  loading = true;
  requestId: string;
  desAsc: boolean;
  key: string;
  rerender = false;
  constructor(
    private platformService: PlatformService,
    private activeRoute: ActivatedRoute,
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker
  ) {
    if (this.contextService.referrer) {
      this.matomoTracker.trackEvent(
        'Referrer',
        'Rquests - ' + this.contextService.referrer
      );
      this.contextService.referrer = '';
    } else {
      this.matomoTracker.trackEvent('Requests', 'Selected in navigation');
    }
    this.contextService.reRender.subscribe((val) => {
      if (val === 'longShortList' || val === 'sentMessage') {
        this.rerender = !this.rerender;
      }
    });
  }

  // This lifecycle hook initializes the component by fetching the requests list and subscribing to route parameters
  async ngOnInit() {
    // Fetch the list of sales requests from the platform service
    await this.platformService.getRequestsList().then((response) => {
      this.salesRequests = response.data;
    });

    // Subscribe to route parameters to handle deeplinks and set the request ID
    this.activeRoute.params.subscribe((params) => {
      params = params as routeParams;
      if (params['deeplink']) {
        // Find the request that matches the deeplink parameter
        const findRequest = this.salesRequests.find(
          (x) => x.id.toLowerCase() === params['deeplink'].toLowerCase()
        );
        if (findRequest) {
          this.requestId = findRequest.id;
        }
      } else {
        this.loading = false;
      }
    });
  }

  // Compares two objects based on a specified key for sorting purposes
  compare(a: any, b: any, key: string) {
    // Handle string comparisons for specific keys
    if (['title', 'customerName', 'owner'].includes(key)) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) {
        return -1;
      }
      if (a[key].toLowerCase() > b[key].toLowerCase()) {
        return 1;
      }
      return 0;
    }
    // Handle numeric comparisons for other keys
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  }

  // Sorts the sales requests based on a specified key and toggles ascending/descending order
  sort(value: string) {
    this.desAsc = !this.desAsc;

    // Sort the sales requests using the compare function
    this.salesRequests.sort((a: requestMatches, b: requestMatches) => {
      return this.desAsc
        ? this.compare(a, b, value)
        : this.compare(b, a, value);
    });
  }

  // Routes to the matching page with the selected request ID
  searchInMathing() {
    this.helperFunctions.route(['matching', 'rtc', this.selectedRequest.id]);
  }

  // Routes to the request details page with the given request ID
  openRequest(id: string) {
    this.helperFunctions.route(['requests', id]);
  }

  // Formats and returns a date using the helper functions, or returns a dash if no date is provided
  getDate(date?: number) {
    if (date) {
      return this.helperFunctions.getDate(date);
    }
    return '-';
  }

  // Filters the sales requests based on a search value
  search(value: string) {
    if (!this.rawOptions) {
      this.rawOptions = this.salesRequests;
    }

    if (value.length === 0) {
      this.salesRequests = [];
    }
    if (value.length === 0) {
      this.salesRequests = this.rawOptions;
      return;
    }

    // Filter the sales requests based on title, customer name, or owner
    this.salesRequests = this.rawOptions.filter((x) => {
      const findbyTitle = x.title
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(value.toLowerCase().replace(/\s/g, ''));
      const findbyCustomerName = x.customerName
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(value.toLowerCase().replace(/\s/g, ''));
      const findbyOwner = x.owner
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(value.toLowerCase().replace(/\s/g, ''));

      // Return true if any of the fields match the search value
      if (!findbyTitle) {
        if (!findbyCustomerName) {
          return findbyOwner;
        }
        return findbyCustomerName;
      }
      return findbyTitle;
    });
  }
}
