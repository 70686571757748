<div class="profile">
  <div class="level0 surface--1">
    <div class="image">
      <i-tabler class="file-text" name="file-text"></i-tabler>
    </div>
    <app-sale-request
      style="width: 100%"
      *ngIf="selectedSalesRequest"
      [selectedRequest]="selectedSalesRequest"
    ></app-sale-request>
    <div *ngIf="selectedRequest" class="info">
      <div>
        <div class="title">
          <h3>{{ selectedRequest.title }}</h3>
          <p>{{ selectedRequest.customer.name }}</p>
        </div>
        <div class="cta">
          <app-cta
            *ngIf="freelanceAddedToRequest"
            [imgIcon]="'/assets/svg/list-check.svg'"
            classes="success--Lighter"
          >
          </app-cta>
          <app-cta
            classes="brand--Lightest textLink"
            [icon]="'file-text'"
            (click)="openInternal($event)"
          >
          </app-cta>
          <app-cta
            classes="brand--Lightest textLink"
            *ngIf="!freelanceAddedToRequest"
            [icon]="'text-plus'"
            (click)="openMatchModal($event)"
          >
          </app-cta>

        </div>
      </div>

      <div class="details body-small">
        <p class="">
          <span>Starting on </span>
          {{ ifEmptyReturnDash(selectedRequest.contractStartDate) }}
        </p>
        <li></li>
        <app-badge
          class="only-first-letter"
          [label]="selectedRequest.priority"
          [color]="getColorType(selectedRequest.priority)"
        ></app-badge>
        <li></li>
        <app-badge
          *ngIf="getSaleStatus(selectedRequest.highestSalesStatus)"
          [label]="
            getSaleStatus(selectedRequest.highestSalesStatus).displayName
          "
          [color]="'neutral--Grey-300 textPrimary'"
          [icon]="getSaleStatus(selectedRequest.highestSalesStatus).icon"
        ></app-badge>
        <p class="body-large">
          {{ selectedRequest.enlistedProfiles.length }} <span>candidates</span>
        </p>
      </div>
    </div>
  </div>
</div>
