<div [class]="{ disabled: disabled }" class="select-items">
  <div *ngIf="type === 'toggle'">
    <mat-slide-toggle
      (change)="changeSlider.emit($event)"
      [ngModel]="isChecked"
    ></mat-slide-toggle>
  </div>
  <div class="radio" *ngIf="type === 'radio'">
    <div class="container">
      <input [checked]="isChecked" type="checkbox" />
      <span class="checkmark"></span>
    </div>
    <p class="body-small">{{ label }}</p>
  </div>
  <div (click)="click()" class="checkbox" *ngIf="type === 'checkbox'">
    <div class="container">
      <input [ngModel]="isChecked" type="checkbox" />
      <span class="checkmark">
        <i-tabler name="check"></i-tabler>
      </span>
      <p class="body-small" *ngIf="label">{{ label }}</p>
    </div>
  </div>
</div>
