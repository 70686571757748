<div *ngIf="message.show" class="notification body2" [class]="message.type" >
  <i-tabler class="icon" [name]="message.icon"></i-tabler>
  <div class="description">
    {{message.description}}
  </div>
  <i-tabler (click)="message.show = false" name="X"></i-tabler>
</div>
<div *ngIf="message.show1" class="notification1 body2" [class]="message.type">
  <div>
    <i-tabler class="icon" [name]="message.icon"></i-tabler>
    <div class="description">
      {{message.description}}
    </div>
  </div>
  <i-tabler (click)="message.show1 = false" name="X"></i-tabler>
</div>
