import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IGetConsultantsResponse,
  matchConsultants,
  requestMatchesLevels1,
} from 'src/interface/shared.interface';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-search-consultant',
  templateUrl: './search-consultant.component.html',
  styleUrls: ['./search-consultant.component.scss'],
})
export class SearchConsultantComponent {
  allconsultants: matchConsultants[] = [];
  timeout: NodeJS.Timeout;
  loading: boolean;
  @Input() addable: boolean;
  @Input({ required: true }) selectedRequest: requestMatchesLevels1 | undefined;
  @Input() toggleDropdown: boolean;
  @Output() selectconsultant = new EventEmitter<matchConsultants>();
  constructor(private platformService: PlatformService) {}
  dataValidator(consultant: matchConsultants) {
    return consultant.firstName;
  }
  select(selectconsultant: matchConsultants) {
    if (
      selectconsultant.added ||
      (!selectconsultant.applicableForC2ROrC2C && !this.addable)
    ) {
      return;
    }
    this.selectconsultant.emit(selectconsultant);
    this.toggleDropdown = false;
  }
  async getconsultants(searchString = '') {
    this.loading = true;

    // TODO: for now it is impossible to get the consultants with the profiletype of 'files' and 'cv' in one request
    const consultantsTypeCv = new Promise<IGetConsultantsResponse>((resolve) => {
      this.platformService
      .getAllConsultants(`search=${searchString}&limit=20&profiletype=cv`)
        .then((response) => {
          resolve(response);
        });
    });
    const consultantsTypeFile = new Promise<IGetConsultantsResponse>((resolve) => {
      this.platformService
      .getAllConsultants(`search=${searchString}&limit=20&profiletype=files`)
        .then((response) => {
          resolve(response);
        });
    });

    await Promise.all([consultantsTypeCv, consultantsTypeFile])
    .then((values: [IGetConsultantsResponse, IGetConsultantsResponse]) => {
      const allConsultants: IGetConsultantsResponse = {
        elements: [...values[0].elements, ...values[1].elements],
        pagination: values[0].pagination || values[1].pagination, // it doesn't metter which one to take, the pagination data is not used here
      };

      this.allconsultants = allConsultants?.elements?.map((x) => {
        x.added = x.shortlistedSalesRequests.some(
          (z) => z.id === this.selectedRequest?.id
        );
        return x;
      }).sort((a, b) => {
        return `${a.firstName} ${a.lastName}`.toLocaleLowerCase().localeCompare(`${b.firstName} ${b.lastName}`.toLocaleLowerCase());
      });

      this.loading = false;
    }).catch((error: Error) => {
      console.error(error);
    });
  }

  searchConsultant(value: string) {
    clearTimeout(this.timeout);
    if (value.length > 1) {
      this.timeout = setTimeout(() => {
        this.getconsultants(value);
      }, 200);
    } else {
      this.allconsultants = [];
    }
  }
}
