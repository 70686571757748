import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { timeline } from 'src/interface/shared.interface';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @Input() consultantId: string;
  timelines: [];
  ascDesc: boolean;
  constructor(private platformService: PlatformService) {}

  ngOnInit(): void {
    this.platformService.getTimeline(this.consultantId).then((response) => {
      this.timelines = response;
    });
  }

  sortByReverse() {
    this.ascDesc = !this.ascDesc;
    this.timelines.reverse();
  }
}
