import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProProfile, type } from 'src/interface/shared.interface';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent {
  @Input() selectedArray: number[] = [];
  @Input() selected = false;
  @Input() type: keyof type = 'jobFamily';
  @Input() data: ProProfile = { id: '0', displayName: '' };
  @Input() loop = 0;
  @Input() single = false;
  @Input() noIcon = false;
  @Input() maxSelection = -1;
  @Input() styles: string;
  @Output() callbackFunction = new EventEmitter<{
    id: string;
    type?: string;
    single?: boolean;
  }>();

  clicked() {
    if (this.type === 'monthRange') {
      this.callbackFunction.emit({ id: this.data.id });
      return;
    }
    this.callbackFunction.emit({
      id: this.data.id,
      type: this.type,
      single: this.single,
    });
  }
}
