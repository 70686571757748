<div class="replyMessage">
  <app-loader [loading]="loading"></app-loader>
  <ng-container *ngIf="reply">
    <div class="repond">
      <div class="left-content">
        <div class="profile">
          <div class="image">
            <i-tabler
              *ngIf="['PendingReply', 'NoReply'].includes(reply.status)"
              name="clock-hour-3"
            ></i-tabler>
            <p *ngIf="['Interested', 'NotInterested'].includes(reply.status)">
              {{ reply.fullName[0] }}
            </p>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right-content">
        <div *ngIf="['PendingReply'].includes(reply.status)" class="top">
          <p class="label2">Waiting for reply</p>
          <p class="body2 date"></p>
        </div>
        <div *ngIf="['NoReply'].includes(reply.status)" class="top">
          <p class="label2">No reply from {{ reply.fullName }}</p>
          <p class="body2 date"></p>
        </div>
        <div *ngIf="['Interested', 'NotInterested'].includes(reply.status)">
          <div class="top">
            <p class="label2">{{ reply.fullName }}</p>
            <p class="body2 date">
              Recieved: {{ getDate(reply.responseTime) }}
            </p>
          </div>
          <div class="message">
            <div>
              <p class="label2">Match:</p>
              <p class="body2">{{ match(reply.response) }}</p>
            </div>
            <div *ngIf="reply.declaredAvailability">
              <p class="label2">When would you be available:</p>
              <p class="body2">
                {{ getDate(reply.declaredAvailability, "iso") }}
              </p>
            </div>
            <div *ngIf="reply.responseText">
              <p class="label2">Why is the match not good:</p>
              <p class="body2">{{ reply.responseText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agent-message">
      <div class="left-content">
        <div class="profile">
          <div class="image">7N</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right-content">
        <div class="top">
          <p class="label2">7N Agent</p>
          <p class="body2 date">Sent: {{ getDate(reply.createdAt) }}</p>
        </div>
        <div class="message">
          <div
            class="body2"
            *ngIf="reply"
            [innerHTML]="reply.emailMessage"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
