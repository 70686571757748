<div class="modal-overlay">
  <div class="modal" [class]="type">
    <div class="header">
      <h2 class="subtitle1">
        <i-tabler
          *ngIf="content.icon.name"
          [name]="content.icon.name"
          [class]="content.icon.class"
        ></i-tabler
        >{{ content.headline }}
      </h2>
      <div class="close" (click)="closeModal()">
        <i-tabler name="X"></i-tabler>
      </div>
    </div>
    <div
      *ngIf="['request', 'add-request'].includes(type)"
      class="modal-content"
    >
      <app-add-request
        [selectedRequest]="selectedRequest"
        (selectRequest)="setSelectedRequest($event)"
        (manualRequest)="manualInput($event)"
      ></app-add-request>
    </div>
    <div
      *ngIf="['filters', 'match-filters'].includes(type)"
      class="modal-content"
    >
      <app-filter-list
        [iterateId]="contextService.iterateId"
        [matchId]="contextService.matchId"
        [filterLevel]="type === 'filters'"
        [modal]="true"
      >
      </app-filter-list>
    </div>
    <div *ngIf="type === 'advancedSearch'" class="modal-content">
      <app-advanced-search></app-advanced-search>
    </div>
    <div *ngIf="['ctr', 'ctc'].includes(type)" class="modal-content">
      <app-find-consultant
        (selected)="selectedConsultant = $event; selected()"
      ></app-find-consultant>
    </div>
    <div
      *ngIf="['anotherLonglist', 'longlist'].includes(type)"
      class="modal-content"
    >
      <app-longlist-content
        (selected)="selectRequest($event)"
      ></app-longlist-content>
    </div>
    <div
      *ngIf="['addconsultant', 'addAnotherconsultant'].includes(type)"
      class="modal-content"
    >
      <app-loader [loading]="loading" *ngIf="loading"></app-loader>
      <app-consultant-content
        *ngIf="!loading"
        (selected)="selectedConsultant = $event; selected()"
      ></app-consultant-content>
    </div>
    <div *ngIf="type === 'addNotes'" class="modal-content">
      <app-add-notes-content
        (callbackFunction)="input($event)"
        [note]="note"
      ></app-add-notes-content>
    </div>
    <div *ngIf="type === 'messaging'" class="modal-content">
      <div *ngIf="sending" class="sending">
        <p class="subtitle1">Sending message</p>
        <app-loader [loading]="true"></app-loader>
      </div>
      <app-messaging-content
        (validateButton)="validateButtons($event)"
        *ngIf="!sending"
        [selectedStep]="selectedStep"
      ></app-messaging-content>
    </div>
    <div class="modal-content description" *ngIf="content.description">
      <p class="textSecondary body-small">{{ content.description }}</p>
    </div>
    <div *ngIf="type === 'replyMessage'" class="modal-content">
      <app-reply-message-content></app-reply-message-content>
    </div>
    <div *ngIf="buttons.length > 0" class="footer">
      <app-button
        (click)="action(buttons[0])"
        [class]="[buttons[0].class!]"
        [label]="buttons[0].title"
        [disable]="buttons[0].disable! || sending"
      ></app-button>
      <div *ngIf="buttons.length > 2; else twobutton">
        <app-button
          (click)="action(buttons[1])"
          [class]="[buttons[1].class!]"
          [label]="buttons[1].title"
          [disable]="buttons[1].disable! || sending"
        ></app-button>
        <app-button
          (click)="action(buttons[2])"
          [class]="[buttons[2].class!]"
          [label]="buttons[2].title"
          [disable]="buttons[2].disable! || sending"
        ></app-button>
      </div>
      <ng-template #twobutton>
        <app-button
          *ngIf="buttons[1]"
          (click)="action(buttons[1])"
          [class]="[buttons[1].class!]"
          [label]="buttons[1].title"
          [disable]="buttons[1].disable! || sending"
        ></app-button>
      </ng-template>
    </div>
  </div>
</div>
