import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { option } from 'src/interface/shared.interface';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnChanges {
  @Input() selectedCvType: number;
  @Input() disable: boolean;
  @Input() filterLevel: boolean;
  @Input() matchId: string | undefined;
  @Input() iterateId: number | null;
  @Input() selectedSearchType: number;
  @Input() selectedFilters: option[] = [];
  @Input() selectedNav: string;
  filterLength = 0;
  openFilter: boolean;
  constructor(private contextService: ContextService) {}

  openModal() {
    if (this.matchId) {
      this.contextService.iterateId = this.iterateId;
      this.contextService.matchId = this.matchId;
      this.contextService.openMatchModal = this.filterLevel
        ? 'filters'
        : 'match-filters';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedCvType']) {
      this.getLength(this.selectedFilters);
    }
    if (changes['selectedFilters']) {
      this.getLength(this.selectedFilters);
    }
  }
  getLength(selectedFilters: option[]) {
    if (this.selectedCvType === 1) {
      this.filterLength = selectedFilters.filter(
        (x) => x.type !== 'keywords'
      ).length;
      return;
    }
    this.filterLength = selectedFilters.length;
  }
}
