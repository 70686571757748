<div class="country">
  <app-dropdown-v2
    trailingIcon="chevron-down"
    [selected]="selected"
    [options]="states"
    [type]="'country'"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [disabled]="disabled"
    [placeholder]="'Choose location'"
  ></app-dropdown-v2>
</div>
