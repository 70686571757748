<div class="search-request">
  <div *ngIf="matchType.length > 0 && type === 'request'">
    <app-tab-buttons
      classList="body-large"
      [selected]="selectedSearchType"
      [tabButtons]="matchType"
      (callbackFunction)="chooseRequestType($event)"
      style="max-width: unset; width: 100%"
    >
    </app-tab-buttons>
  </div>
  <ng-container *ngIf="selectedSearchType === 1">
    <textarea
      [value]="manualRequestDescription"
      (input)="input($event)"
      class="body-large"
    ></textarea>
  </ng-container>
  <ng-container *ngIf="selectedSearchType === 0">
    <app-request-level0
      *ngIf="selectedRequest"
      [request]="geSelectedRequest()"
      (cta)="select(undefined)"
      [ctas]="{ selected: true }"
      [type]="type"
    ></app-request-level0>
    <div *ngIf="!selectedRequest">
      <div (clickOutside)="toggleDropdown = false" class="wrap-search">
        <app-input
          (inputs)="searchRequest($event)"
          trailingIcon="chevron-down"
          type="field"
          (focus)="toggleDropdown = true; getRequests()"
        ></app-input>
        <div *ngIf="toggleDropdown" class="requests surface--2">
          <div class="placeholder" *ngIf="!allRequests && !loading">
            <p class="s1">Start your search.</p>
            <p class="body-large">Please search by name to find consultant.</p>
          </div>
          <div class="loading" *ngIf="loading">
            <app-loader [loading]="loading"></app-loader>
          </div>
          <div *ngIf="!loading && allRequests">
            <ng-container *ngFor="let request of allRequests | slice : 0 : 20">
              <app-request-level0
                (click)="select(request)"
                [request]="request"
                [disable]="!request.isDescription && type !== 'longlist'"
                [type]="type"
              ></app-request-level0>
            </ng-container>
          </div>
        </div>
      </div>
      <div>
        <p class="body-large textSecondary">
          No request chosen. Pick request from the list
          <ng-container *ngIf="matchType.length > 0">
            or
            <span class="link" (click)="chooseRequestType(1)"
              >input manually</span
            >
          </ng-container>
        </p>
      </div>
    </div>
  </ng-container>
</div>
