<div class="find-match">
  <div>
    <div class="bundle">
      <div
        *ngIf="!selectedRequest && !selectedconsultant && !manualRequest"
        class="add"
        [class]="{ click: click }"
        (click)="openModal(matchType[matchTypeNumber].modal)"
      >
        <app-cta [icon]="'plus'"> </app-cta>
        <p class="body-large">{{ matchType[matchTypeNumber].add }}</p>
      </div>
      <app-level0
        *ngIf="selectedconsultant?.id"
        [consultant]="selectedconsultant"
        [ctas]="{ edit: true }"
        (cta)="openModal(matchType[matchTypeNumber].modal)"
      ></app-level0>
      <app-request-level0
        [style]="'height: 62px'"
        [request]="selectedRequest"
        *ngIf="!isManual && selectedRequest"
        [ctas]="{ edit: true, view: true }"
        (cta)="openModal(matchType[matchTypeNumber].modal)"
      ></app-request-level0>
      <app-request-level0
        [style]="'height: 62px'"
        [manualRequest]="manualRequest"
        *ngIf="isManual && manualRequest"
        [ctas]="{ edit: true }"
        (cta)="openModal(matchType[matchTypeNumber].modal)"
      ></app-request-level0>
    </div>

    <div class="advanced" (click)="openModal('advancedSearch')">
      <app-button
        trailingIcon="pencil"
        [label]="
          'Advanced search ' +
          (selectedAdvanced ? '“' + selectedAdvanced + '”' : '')
        "
        [class]="['secondary', 'small']"
        style="white-space: unset !important"
      ></app-button>
    </div>
  </div>
  <app-button
    [class]="['primary', 'large']"
    [label]="'Find a match'"
    [leadingIcon]="'refresh'"
    [spinner]="loading"
    [style]="'width: 100%; margin-top: 2px;'"
    [disable]="
      (!selectedRequest && !selectedconsultant && !manualRequest) || loading
    "
    (click)="
      loading
        ? ''
        : cta.emit(
            selectedRequest || selectedconsultant || manualRequest
              ? 'search'
              : ''
          )
    "
  ></app-button>
</div>
