<div class="buttons" [style]="styles" (click)="clicked()" [class]="{
  'active': selected,
  'description': data.description
  }">

  <app-select-items *ngIf="!noIcon" [type]="single ?'radio' :'checkbox'" [isChecked]="selected"></app-select-items>
  <div>
    <p class="label2">{{ data.displayName }}</p>
    <p class="body2" *ngIf="data.description">{{data.description}}</p>
  </div>
</div>
