<div [class]="type + ' dialog'">
  <div *ngIf="type === 'assessment'">
    <div class="assessment-top">
      <div>Additional information</div>
      <p (click)="closeDialog()" class="link">Close</p>
    </div>
    <div class="assessment-content">
      <div class="index">
        <h1>{{getCharBehav(data.scores[0].trait)}}</h1>
        <div>
          <p class="basline_large bold">
            Relevant game:
          </p>
          <p>
            {{data.scores[0].traitExcerpt}}
          </p>
        </div>
      </div>
      <section>
        <p>{{data.scores[0].scoreExcerpt}}</p>
        <div class="five-step">
          <div [class]="{'active':data.scores[0].score === 1}"></div>
          <div [class]="{'active':data.scores[0].score === 2}"></div>
          <div [class]="{'active':data.scores[0].score === 3}"></div>
          <div [class]="{'active':data.scores[0].score === 4}"></div>
          <div [class]="{'active':data.scores[0].score === 5}"></div>
        </div>
        <div class="five-step-description">
          <div>
            <p class="baseline_large bold"> {{data.scores[0].leftHandScoreLabel}}</p>
            <p class="baseline_small"> {{data.scores[0].leftHandScoreExcerpt}}</p>
          </div>
          <div>
            <p class="baseline_large bold"> {{data.scores[0].rightHandScoreLabel}}</p>
            <p class="baseline_small"> {{data.scores[0].rightHandScoreExcerpt}}</p>
          </div>
        </div>
      </section>
      <section class="details">
        <div>
          <p class="baseline_large bold">Strengths</p>
          <ul>
            <li *ngFor="let strengths of data.scores[0].strengths">
              {{strengths}}
            </li>
          </ul>
        </div>
        <div>
          <p class="baseline_large bold">Challenges</p>
          <ul>
            <li *ngFor="let challenge of data.scores[0].challenges">
              {{challenge}}
            </li>
          </ul>
        </div>
      </section>
      <section class="details">
        <div>
          <p class="baseline_large bold">Responsibilities that might fit</p>
          <ul>
            <li *ngFor="let fitTasks of data.scores[0].fitTasks">
              {{fitTasks}}
            </li>
          </ul>
        </div>
        <div>
          <p class="baseline_large bold">Responsibilities that might not fit</p>
          <ul>
            <li *ngFor="let noFitTasks of data.scores[0].noFitTasks">
              {{noFitTasks}}
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
  <div *ngIf="type === 'request'">
    <div class="request-top">
      <h1>
        Request
      </h1>
      <div>
        <div (click)="href(requestData.extrnalLink)" class="btn">
          <p>View request in CRM</p> <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
        </div>
        <p (click)="closeDialog()" class="link">Close</p>
      </div>
    </div>
    <div class="request-content">
      <div [innerText]="requestData.description">

      </div>
    </div>
  </div>
