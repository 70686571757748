import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightFromLine } from '@fortawesome/pro-regular-svg-icons'

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent {
  faArrowRightFromLine = faArrowRightFromLine as IconProp
  @Input() openSidePanel = false
  @Output() callbackFunction = new EventEmitter()

  toggleSide() {
    this.callbackFunction.emit()
  }
}
