<div (clickOutside)="dropdown = false" class="dropdown-v2" [class]="type">
  <div
    (click)="dropdown = true"
    class="search-input"
    [class]="{ disabled: disabled }"
  >
    <div
      class="input body-small"
      [attr.placeholder]="placeholder"
      [class]="{ placeholder: selected.length > 0, singleSelect: !multiselect }"
    >
      <ng-container *ngFor="let select of selected">
        <ng-container *ngIf="tiers.length > 0; else displayName">
          <app-tier-statuses
            (callbackFunction)="selectFunc(select)"
            [tier]="getTier(select)!"
            [trailingIcon]="
              multiselect ? { type: 'icon', icon: 'X' } : undefined
            "
          ></app-tier-statuses>
        </ng-container>
        <ng-template #displayName>
          <app-badge
            (callbackFunction)="selectFunc(select)"
            [label]="select.displayName"
            color="neutral--Grey-300"
            [trailingIcon]="
              multiselect ? { type: 'icon', icon: 'X' } : undefined
            "
          ></app-badge>
        </ng-template>
      </ng-container>

      <div
        class="search"
        [class]="{ notSearchable: !searchable }"
        #searchInput
        (click)="dropdown = true"
        [contentEditable]="!disabled && searchable"
        (input)="search($event)"
      ></div>
    </div>
    <div (click)="dropdown = !dropdown" class="icons textSecondary">
      <i-tabler
        *ngIf="trailingIcon"
        class="xs"
        [name]="trailingIcon"
      ></i-tabler>
      <i-tabler
        *ngIf="!trailingIcon"
        class="xs"
        [name]="'chevron-' + (dropdown ? 'up' : 'down')"
      ></i-tabler>
    </div>
  </div>

  <div class="dropdown Surface-1" [class]="{dropup}" *ngIf="dropdown">
    <div
      class="option"
      (click)="selectFunc(option)"
      *ngFor="let option of options"
    >
      <div *ngIf="multiselect">
        <input #checkboxInput [checked]="isSlected(option)" type="checkbox" />
        <span class="checkmark"></span>
      </div>
      <ng-container *ngIf="tiers.length > 0; else displayName">
        <app-tier-statuses [tier]="getTier(option)!"></app-tier-statuses>
      </ng-container>

      <ng-template #displayName>
        <p class="body-small name">
          <ng-container *ngIf="option.type !== 'team'">
            {{ option.displayName }}
          </ng-container>
          <ng-container *ngIf="option.type === 'team'">
            <span>
              <span class="friendlyName">
                {{ option.name }}
              </span>
              <span class="textSecondary">{{ option.displayName }}</span>
            </span>
          </ng-container>
        </p>
      </ng-template>
    </div>
  </div>
</div>
