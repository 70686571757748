import { Component, Input } from '@angular/core';
import {
  matchConsultants,
  requestMatchesLevels1,
} from 'src/interface/shared.interface';
import { shortlistedSalesRequests } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-request-level1',
  templateUrl: './request-level1.component.html',
  styleUrls: ['./request-level1.component.scss'],
})
export class RequestLevel1Component {
  @Input() selectedRequest: requestMatchesLevels1;
  @Input() selectedSalesRequest: shortlistedSalesRequests;
  openDropdown: string;
  freelanceAddedToRequest: boolean;

  constructor(
    private helperFunctions: HelperFunctionsService,
    private contextService: ContextService
  ) {

  }


  ifEmptyReturnDash(value: string) {
    if (value) {
      return this.getDate(value);
    }
    return '-';
  }
  openMatchModal(event: Event, another?: boolean) {
    event.stopPropagation();
    this.contextService.openMatchModal = another
      ? 'addAnotherconsultant'
      : 'addconsultant';
    this.contextService.selectedRequest = this.selectedRequest;
  }
  toggleDropdown(event: Event, id: string) {
    event.stopPropagation();
    this.openDropdown = id;
  }
  openInternal(event: Event) {
    event.stopPropagation();
    if (this.selectedRequest) {
      window.open('requests/' + this.selectedRequest.id, '_blank');
    }
  }
  openExternal(link: string, text: string, event: Event) {
    event.stopPropagation();
    if (this.selectedRequest) {
      window.open(link, '_blank');
    }
  }
  getDate(value: string) {
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }
  getColorType(priority: string) {
    if (priority.toLowerCase() === 'medium') {
      return 'brand--Light textLink';
    }
    if (priority.toLowerCase() === 'low') {
      return 'brand--Lighter textLink';
    }
    return 'brand--Medium';
  }
  getSaleStatus(value: { salesStatus: string; displayName: string }) {
    return this.helperFunctions.getSaleStatus(value);
  }
}
