import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef,
  SimpleChanges,
} from '@angular/core';
import { option, tier } from 'src/interface/shared.interface';

@Component({
  selector: 'app-dropdown-v2',
  templateUrl: './dropdown-v2.component.html',
  styleUrls: ['./dropdown-v2.component.scss'],
})
export class DropdownV2Component {
  @Input() trailingIcon: string;
  @Input() tiers: tier[] = [];
  @Input() selected: option[] = [];
  @Input() options: option[];
  @Input() multiselect: boolean;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() searchable = true;
  @Output() selectOption = new EventEmitter<option>();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLElement>;
  dropdown: boolean;
  dropup: boolean;
  rawOptions: option[];

  getTier(option: option) {
    return this.tiers.find(
      (x) => x.status.contractStatus === option.displayName
    );
  }
  search(event: Event) {
    const value = (
      event.currentTarget as HTMLInputElement
    ).innerText.toLocaleLowerCase();

    if (!this.rawOptions) {
      this.rawOptions = this.options;
    }
    this.options = this.rawOptions.filter((x) => {
      let find = x.displayName.toLocaleLowerCase().includes(value);
      if (!find) {
        find = x.name ? x.name.toLocaleLowerCase().includes(value) : true;
      }
      return find;
    });
  }
  isSlected(option: option) {
    return this.selected.some((x) => x.id === option.id);
  }
  selectFunc(select: option) {
    if (this.disabled) {
      return;
    }
    this.searchInput.nativeElement.innerHTML = '';
    this.selectOption.emit(select);
  }
  resetInput() {
    if (this.rawOptions) {
      this.options = this.rawOptions;
    }
    return '';
  }
}
