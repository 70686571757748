import { Component, Input } from '@angular/core';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() back = true;
  constructor(private helperFunctions: HelperFunctionsService) {}
  clearStorage() {
    window.open('/', '_self');
    if (this.back) {
      this.helperFunctions.route(['/']);
      sessionStorage.removeItem('selectedNav');
    }
  }
}
