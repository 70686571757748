import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.scss'],
})
export class SelectItemsComponent {
  @Input() isChecked: boolean;
  @Input() disabled: boolean;
  @Input() type: selectItem;
  @Input() label: string;
  @Output() changeSlider = new EventEmitter<MatSlideToggleChange>();
  @Output() checked = new EventEmitter<boolean>();
  click() {
    if (!this.disabled) {
      this.checked.emit(this.isChecked);
    }
  }
}
type selectItem = 'toggle' | 'checkbox' | 'radio' | 'select';
