<div class="location" *ngIf="location">
  <p class="body-small textSecondary">Team location</p>
  <app-dropdown-tree
    trailingIcon="search"
    [selected]="selected"
    [options]="options"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [placeholder]="'Find location'"
    [disabled]="disabled"
    [searchable]="true"
  ></app-dropdown-tree>
</div>
