import { Component, Input } from '@angular/core';
import { experience, experiences } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-job-experiences',
  templateUrl: './job-experiences.component.html',
  styleUrls: ['./job-experiences.component.scss'],
})
export class JobExperiencesComponent {
  @Input() experience: experience;
  @Input() selectedTab: number;

  constructor(private helperFunctions: HelperFunctionsService) {}

  filter(job: experiences) {
    if (this.selectedTab === 1) {
      return job.experienceType === 'position';
    }
    if (this.selectedTab === 2) {
      return job.experienceType === 'contract';
    }
    return true;
  }

  getIcon(type: string, position: string) {
    if (position === 'contract') {
      return { type: 'icon', icon: 'discount-check' };
    }
    if (position === 'position') {
      return { type: 'icon', icon: 'briefcase' };
    }
    if (position === 'project') {
      return { type: 'icon', icon: 'discount-check' };
    }
    return { type: '', icon: '' };
  }
  getLabel(type: string, position: string) {
    if (position === 'contract') {
      return '7N Contract';
    }
    if (position === 'position') {
      return 'Position';
    }
    if (position === 'project') {
      return 'Project';
    }
    return '';
  }
  getColor(type: string, position: string) {
    if (position === 'contract') {
      return 'brand--Light textLink';
    }
    if (position === 'position') {
      return 'highlight--Light textHighlightDark';
    }
    if (position === 'project') {
      return 'info--Light textInfoDark';
    }
    return '';
  }
  getDate(value: string, endDate = false) {
    if (!value && endDate) {
      return 'Now';
    }
    if (!value) {
      return '-';
    }
    const nowtimestamp = Math.round(new Date().getTime() / 1000);
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    if (endDate && nowtimestamp < timestamp) {
      return 'Now';
    }

    return this.helperFunctions.getDate(timestamp, false, true);
  }
}
