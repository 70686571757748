<div *ngIf="selectedRequest" class="info">
  <div>
    <div class="title">
      <h3>{{ selectedRequest.title }}</h3>
      <p>{{ selectedRequest.customerName }}</p>
    </div>
    <div class="cta">
      <app-cta
        classes="brand--Lightest textLink"
        [icon]="'external-link'"
        (click)="openInternal($event)"
      >
      </app-cta>
    </div>
  </div>

  <div class="details body-small">
    <!-- <p class="">
      <span>Starting on </span>
      {{ ifEmptyReturnDash(selectedRequest.contractStartDate) }}
    </p>
    <li></li> -->
    <!-- <app-badge
      class="only-first-letter"
      [label]="selectedRequest.priority"
      [color]="getColorType(selectedRequest.priority)"
    ></app-badge>
    <li></li> -->
    <app-badge
      *ngIf="selectedRequest.salesStatus"
      [label]="selectedRequest.salesStatus"
      [color]="'neutral--Grey-300 textPrimary'"
      [icon]="getSaleStatus(selectedRequest.salesStatus).icon"
    ></app-badge>
    <!-- <p class="body-large">
      {{ selectedRequest.enlistedProfiles.length }} <span>candidates</span>
    </p> -->
  </div>
</div>
