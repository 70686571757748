import { Component, Input, Output } from '@angular/core';
import { shortlistedSalesRequests } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-sale-request',
  templateUrl: './sale-request.component.html',
  styleUrls: ['./sale-request.component.scss'],
})
export class SaleRequestComponent {
  @Input() selectedRequest: shortlistedSalesRequests;
  constructor(private helperFunctions: HelperFunctionsService) {}
  openInternal(event: Event) {
    event.stopPropagation();
    if (this.selectedRequest) {
      window.open('requests/' + this.selectedRequest.id, '_blank');
    }
  }
  getSaleStatus(value: string) {
    return this.helperFunctions.getSaleStatus({
      salesStatus: value,
      displayName: value,
    });
  }
}
