<div class="dropdown surface--1">
  <ng-container *ngIf="profile3">
    <div (click)="openMatchModal($event, 'anotherLonglist')">
      <i-tabler name="text-plus"></i-tabler>
      <p class="body-small">Add to a request</p>
    </div>
    <div (click)="openExternal(profile3.my7NLink, 'View in My7N', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in My7N</p>
    </div>
    <div (click)="openExternal(profile3.crmLink, 'View in CRM', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in CRM</p>
    </div>
    <div
      [class]="{ disabled: !profile3.linkedIn }"
      (click)="
        openExternal(profile3.linkedIn ?? '', 'Open in LinkedIn', $event)
      "
    >
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">Open in LinkedIn</p>
    </div>
  </ng-container>

  <ng-container *ngIf="consultant">
    <div *ngIf="!isManual" (click)="openMatchModal($event, 'anotherLonglist')">
      <i-tabler name="text-plus"></i-tabler>
      <p *ngIf="selectedSearchType === 0" class="body-small">
        Add to another request
      </p>
      <p *ngIf="selectedSearchType === 2" class="body-small">
        Add to a request
      </p>
    </div>
    <div (click)="openExternal(consultant.my7NLink, 'View in My7N', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in My7N</p>
    </div>
    <div (click)="openExternal(consultant.crmLink, 'View in CRM', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in CRM</p>
    </div>
    <div
      [class]="{ disabled: !consultant.linkedIn }"
      (click)="
        openExternal(consultant.linkedIn ?? '', 'Open in LinkedIn', $event)
      "
    >
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">Open in LinkedIn</p>
    </div>
  </ng-container>
</div>
