<div class="timeline">
  <div class="search-sort">
    <div class="sortBy">
      <p class="body-large textSecondary">Sort by</p>
      <app-button
        [class]="['small', 'ghost']"
        label="Most recent"
        [trailingIcon]="
          ascDesc ? 'sort-ascending' : 'sort-descending'
        "
        (click)="sortByReverse()"
      ></app-button>
    </div>
  </div>
  <app-loader [loading]="!timelines" *ngIf="!timelines"></app-loader>
  <app-timeline-card [timeline]="timeline" *ngFor="let timeline of timelines"></app-timeline-card>
</div>
