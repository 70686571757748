<div class="loading" *ngIf="loading">
  <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
</div>
<app-dialog
  *ngIf="dialog === true"
  [data]="result"
  [type]="dialogType"
  (close)="toogleDialog()"
>
</app-dialog>
<div *ngIf="!loading" class="candidate surface--2">
  <div class="top">
    <div *ngIf="selectedConsultant.genome" class="info">
      <div [routerLink]="['/', breadCrumbs.slug]" class="back">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
      </div>
      <div class="meta-info">
        <div class="bread-crumbs">
          <h2
            class="link"
            [routerLink]="['/' + breadCrumbs.slug, breadCrumbs.id]"
            *ngIf="breadCrumbs.id"
          >
            {{ getCandidate(breadCrumbs.id) }}
          </h2>
          <p class="baseline_small">
            Category: {{ getCategory(selectedConsultant.category) }}
          </p>
        </div>
        <div class="track">
          <p class="baseline_small">
            Recieved:
            {{ getDate(selectedConsultant.genome.submissionTime ?? -1) }}
          </p>
          <p class="baseline_small">
            Campaign: {{ selectedConsultant.genome.tracking?.campaign ?? "-" }}
          </p>
          <p class="baseline_small">
            Source: {{ selectedConsultant.genome.tracking?.source ?? "Direct" }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="selectedConsultant.genome" class="wrapper">
      <div class="user-info">
        <div>
          <h2
            class="link"
            [routerLink]="['/' + breadCrumbs.slug, breadCrumbs.id]"
            *ngIf="breadCrumbs.id"
          >
            {{ getCandidate(breadCrumbs.id) }}
          </h2>
          <p class="baseline_small">
            Category: {{ getCategory(selectedConsultant.category) }}
          </p>
          <div>
            <p class="baseline_small">
              Recieved:
              {{ getDate(selectedConsultant.genome.submissionTime ?? -1) }}
            </p>
            <p class="baseline_small">
              Campaign:
              {{ selectedConsultant.genome.tracking?.campaign ?? "-" }}
            </p>
            <p class="baseline_small">
              Source:
              {{ selectedConsultant.genome.tracking?.source ?? "Direct" }}
            </p>
          </div>
        </div>
        <p class="baseline_small">
          <ng-container
            >{{ selectedConsultant.metadata.emailAddress }},
          </ng-container>
          <ng-container
            >{{
              selectedConsultant.metadata.phoneNumber
                ? selectedConsultant.metadata.phoneNumber
                : "Mobile: -"
            }}
          </ng-container>

          <span *ngIf="selectedConsultant.genome.genome.location.city"
            >{{ selectedConsultant.genome.genome.location.city }},
          </span>
          <span *ngIf="selectedConsultant.genome.genome.location.state"
            >{{ selectedConsultant.genome.genome.location.state }},
          </span>
          <span
            *ngIf="
              getCountry(selectedConsultant.genome.genome.location.country)
            "
            >{{ getCountry(selectedConsultant.genome.genome.location.country) }}
          </span>
        </p>
      </div>
      <div class="cta">
        <ng-container *ngIf="selectedConsultant.genome.genome.status === 0">
          <div (click)="setSatus('reject')" class="btn">Reject</div>
          <div (click)="setSatus('schedule')" class="btn btn-dark">
            Interview
          </div>
        </ng-container>
        <ng-container *ngIf="selectedConsultant.genome.genome.status === 1">
          <div (click)="setSatus('reject')" class="btn">Reject</div>
          <div (click)="setSatus('approve')" class="btn btn-dark">Approve</div>
        </ng-container>
        <ng-container *ngIf="selectedConsultant.genome.genome.status === 2">
          <div (click)="setSatus('schedule')" class="btn">
            Move to pending interview
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="selectedConsultant" class="profile">
    <section *ngIf="selectedConsultant.industryProfile" class="grid">
      <div>
        <span>
          <div>
            <p class="baseline_small bold">
              Experience
              <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
            </p>
            <span>
              <h1>
                {{
                  getYearsOfExperience(
                    selectedConsultant.industryProfile.levelOfExperience
                  )
                }}
              </h1>
              <p class="baseline_small light">Years</p>
            </span>
          </div>
          <div>
            <div>
              <p class="baseline_small">Worked as a consultant before?</p>
              <div class="row">
                <div class="selected-btn">
                  <p>
                    {{
                      selectedConsultant.genome.genome
                        .previouslyWorkedAsConsultant
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p class="baseline_small">Language(s):</p>
              <div class="row">
                <div
                  *ngFor="
                    let lang of selectedConsultant.genome.genome
                      .workingLanguages
                  "
                  class="selected-btn"
                >
                  <p>{{ getLanguage(lang) }}</p>
                </div>
                <div
                  *ngIf="
                    selectedConsultant.genome.genome.workingLanguages.length ===
                    0
                  "
                >
                  -
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="no-border">
        <div class="border availability">
          <p class="baseline_small bold">
            <span
              >Available from
              <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
            </span>
            <ng-container
              *ngIf="selectedConsultant.genome.genome.professionalProfiles"
            >
              <a
                [href]="
                  helperFunctions.addHttpOrHttps(
                    selectedConsultant.genome.genome.professionalProfiles
                      .linkedin
                  )
                "
                target="_blank"
                class="nowrap link"
                >View linkedin profile</a
              >
            </ng-container>
          </p>
          <div>
            <h1>
              {{
                beautifyDate(selectedConsultant.genome.genome.availabilityDate)
              }}
            </h1>
            <div class="row">
              <ng-container
                *ngFor="
                  let item of selectedConsultant.genome.genome
                    .workingPreferences | keyvalue
                "
              >
                <div class="selected-btn" *ngIf="item.value">
                  <p>{{ toLowerCase(item.key) }}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="border">
          <div>
            <p class="baseline_small bold">
              <span>
                Price
                <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
              </span>
            </p>
            <p
              *ngIf="selectedConsultant.genome.genome.expectedIncome"
              class="baseline_small light"
            >
              *Calculated from expected monthly salary
            </p>
          </div>
          <div
            *ngIf="selectedConsultant.genome.genome.expectedIncome"
            class="prices"
          >
            <div>
              <div class="row">
                <p class="baseline_small nowrap">Ask price</p>
              </div>
              <div>
                <h1>
                  {{
                    selectedConsultant.genome.genome.expectedIncome
                      .hourlyRate ?? "-"
                  }}
                </h1>
                <p class="baseline_small">
                  {{ selectedConsultant.genome.genome.expectedIncome.currency }}
                </p>
              </div>
              <div class="row">
                <p class="baseline_small light">Hourly price{{ star }}</p>
              </div>
            </div>
            <div>
              <div class="row">
                <p class="baseline_small nowrap">Quotable price</p>
              </div>
              <div>
                <h1>
                  {{
                    selectedConsultant.genome.genome.expectedIncome.quotable ??
                      "-"
                  }}
                </h1>
                <p class="baseline_small">
                  {{ selectedConsultant.genome.genome.expectedIncome.currency }}
                </p>
              </div>
              <div class="row">
                <p class="baseline_small light">Hourly price{{ star }}</p>
              </div>
            </div>
            <div>
              <div class="row">
                <p class="baseline_small nowrap">Market price</p>
              </div>
              <div>
                <h1>
                  {{
                    selectedConsultant.genome.genome.expectedIncome.market ??
                      "-"
                  }}
                </h1>
                <p class="baseline_small">
                  {{ selectedConsultant.genome.genome.expectedIncome.currency }}
                </p>
              </div>
              <div class="row">
                <p class="baseline_small light">Hourly price{{ star }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <span>
          <p class="baseline_small bold">
            Job level
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="space-between">
            <div>
              <h1>{{ selectedConsultant.genome.genome.levelOfExperience }}</h1>
              <ng-container *ngFor="let level of levelOfExperience">
                <p
                  *ngIf="
                    selectedConsultant.genome.genome.levelOfExperience ===
                    level.title
                  "
                  class="baseline_small"
                >
                  {{ level.description }}
                </p>
              </ng-container>
            </div>

            <div class="level">
              <div *ngFor="let level of levelOfExperience">
                <div></div>
                <p class="baseline_small">{{ level.title }}</p>
                <span
                  *ngIf="
                    selectedConsultant.genome.genome.levelOfExperience ===
                    level.title
                  "
                ></span>
              </div>
            </div>
          </div>
        </span>
      </div>
    </section>
    <section>
      <div *ngIf="selectedConsultant.skillsProfile" class="left">
        <div [class]="{ noData: radarChartData.datasets[0].data.length === 0 }">
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Secret code
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="secret-code">
            <canvas
              baseChart
              [data]="radarChartData"
              [options]="mobile ? radarChartOptionsMobile : radarChartOptions"
              [type]="radarChartType"
            >
            </canvas>
          </div>
        </div>
        <div [class]="{ noData: !selectedConsultant.skillsProfile.jobFamily }">
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Job family
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="row">
            <div class="selected-btn">
              <p>
                {{
                  getJobFamily(selectedConsultant.skillsProfile.jobFamily.id)
                }}
              </p>
            </div>
          </div>
        </div>
        <div
          [class]="{
            noData:
              selectedConsultant.skillsProfile.topSelectedSkills.length === 0
          }"
        >
          <p class="baseline_small bold">
            Top skills
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="row">
            <div class="notFilledOut">Not filled out</div>
            <div
              *ngFor="
                let skill of selectedConsultant.skillsProfile.topSelectedSkills
              "
              class="selected-btn"
            >
              <p>{{ skill.displayName }}</p>
            </div>
          </div>
          <p class="baseline_small bold">
            All skills
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="row">
            <div class="notFilledOut">Not filled out</div>
            <div
              *ngFor="
                let skill of selectedConsultant.skillsProfile.selectedSkills
              "
              class="selected-btn"
            >
              <p>{{ skill.displayName }}</p>
            </div>
          </div>
        </div>
        <div
          [class]="{
            noData:
              selectedConsultant.skillsProfile.technologySkills.length === 0
          }"
        >
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Tech tools and methods
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="row">
            <div
              *ngFor="
                let skill of selectedConsultant.skillsProfile.technologySkills
              "
              class="selected-btn"
            >
              <p>{{ skill.displayName }}</p>
            </div>
          </div>
        </div>
        <div
          [class]="{
            noData: !anyProfile(
              selectedConsultant.genome.genome.professionalProfiles
            )
          }"
        >
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Professional profiles
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div
            *ngIf="selectedConsultant.genome.genome.professionalProfiles"
            class="prof-profiles"
          >
            <div
              *ngIf="
                selectedConsultant.genome.genome.professionalProfiles.linkedin
              "
              class="linked"
            >
              <div>
                <fa-icon
                  [styles]="{ color: '#0072b1' }"
                  [icon]="faLinkedin"
                ></fa-icon>
                <p>Linkedin</p>
              </div>
              <a
                class="link"
                target="_blank"
                [href]="
                  helperFunctions.addHttpOrHttps(
                    selectedConsultant.genome.genome.professionalProfiles
                      .linkedin
                  )
                "
                >View profile</a
              >
            </div>
            <div
              *ngIf="
                selectedConsultant.genome.genome.professionalProfiles.github
              "
              class="github"
            >
              <div>
                <fa-icon
                  [styles]="{ color: '#171515' }"
                  [icon]="faGithub"
                ></fa-icon>
                <p>Github</p>
              </div>
              <a
                class="link"
                target="_blank"
                [href]="
                  selectedConsultant.genome.genome.professionalProfiles.github
                "
                >View profile</a
              >
            </div>
            <div
              *ngIf="
                selectedConsultant.genome.genome.professionalProfiles.website
              "
              class="website"
            >
              <div>
                <fa-icon [icon]="faGlobe"></fa-icon>
                <p>Website</p>
              </div>
              <a
                class="link"
                target="_blank"
                [href]="
                  selectedConsultant.genome.genome.professionalProfiles.website
                "
                >View profile</a
              >
            </div>
            <div
              *ngIf="
                selectedConsultant.genome.genome.professionalProfiles.other
              "
              class="other"
            >
              <div>
                <fa-icon [icon]="faGlobeOther"></fa-icon>
                <p class="bold">Other</p>
              </div>
              <a
                class="link"
                target="_blank"
                [href]="
                  selectedConsultant.genome.genome.professionalProfiles.other
                "
                >View profile</a
              >
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedConsultant.genome" class="right">
        <div
          [class]="{
            noData: !selectedConsultant.genome.genome.workingSweetspot
          }"
        >
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Working sweetspot
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="sweet-spot">
            <div *ngFor="let skill of contextService.sweetSpot; let i = index">
              <p class="baseline_small bold">{{ skill.displayName }}</p>
              <div *ngIf="selectedConsultant.genome.genome.workingSweetspot">
                <div class="counter">
                  <p class="baseline_small">{{ getSweetSpot(i + 1) }}/100</p>
                </div>
                <!-- <mat-slider
                  [disabled]="true"
                  [value]="getSweetSpot(i + 1)"
                  class="black"
                  aria-label="unit(s)"
                >
                </mat-slider> -->
                <div class="start-end">
                  <p class="baseline_small">Not Important</p>
                  <p class="baseline_small">Very Important</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="CharacteristicsBehaviours"
          *ngIf="selectedConsultant.assessmentProfile.assessmentResults"
          [class]="{
            noData:
              !selectedConsultant.assessmentProfile.assessmentResults
                .gameInstanceResults
          }"
        >
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Characteristics and behaviours
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="char-behav">
            <div>
              <div class="headline">
                <p class="baseline_small">Result</p>
                <p class="baseline_small">Description</p>
              </div>
            </div>
            <div
              *ngFor="
                let result of selectedConsultant.assessmentProfile
                  .assessmentResults.gameInstanceResults
              "
            >
              <div *ngFor="let score of result.scores">
                <div>
                  {{ getCharBehav(score.trait) }}
                  <div class="score">
                    <div [class]="{ active: score.score === 1 }"></div>
                    <div [class]="{ active: score.score === 2 }"></div>
                    <div [class]="{ active: score.score === 3 }"></div>
                    <div [class]="{ active: score.score === 4 }"></div>
                    <div [class]="{ active: score.score === 5 }"></div>
                  </div>
                </div>
                <div>
                  <p class="baseline_small">{{ score.strengths[0] }}</p>
                  <p
                    (click)="readMore('assessment', result, score)"
                    class="baseline_small link"
                  >
                    Read more
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          [class]="{
            noData: selectedConsultant.industryProfile.jobHistory.length === 0
          }"
        >
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Job history
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="job-history">
            <div>
              <ng-container
                *ngFor="
                  let jobHistory of selectedConsultant.industryProfile
                    .jobHistory;
                  let last = last
                "
              >
                <div
                  [class]="{ current: jobHistory.current }"
                  class="each-history"
                >
                  <div class="current-label">
                    <p class="baseline_small">Current position</p>
                  </div>
                  <div>
                    <div class="column left">
                      <div class="column">
                        <p class="baseline_small">Role</p>
                        <p class="baseline_xlarge">
                          {{ jobHistory.role }}
                        </p>
                      </div>
                      <div class="row">
                        <div class="column">
                          <p class="baseline_small">Company</p>
                          <p class="baseline_xlarge">
                            {{ jobHistory.company }}
                          </p>
                        </div>
                        <div class="column">
                          <p class="baseline_small">Industry</p>
                          <p class="baseline_xlarge">
                            {{ getIndustry(jobHistory.industryId) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="column right">
                      <div class="column">
                        <p class="baseline_small">Start</p>
                        <p class="baseline_xlarge">
                          {{ getMonthYear(jobHistory.start) }}
                        </p>
                      </div>
                      <div *ngIf="jobHistory.end" class="column">
                        <p class="baseline_small">End</p>
                        <p class="baseline_xlarge">
                          {{ getMonthYear(jobHistory.end) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedConsultant.industryProfile.jobHistory.length === 0"
          [class]="{
            noData:
              selectedConsultant.industryProfile.selectedIndustries.length === 0
          }"
        >
          <div class="notFilledOut">Not filled out</div>
          <p class="baseline_small bold">
            Industries
            <!--<fa-icon [icon]="faCircleQuestion"></fa-icon>-->
          </p>
          <div class="industries">
            <div
              *ngFor="
                let industries of selectedConsultant.industryProfile
                  .selectedIndustries
              "
            >
              <p>{{ getIndustry(industries.id) }}</p>
              <h1>{{ getYearsOfExperience(industries.levelOfExperience) }}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
