import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option } from 'src/interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.scss'],
})
export class StatusesComponent implements OnChanges {
  @Input() clearFromParent: boolean;
  @Input() disabled: boolean;
  @Input() statuses?: string[];
  options: option[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();
  selectOption(option: option) {
    const findex = this.selected.findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected.splice(findex, 1);
    } else {
      this.selected.push(option);
    }
    this.selectedOption.emit(this.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.statuses && changes['selected']) {
      this.options = [];
      this.statuses.forEach((status: string) => {
        const findex = this.selected.findIndex((x) => x.displayName === status);
        this.options.push({
          id: findex !== -1 ? this.selected[findex].id : uuidv4(),
          displayName: status,
          type: 'status',
        });
      });
    }
  }
}
