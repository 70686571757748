import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {}

  @Input() myDirective: boolean;
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  // Handle single click
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    this.handleClick(event, targetElement);
  }

  // Handle double click
  @HostListener('document:dblclick', ['$event', '$event.target'])
  public onDoubleClick(event: MouseEvent, targetElement: HTMLElement): void {
    event.preventDefault();
    // this.handleClick(event, targetElement);
  }

  private handleClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement && !this.myDirective) {
      event.preventDefault();
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
