import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent {
  @Input() class = 'test';
  @Input() dropdown: 'leading' | 'trailing' = 'leading';
  @Input() type = 'field';
  @Input() combined: string;
  @Input() icon = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() contenteditable = true;
  @Input() value: string | number = '';
  @Input() option: { icon?: string; displayName: string };
  @Input() options: { icon?: string; displayName: string }[];
  @Input() inputBox: number[] = [];
  @Input() trailingIcon: string;
  @Input() leadingIcon: string;
  @Input() inputType: string;
  @Input() styles: string;
  @Input() valueType = 'innerText';
  @Input() changeValue: Date;

  @Output() click = new EventEmitter<Event>();
  @Output() blur = new EventEmitter<FocusEvent>();
  @Output() inputs = new EventEmitter<string>();
  @Output() focus = new EventEmitter<FocusEvent>();
  @Output() selectedCallback = new EventEmitter<any>();
  @ViewChildren('box') box!: QueryList<ElementRef>;
  @ViewChildren('myInput') myInput!: QueryList<ElementRef>;
  @ViewChildren('myContent') myContent!: QueryList<ElementRef>;
  @ViewChild('myContent') contentElm!: ElementRef<HTMLElement>;

  clearButton: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['changeValue']) {
      if (this.myContent) {
        this.myContent.forEach((element) => {
          element.nativeElement.innerHTML = this.value;
        });
      }
    }
  }
  ngOnInit() {
    setTimeout(() => {
      if (this.contentElm) {
        this.contentElm.nativeElement.addEventListener('paste', (e) => {
          e.preventDefault();
          const text = e.clipboardData!.getData('text/plain');
          document.execCommand('insertText', false, text);
        });
      }
    });
    setTimeout(() => {
      this.myInput.forEach((element) => {
        if (element.nativeElement.type === 'search') {
          element.nativeElement.focus();
        }
      });
    });
  }
  onlyNumberKey(key: string) {
    if (this.inputType === 'tel') {
      const numbers = /^[0-9|+]+$/;
      if (!key.match(numbers)) return false;
    }
    return true;
  }
  clearInput() {
    this.value = '';
    this.inputs.emit(this.value);
  }
  inputValue(event: Event) {
    this.value = (event.target as HTMLInputElement).value;

    this.inputs.emit(this.value);
  }

  isApple() {
    const expression = /(Mac|iPhone|iPod|iPad)/i;
    return expression.test(navigator.userAgent);
  }

  validate(event: KeyboardEvent) {
    if (this.isControl(event)) {
      if (
        ['KeyA', 'KeyV', 'KeyC'].some((x) => {
          return x === event.code;
        })
      ) {
        return true;
      }
    }
    if (
      ['Backspace', 'Tab'].some((x) => {
        return x === event.code;
      })
    ) {
      return true;
    }
    return /^[0-9]$/.test(event.key);
  }

  setValueAndFocus(split: string[]) {
    let loop = 0;
    let next = 0;
    this.box.forEach((element, key) => {
      const active = element.nativeElement.ownerDocument.activeElement.name;
      const currentIndex = JSON.parse(active);
      if (key >= currentIndex) {
        if (/^[0-9]$/.test(split[loop])) {
          element.nativeElement.value = split[loop];
          next = currentIndex + 1;
          loop++;
        }
        if (next === key) {
          element.nativeElement.focus();
        }
      }
    });
  }

  paste(event: ClipboardEvent) {
    if (this.inputType === 'tel') {
      if (!this.onlyNumberKey(event.clipboardData!.getData('text/plain'))) {
        event.preventDefault();
      }

      return;
    }
    const value = event.clipboardData?.getData('text/plain') ?? '';
    this.inputs.emit(value);

    const split = value.split('');
    this.setValueAndFocus(split);
  }

  boxInput(event: Event) {
    const input = event as InputEvent;
    const split = (input.data ?? '').split('');

    this.setValueAndFocus(split);
  }

  isControl(event: KeyboardEvent) {
    if (this.isApple()) {
      return event.metaKey;
    }
    return event.ctrlKey;
  }
}
