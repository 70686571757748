import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { cvType, matchConsultants } from 'src/interface/shared.interface';
import { option } from 'src/interface/shared.interface';
import { FeatureFlagsService } from 'src/services/feature-flags/feature-flags.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';
import { removeFilterFunc } from '../shared-functions/filtering';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss'],
})
export class ConsultantsComponent implements OnInit, OnDestroy {
  @Input() selectedNav: string;
  loading = true;
  moreLoading = false;
  selectedCvType = 0;
  requestId: string;
  response: boolean;
  selectedFilters: option[] = [];
  matchingType: cvType[] = [];
  loadLength = 30;
  potentialResults: matchConsultants[] = [];
  queryParams: any;
  filters: string[] = [];
  timeSearch: NodeJS.Timeout;
  potentialResultsCv: matchConsultants[];
  potentialResultsFile: matchConsultants[];
  globalFilterSubscription: Subscription;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public contextService: ContextService,
    public featureFlags: FeatureFlagsService,
    private platformService: PlatformService,
    private helperService: HelperFunctionsService
  ) {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        this.queryParams = { ...params };
      });
    }
  }

  // Function to check filters and return a filtered list based on the selected CV type
  checkFilter(selectedFilters: option[]) {
    if (this.selectedCvType === 1) {
      return selectedFilters.filter((x) => x.type !== 'keywords');
    }
    return selectedFilters;
  }
  // Lifecycle hook to initialize the component and set up subscriptions
  async ngOnInit() {
    // Subscribe to global filter changes and handle the selected filters
    this.globalFilterSubscription = this.contextService.globalFilterC.subscribe(
      async (selectedFilters: option[]) => {
        this.selectedFilters = selectedFilters;

        // Retain only 'search' filters in the filters array
        this.filters = this.filters.filter((x) => x.includes('search'));

        // Iterate over query parameters and add them to filters array
        for (const key in this.queryParams) {
          if (key === 'cvtype') {
            this.selectedCvType = this.queryParams[key] === '0' ? 0 : 1;
            continue;
          }
          if (key === 'keywords') {
            this.filters.push(key + '=' + this.queryParams[key]);
            continue;
          }
          if (key === 'range') {
            this.filters.push('availability=' + this.queryParams[key]);
            continue;
          }
          this.filters.push(key + '=' + this.queryParams[key]);
        }

        // Set loading state and fetch all consultants based on the filters
        this.loading = true;
        this.response = false;

        this.getAllConsultants(
          this.filters.map((x) => x.replace(/&/g, '%26')).join('&')
        );
      }
    );
  }

  // Function to load more results when the user scrolls down or clicks "load more"
  async loadMore() {
    this.moreLoading = true;
    setTimeout(async () => {
      if (this.loadLength + 20 >= this.potentialResults.length) {
        const findex = this.filters.findIndex((x) => x.includes('offset'));

        if (findex !== -1) {
          this.filters.splice(findex, 1);
        }
        this.filters.push('offset=' + this.potentialResults.length);
        await this.getAllConsultants(this.filters.join('&'), true);
      }
      this.loadLength = this.loadLength + 20;
      this.moreLoading = false;
    }, 1000);
  }

  // Function to fetch all consultants based on the query and pagination
  async getAllConsultants(query?: string, pagenation?: boolean) {
    // Fetch consultants of CV and file types

    const CV = new Promise((resolve) => {
      this.platformService
        .getAllConsultants(
          !query ? 'profiletype=cv' : query + '&profiletype=cv'
        )
        .then((response) => {
          resolve(response);
        });
    });
    const file = new Promise((resolve) => {
      this.platformService
        .getAllConsultants(
          !query ? 'profiletype=files' : query + '&profiletype=files'
        )
        .then((response) => {
          resolve(response);
        });
    });

    // Wait for both CV and file results to be fetched
    await Promise.all([CV, file]).then((values: any[]) => {
      if (pagenation) {
        this.potentialResultsCv = [
          ...this.potentialResultsCv,
          ...values[0].elements,
        ];
        this.potentialResultsFile = [
          ...this.potentialResultsFile,
          ...values[1].elements,
        ];

        if (this.selectedCvType === 0) {
          this.potentialResults = this.potentialResultsCv;
        }
        if (this.selectedCvType === 1) {
          this.potentialResults = this.potentialResultsFile;
        }
      } else {
        this.potentialResultsCv = values[0].elements;
        this.potentialResultsFile = values[1].elements;

        if (this.selectedCvType === 0) {
          this.potentialResults = this.potentialResultsCv;
        }
        if (this.selectedCvType === 1) {
          this.potentialResults = this.potentialResultsFile;
        }
      }

      // Update response and loading states
      this.response = true;
      this.loading = false;

      // Set matching types and update results length in context service
      this.matchingType = [];
      this.matchingType.push(
        {
          matchingType: 'CV',
          displayName: 'My7N CVs',
          icon: 'discount-check',
          iterationId: 1,
          amount: values[0].pagination.total,
        },
        {
          matchingType: 'Files',
          displayName: 'Other CVs',
          icon: 'file-cv',
          iterationId: 2,
          amount: values[1].pagination.total,
        }
      );
      this.contextService.resultsLength.next(
        this.matchingType[this.selectedCvType].amount
      );
    });
  }

  getTier(option: option) {
    return this.contextService.filterData.tiers.find(
      (x: any) => x.status.contractStatus === option.displayName
    );
  }

  // Function to handle search input and fetch consultants based on the search term
  search(searchPhrase: string) {
    // clean up the search phrase
    searchPhrase = this.helperService.sanitizeSearchPhrase(searchPhrase);

    this.loading = true;
    this.response = false;
    clearTimeout(this.timeSearch);
    const findex = this.filters.findIndex((x) => x.includes('search='));

    if (findex !== -1) {
      this.filters.splice(findex, 1);
    }
    this.filters.push('search=' + searchPhrase);
    this.timeSearch = setTimeout(() => {
      this.getAllConsultants(this.filters.join('&'));
    }, 300);
  }

  // Function to remove a filter from the selected filters and update the query parameters
  async removeFilter(filter: option) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = { ...params };
    });
    const { selectedFilters, queryParams } = await removeFilterFunc(
      filter,
      this.selectedFilters,
      this.queryParams
    );
    this.selectedFilters = selectedFilters;

    await this.router.navigate([], { queryParams });
  }

  // Function to handle the selection of CV type and update the potential results accordingly
  cvFile(tab: number) {
    this.selectedCvType = tab;
    if (tab === 0) {
      this.potentialResults = this.potentialResultsCv;
    }
    if (tab === 1) {
      this.potentialResults = this.potentialResultsFile;
    }
    this.loadLength = 30;
    this.queryParams['cvtype'] = tab;
    this.router.navigate([], {
      queryParams: this.queryParams,
    });
  }

  // Function to clear all selected filters and optionally perform a new search
  clearAll(newSearch?: boolean) {
    this.selectedFilters = [];
    this.contextService.globalFilterC.next([]);
    if (newSearch) {
      this.router.navigate([], {});
    }
  }

  ngOnDestroy(): void {
    if (this.globalFilterSubscription) {
      this.globalFilterSubscription.unsubscribe();
    }
  }
}
