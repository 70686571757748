<div class="shortlisted">
  <div class="sortBy">
    <p class="body-large textSecondary">Sort by</p>
    <app-button
      [class]="['small', 'ghost']"
      label="Sale status"
      [trailingIcon]="
        ascDesc ? 'sort-ascending' : 'sort-descending'
      "
      (click)="sortBySalestatus()"
    ></app-button>
  </div>
  <app-request-level1
    *ngFor="let result of shortlisted"
    [selectedRequest]="result"
  ></app-request-level1>
</div>
