<div class="filter">
  <div class="add-filter">
    <p class="label2">Filters</p>

    <div>
      <div (clickOutside)="defaultListActive = '';">
        <ng-container *ngFor="let list of defaultFilter; let i = index">
          <div *ngIf="list.default">
            <div class="empty-select">
              <div>
                <app-input *ngIf="list.type !== 'availability'" (blur)="list.input = ''"
                  [placeholder]="list.placeHolder ?? ''" (focus)="setSearch(list.type)"
                  (inputs)="searchInput($event, list.type)" [trailingIcon]="'chevron-down'"></app-input>
                <app-month-year-select [styles]="(i > 2 ? 'right:0px' : '')" *ngIf="list.type === 'availability'"
                  [value]="list.selected[0]" [selectedMonthRange]="list.monthRange ?? '0'" [type]="'start'"
                  (callbackFunction)="selectedValue($event.value, $event.type ?? list.type)"
                  [placeholder]="'Availability'" trailingIcon="chevron-down"></app-month-year-select>
              </div>

            </div>
            <div *ngIf="!list.disabled" [class]="{'active': defaultListActive === list.type}">
              <div *ngIf="defaultListActive === list.type" class="select">
                <div class="top">
                  <div *ngIf="!list.default" class="search"> <input [(ngModel)]="list.input" (blur)="list.input = ''"
                      #inputSearch type="search" placeholder="Search" (focus)="setSearch(list.type)"
                      (input)="search($event, list.type)" /></div>
                </div>
                <div class="list">
                  <ng-container *ngIf="type === 'proData'">
                    <app-checkboxSelect [type]="list.type" (callbackFunction)="selectedValue($event, list.type)"
                      class="select_box" [proData]="getProData(list)" [selected]="list.selected"
                      [multiselect]="list.multiSelect ? list.multiSelect : false">
                    </app-checkboxSelect>
                  </ng-container>
                  <ng-container *ngIf="type === ''">
                    <app-checkboxSelect [type]="list.type" (callbackFunction)="selectedValue($event, list.type)"
                      class="select_box" [data]="getSelectData(list)" [selected]="list.selected"
                      [multiselect]="list.multiSelect ? list.multiSelect : false">
                    </app-checkboxSelect>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div (clickOutside)="filterActive = false;" class="advanced-filter">
        <app-button *ngIf="anyAdvanced()" (click)="openFilter()"
          [class]="this.filterActive ? ['active','secondary']: ['secondary']" [leadingIcon]="'filter'"></app-button>
        <div *ngIf="filterActive" class="select">
          <div class="list" [class]="{'hideOverflow': listActive}">
            <p class="filter-title">Advanced filters</p>
            <ng-container *ngFor="let group of ['1','2','3','4']">
              <p class="baseline_small">{{getGroupName(group)}}</p>
              <ng-container *ngFor="let list of filterList">
                <div *ngIf="!list.disabled && !list.default && list.group === group"
                  [class]="{'active': listActive === list.type}">
                  <div class="first-list" (click)="selectList(list.type)">
                    <p>{{list.title}}</p>
                    <div>
                      <p *ngIf="list.selected.length > 0"><span>(</span>{{list.selected.length}}<span>)</span></p>
                      <fa-icon [icon]="list.icon"></fa-icon>
                    </div>
                  </div>
                  <div *ngIf="listActive === list.type" class="select">
                    <div class="top">
                      <div (click)="selectList(''); filterActive = true">
                        <div>
                          <fa-icon [icon]="faChevronLeft"></fa-icon>
                        </div>
                        <p class="title">{{list.title}}</p>
                      </div>
                      <div class="search"> <input placeholder="Search" [(ngModel)]="list.input" (blur)="list.input = ''"
                          #inputSearch type="search" (focus)="setSearch(list.type)"
                          (input)="search($event, list.type)" /></div>
                    </div>
                    <div class="list">

                      <ng-container *ngIf="type === 'proData'">
                        <app-checkboxSelect [type]="list.type" (callbackFunction)="selectedValue($event, list.type)"
                          class="select_box" [proData]="getProData(list)" [selected]="list.selected"
                          [multiselect]="list.multiSelect ? list.multiSelect : false">
                        </app-checkboxSelect>
                      </ng-container>
                      <ng-container *ngIf="type === ''">
                        <app-checkboxSelect [type]="list.type" (callbackFunction)="selectedValue($event, list.type)"
                          class="select_box" [data]="getSelectData(list)" [selected]="list.selected"
                          [multiselect]="list.multiSelect ? list.multiSelect : false">
                        </app-checkboxSelect>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedFilter.length > 0" class="selected-filter">
      <div *ngFor="let selected of selectedFilter">
        <div class="btns">
          <div (click)="selectedValue(selected.id, selected.type, true)" class="btn btn-select">
            {{getSelected(selected.id, selected.type)}}
          </div>
        </div>
      </div>

      <p (click)="clearAll()" class="body-small link clear-all" *ngIf="anyFilter()">Clear all</p>
    </div>
  </div>


</div>
