<div class="availability" [class]="{disabled}">
  <div
    *ngFor="
      let available of [
        { id: '0available', displayName: 'Now', type: 'availability' },
        { id: '1available', displayName: 'Next 30 days', type: 'availability' },
        {
          id: '2available',
          displayName: 'Next 60 days',
          type: 'availability'
        },
        { id: '3available', displayName: 'Custom range', type: 'availability' }
      ];
      let i = index
    "
  >
    <app-select-items
      (click)="this.selectAvailability(available)"
      [isChecked]="selected.option.id === available.id"
      type="radio"
      [label]="available.displayName"
    ></app-select-items>
  </div>
  <div *ngIf="selected.option.id === '3available'" class="date-range">
    <div class="select-range">
      <p class="body-small bold textSecondary">From - To</p>
      <div class="calendar">
        <mat-form-field>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input
              (click)="picker.open()"
              matStartDate
              formControlName="start"
              placeholder="m/d/yyyy"
              class="body-small"
            />
            <input
              (click)="picker.open()"
              matEndDate
              formControlName="end"
              placeholder="m/d/yyyy"
              (dateChange)="dateChange()"
              class="body-small"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
