<div
  [class]="'tabButtons ' + (disable ? ' disable ' : ' ') + classList"
>
  <div
    [style]="style"
    (click)="callbackFunction.emit(i)"
    [class]="getClass(i) + ' ' + classList"
    *ngFor="let tab of tabButtons; let i = index"
  >
    <i-tabler [name]="tab.icon"></i-tabler>
    <p>{{ tab.displayName }}</p>
    <app-badge
      [color]="
        'amount ' +
        (selected === i ? 'brand--Dark textContrast' : 'brand--Light textLink')
      "
      [label]="tab.amount"
      *ngIf="tab.amount !== undefined"
    ></app-badge>
  </div>
</div>
