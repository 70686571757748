import { Injectable } from '@angular/core';
import { FEATURE_FLAGS } from 'src/features';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  setFeatureEnabled(feature: string) {
    FEATURE_FLAGS[feature] = true;
  }
  isFeatureEnabled(feature: string): boolean {
    if (FEATURE_FLAGS[feature] === undefined) {
      return true;
    }
    return !!FEATURE_FLAGS[feature];
  }
}
