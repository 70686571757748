import { Component, Input } from '@angular/core';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  @Input() label: string;
  @Input() excerpt: string;
  @Input() matching: boolean;
  constructor(public contextService: ContextService) {}
}
