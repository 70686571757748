import { Component } from '@angular/core';
import { countryCode, routeParams } from 'src/interface/shared.interface';
import { ContextService } from 'src/services/platform/context.service';
import countries from 'src/assets/countries';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent {
  selected = '';
  qualificationImportance = [
    'Minimal',
    'Limited',
    'Moderate',
    'Considerable',
    'Maximum',
  ];
  advancedSearch = this.contextService.advancedSearch;
  countryList = countries.list;
  matchCountry = true;
  constructor(
    private contextService: ContextService,
    private activeRoute: ActivatedRoute
  ) {
    this.activeRoute.params.subscribe((params) => {
      params = params as routeParams;
      if (params['deeplink'] === 'ctr') {
        this.matchCountry = false;
      }
    });
  }

  selectCountry(value: countryCode) {
    this.advancedSearch.countryCode = value.value!;
    localStorage.setItem('countryCode', this.advancedSearch.countryCode);
  }

  getSelectedCountry(countryCode: string) {
    if (!countryCode) {
      return { value: '', displayName: '' };
    }
    return this.countryList.find((x) => x.value === countryCode);
  }

  selectImportancy(qI: string) {
    this.selected = qI;
    this.advancedSearch.qualificationImportance = qI;
  }
  customInput(value: string) {
    this.advancedSearch.qualification = value;
  }
  findIndex() {
    return this.qualificationImportance.findIndex(
      (x) => x === this.advancedSearch.qualificationImportance
    );
  }
}
