<div class="level2">
  <div class="left">
    <div class="general">
      <p class="body-small textSecondary bold">General</p>
      <div class="bundle-info body-small">
        <div>
          <app-avatar
            [consultant]="consultant"
            [salesAgent]="true"
            class="xs"
          ></app-avatar>
          <p class="body-small ellipsis">{{ getFullname(consultant, true) }}</p>
        </div>
        <div class="email">
          <i-tabler name="mail"></i-tabler>
          <p class="body-small ellipsis">{{ dashIfEmpty(consultant.email) }}</p>
        </div>
        <div>
          <i-tabler name="phone"></i-tabler>
          <p class="body-small ellipsis">{{ dashIfEmpty(consultant.phoneNumber) }}</p>
        </div>
      </div>
    </div>
    <div class="current-short">
      <p class="body-small textSecondary bold">Currently shortlisted</p>
      <div class="bundle-info body-small">
        <div>
          <i-tabler name="file-text"></i-tabler>
          <p class="body-small">
            {{ consultant.shortlistedSalesRequests.length }}
            requests
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="right body-small">
    <ng-container *ngIf="selectedCvType === 0">
      <div class="previousRoles textSecondary bold header">
        <p class="body-small bold">
          Previous Roles
          <app-badge
            [color]="'amount neutral--Grey-300 textSecondary'"
            [label]="consultant.previousRoles.length"
          ></app-badge>
        </p>
        <div class="previousRolesItems">
          <span class="rolesItem"
            *ngFor="
              let previousRoles of consultant.previousRoles;
              let last = last
            ">
              {{ previousRoles }}
            <span *ngIf="!last" class="li"></span>
          </span>
        </div>
      </div>
      <div class="contracts">
        <div class="textSecondary bold header">
          <p class="body-small">Latest experience</p>
          <div class="body-small experience">
            <p class="body-small">
              {{ consultant.experience.experienceCount }} positions
            </p>
            <div class="li"></div>
            <p class="experience-years body-small">
              {{ consultant.experience.yearsOfExperience }} years of experience
            </p>
          </div>
        </div>
        <div>
          <div
            class="body-small"
            *ngFor="let experience of consultant.experience?.experiences | slice: 0: 3"
          >
            <p class="body-small">
              <span class="bold">{{ experience.jobTitle }}</span> at
              {{ experience.customerName }}
            </p>
            <div class="li"></div>
            <p class="textSecondary bold date">
              {{ getMonthYear(experience.startDate, experience.endDate) }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedCvType === 1">
      <div class="incomplete-profile">
        <div>
          <p class="body-large bold">This profile is incomplete.</p>
          <p class="body-small">
            This user hasn’t filed out their 7N Profile yet.
          </p>
        </div>
        <div class="link-buttons">
          <app-button
            style="width: 180px; justify-content: space-between"
            (click)="downloadUrl($event)"
            [class]="['brand--Lighter', 'textLink', 'small']"
            [label]="consultant.cvFile ? consultant.cvFile.filename : 'File CV'"
            [disable]="!consultant.cvFile"
            [trailingIcon]="'download'"
          ></app-button>
          <app-button
            style="width: 180px;"
            [disable]="!consultant.linkedIn"
            (click)="openExternal($event, consultant.linkedIn)"
            [class]="['brand--Lighter', 'textLink', 'small']"
            label="Go to LinkedIn"
            [leadingIcon]="'brand-linkedin'"
          ></app-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
