<table>
  <tbody>
    <tr>
      <th class="check-all">
        <div (click)="select('-1')">
          <input [checked]="selected.length === list.length" type="checkbox" />
          <span class="checkmark"></span>
        </div>
      </th>
      <ng-container *ngFor="let list of tableList">
        <th *ngIf="!list.disable">
          {{list.title}}
        </th>
      </ng-container>
      <th *ngIf="type !== 'match'"></th>
    </tr>
    <ng-container *ngIf="list">
      <tr (click)="clickRow ? openList(value.id): ''"
        *ngFor="let value of list | slice:0:listLimit !== -1 ? listLimit: list.length;  let i = index"
        [class]="{'selected': selected.includes(value.id), 'added': value['added']}">

        <td (click)="firstTd()">

          <div (click)="select(value.id)">
            <input [checked]="selected.includes(value.id)" type="checkbox" />
            <span class="checkmark"></span>
          </div>
        </td>
        <ng-container *ngFor="let list of tableList">
          <td *ngIf="!list.disable">
            <span [class]="{'disMaxWidth': list.typeOf[0] === 'submissionTime'}">

              <ng-container *ngFor="let typeOf of list.typeOf">
                <span class="score" *ngIf="typeOf === 'score'">
                  0.{{convertToProcent(getValue(value, typeOf))}}
                  <div>
                    <div [style.width]="convertToProcent(getValue(value, typeOf)!)+'%'"></div>
                  </div>
                </span>
                <span *ngIf="typeOf === 'externalLink'">
                  <div class="link externalLink btn" (click)="href(getValue(value, typeOf))">
                    <fa-icon [icon]="faLinkSimple"></fa-icon>
                  </div>
                </span>
                <span *ngIf="typeOf === 'view'">
                  <div class="link externalLink btn" (click)="openList(value.id)">
                    <fa-icon [icon]="faLinkSimple"></fa-icon>
                  </div>
                </span>
                <span class="canWorkRemote" *ngIf="typeOf === 'canWorkRemote'">
                  <ng-container *ngIf="getValue(value, typeOf)">Remote</ng-container>
                  /

                  <ng-container *ngFor="let country of value['workLocations']">
                    {{country.name}}
                    -
                    <ng-container *ngFor="let state of country.states; let last = last">
                      {{state}}<span *ngIf="!last">,</span>
                    </ng-container>
                  </ng-container>

                </span>
                <ng-container
                  *ngIf="typeOf !== 'externalLink' && typeOf !== 'score' && typeOf !== 'canWorkRemote'  && typeOf !== 'view'">
                  {{getValue(value, typeOf)}}
                </ng-container>
              </ng-container>
            </span>
          </td>
        </ng-container>
        <td *ngIf="type !== 'match'" class="arrow">
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="potentialRequests">
      <tr
        *ngFor="let value of potentialRequests | slice:0:listLimit !== -1 ? listLimit: potentialRequests.length;  let i = index">

        <td>

          <div (click)="select(value.id!)">
            <input [checked]="selected.includes(value.id!)" type="checkbox" />
            <span class="checkmark"></span>
          </div>
        </td>
        <ng-container *ngFor="let list of tableList">
          <td *ngIf="!list.disable">
            <span>
              <ng-container *ngFor="let typeOf of list.typeOf">
                <span class="score" *ngIf="typeOf === 'score'">
                  0.{{convertToProcent(value.score)}}
                  <div>
                    <div [style.width]="convertToProcent(value.score!)+'%'"></div>
                  </div>
                </span>
                <span *ngIf="typeOf === 'externalLink'">
                  <div class="link externalLink btn" (click)="href(value.externalLink)">
                    <fa-icon [icon]="faLinkSimple"></fa-icon>
                  </div>
                </span>
                <span *ngIf="typeOf === 'view'">
                  <div class="link externalLink btn" (click)="openList(value.id!)">
                    <fa-icon [icon]="faLinkSimple"></fa-icon>
                  </div>
                </span>
                <ng-container
                  *ngIf="typeOf !== 'externalLink' && typeOf !== 'score' && typeOf !== 'canWorkRemote'  && typeOf !== 'view'">
                  {{getRequestValue(value, typeOf)}}
                </ng-container>
              </ng-container>
            </span>
          </td>
        </ng-container>
        <td *ngIf="type !== 'match'" class="arrow">
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div class="loading" *ngIf="loading && type !== 'match'">
  <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
</div>
