<app-request
  *ngIf="requestId"
  [requestId]="requestId"
  [loading]="loading"
  [rerender]="rerender"
  (callbackFunction)="loading = $event"
></app-request>

<div class="surface--2">
  <app-loader *ngIf="loading" [loading]="loading"></app-loader>
</div>
<div *ngIf="!loading && !requestId" class="requests">
  <div class="top surface--2">
    <app-page-title label="Requests"></app-page-title>

    <div class="filter">
      <app-input
        (inputs)="search($event)"
        [leadingIcon]="'search'"
        placeholder="Search for a request"
        [inputType]="'search'"
      ></app-input>
    </div>
  </div>

  <table class="body1 surface--2">
    <tr>
      <th>
        <p (click)="sort('title')" class="label2">
          Job request
          <i-tabler
            *ngIf="key === 'title'"
            [name]="desAsc ? 'sort-descending' : 'sort-ascending'"
          ></i-tabler>
        </p>
      </th>
      <th>
        <p (click)="sort('customerName')" class="label2">
          Client
          <i-tabler
            *ngIf="key === 'customerName'"
            [name]="desAsc ? 'sort-descending' : 'sort-ascending'"
          ></i-tabler>
        </p>
      </th>
      <th>
        <p (click)="sort('longlist')" class="label2">
          Long List
          <i-tabler
            *ngIf="key === 'longlist'"
            [name]="desAsc ? 'sort-descending' : 'sort-ascending'"
          ></i-tabler>
        </p>
      </th>
      <th>
        <p (click)="sort('shortlist')" class="label2">
          Short list<i-tabler
            *ngIf="key === 'shortlist'"
            [name]="desAsc ? 'sort-descending' : 'sort-ascending'"
          ></i-tabler>
        </p>
      </th>
      <th>
        <p (click)="sort('priority')" class="label2">
          Priority<i-tabler
            *ngIf="key === 'priority'"
            [name]="desAsc ? 'sort-descending' : 'sort-ascending'"
          ></i-tabler>
        </p>
      </th>
      <th>
        <p (click)="sort('owner')" class="label2">
          Sales agent<i-tabler
            *ngIf="key === 'owner'"
            [name]="desAsc ? 'sort-descending' : 'sort-ascending'"
          ></i-tabler>
        </p>
      </th>
      <th>
        <p (click)="sort('createdAt')" class="label2">
          Date created<i-tabler
            *ngIf="key === 'createdAt'"
            [name]="desAsc ? 'sort-descending' : 'sort-ascending'"
          ></i-tabler>
        </p>
      </th>
    </tr>
    <tr (click)="openRequest(request.id)" *ngFor="let request of salesRequests">
      <td>
        <div>
          <i-tabler name="file-text"></i-tabler>
          <p class="body1">{{ request.title }}</p>
        </div>
      </td>
      <td>
        <div>
          <p class="body2">{{ request.customerName }}</p>
        </div>
      </td>
      <td>
        <p *ngIf="request.longlist" class="body2">
          {{ request.longlist.length }}
        </p>
      </td>
      <td>
        <p *ngIf="request.shortlist" class="body2">
          {{ request.shortlist.length }}
        </p>
      </td>
      <td>
        <p class="body2">{{ request.priority }}</p>
      </td>
      <td>
        <p class="body2">{{ request.owner }}</p>
      </td>
      <td>
        <p class="body2">{{ getDate(request.createdAt) }}</p>
      </td>
    </tr>
  </table>
</div>
