import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FilterWorkLocation, option } from 'src/interface/shared.interface';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnChanges {
  @Input() clearFromParent: boolean;
  @Input() disabled: boolean;
  @Input() worklocation?: FilterWorkLocation[];
  states: option[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();
  selectOption(option: option) {
    if (option.id === '-1') {
      this.selected = [];
      this.selectedOption.emit(this.selected);
      return;
    }
    const findex = this.selected.findIndex((x) => x.id === option.id);
    if (findex === -1) {
      this.selected.push(option);
      this.selectedOption.emit(this.selected);
      return;
    }
    this.selected.splice(findex, 1);
    this.selectedOption.emit(this.selected);
  }

  ngOnChanges() {
    if (this.worklocation) {

      this.states = [];
      this.worklocation.forEach((country, i) => {
        const states = country.states.map((x: string, j) => {
          return { id: i + '' + j, displayName: x, type: 'location' };
        });
        this.states = [...this.states, ...states];
      });
    }
  }
}
