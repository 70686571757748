import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faArrowUpArrowDown,
  faChevronRight,
  faSliders,
  faPlus,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import {
  filterOption,
  list,
  ProProfile,
  routeParams,
  SelectedFilter,
  tableList,
} from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() type = '';
  faPlus = faPlus;
  faSliders = faSliders;
  faChevronRight = faChevronRight;
  faArrowUpArrowDown = faArrowUpArrowDown;
  faSpinner = faSpinner;
  allCandidates: list[];
  selected: string[] = [];
  allCandidatesList: list[] = [];
  loadLength = 50;
  showSearch = '';
  filterList: filterOption[] = [];
  skills: ProProfile[] = [];
  technologies: ProProfile[] = [];
  families: ProProfile[] = [];
  input = '';
  loading = true;
  filterActive = false;
  tableList: tableList[] = [];
  constructor(
    private contextService: ContextService,
    private router: Router,
    private platformService: PlatformService,
    private activatedRoute: ActivatedRoute,
    private helperFunctions: HelperFunctionsService
  ) {
    let slug = '';

    this.activatedRoute.params.subscribe((val) => {
      slug = val['slug'];
      this.platformService.getData(slug);
      if (slug === 'unsubmitted') {
        this.tableList = this.contextService.unsubmittedList;
      }
      if (slug !== 'unsubmitted') {
        this.tableList = this.contextService.tableList;
      }
    });

    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'success') {
        this.allCandidatesList = this.contextService.profileData;
        this.allCandidatesList.map((val: list) => {
          val.sortDate = 0;
          if (val.submissionTime) {
            val.sortDate = val.submissionTime;
          } else {
            val.sortDate = val.createdAtTime;
          }
        });
        this.allCandidatesList.sort(this.compare);

        this.technologies = this.contextService.technologiesData;

        const selectedFilter: SelectedFilter[] =
          JSON.parse(sessionStorage.getItem('selectedFilter')!) ?? [];
        this.filterList = [
          {
            data: this.helperFunctions.uniqueProProfile(
              this.contextService.skillsData
            ),
            raw: this.contextService.skillsData,
            title: 'Skills',
            icon: faChevronRight,
            type: 'skills',
            selected: [],
            input: '',
            multiSelect: true,
            group: '1',
          },
          {
            data: this.helperFunctions.uniqueProProfile(
              this.contextService.technologiesData
            ),
            raw: this.contextService.technologiesData,
            title: 'Technologies',
            icon: faChevronRight,
            type: 'technologies',
            selected: [],
            input: '',
            multiSelect: true,
            group: '1',
          },

          {
            data: this.helperFunctions.uniqueProProfile(
              this.contextService.industriesData
            ),
            raw: this.contextService.industriesData,
            title: 'Industries',
            icon: faChevronRight,
            type: 'industries',
            selected: [],
            input: '',
            multiSelect: true,
            group: '1',
          },
          {
            data: this.helperFunctions.uniqueProProfile(
              this.contextService.countryCode
            ),
            raw: this.contextService.countryCode,
            title: 'Country',
            icon: faChevronRight,
            type: 'countryCode',
            selected: [],
            input: '',
            multiSelect: true,
            group: '2',
          },
          {
            data: this.contextService.yearExperience,
            raw: this.contextService.yearExperience,
            title: 'Year of experience',
            icon: faChevronRight,
            type: 'yearExperience',
            selected: [],
            input: '',
            multiSelect: false,
            group: '3',
          },
          {
            data: this.contextService.jobLevel,
            raw: this.contextService.jobLevel,
            title: 'Job Level',
            icon: faChevronRight,
            type: 'levelWork',
            selected: [],
            input: '',
            multiSelect: false,
            group: '3',
          },
          {
            data: this.helperFunctions.uniqueProProfile(
              this.contextService.familiesData
            ),
            raw: this.contextService.familiesData,
            title: 'Job Families',
            placeHolder: 'Select job family',
            icon: faChevronRight,
            type: 'jobFamily',
            selected: [],
            input: '',
            multiSelect: true,
            default: true,
            group: '3',
          },
          {
            data: this.helperFunctions.uniqueProProfile(
              this.contextService.campaign
            ),
            raw: this.contextService.campaign,
            title: 'Campaign',
            icon: faChevronRight,
            type: 'campaign',
            selected: [],
            input: '',
            multiSelect: false,
            group: '4',
          },
          {
            data: [
              { displayName: 'Yes', id: '1' },
              { displayName: 'No', id: '2' },
            ],
            raw: [
              { displayName: 'Yes', id: '1' },
              { displayName: 'No', id: '2' },
            ],
            title: 'Work as consultant',
            icon: faChevronRight,
            type: 'asConsultant',
            selected: [],
            input: '',
            multiSelect: false,
            group: '3',
          },
          {
            data: [
              { displayName: 'Yes', id: '1' },
              { displayName: 'No', id: '2' },
            ],
            raw: [
              { displayName: 'Yes', id: '1' },
              { displayName: 'No', id: '2' },
            ],
            title: 'My7N',
            icon: faChevronRight,
            type: 'labels',
            selected: [],
            input: '',
            multiSelect: false,
            group: '3',
          },
          {
            data: [],
            raw: [],
            title: 'Availability',
            icon: faChevronRight,
            type: 'availability',
            selected: [],
            monthRange: '-1',
            input: '',
            default: true,
            placeHolder: 'Availability',
          },
        ];


        selectedFilter.forEach((val: SelectedFilter) => {
          const findex = this.filterList.findIndex((x) => x.type === val.type);
          if (findex !== -1) {
            this.filterList[findex].selected.push(val.id);
          }
          if (val.type === 'monthRange') {
            const findex = this.filterList.findIndex(
              (x) => x.type === 'availability'
            );
            if (findex !== -1) {
              this.filterList[findex].monthRange = val.id;
              this.filterList[findex].selected.push(val.id);
            }
          }
        });

        this.contextService.listLimit.length =
          this.contextService.profileData.length;
        this.contextService.listLimit.filterLength = this.loadLength;
        if (this.contextService.listLimit.length < this.loadLength) {
          this.contextService.listLimit.filterLength =
            this.contextService.profileData.length;
        }
        this.loading = false;
      } else {
        this.allCandidatesList = [];
        this.loading = true;
      }
      this.filteringList();
    });
  }
  compare(a: list, b: list) {
    if (a.sortDate > b.sortDate) {
      return -1;
    }
    if (a.sortDate < b.sortDate) {
      return 1;
    }
    return 0;
  }
  getSelected(id: string, type: string) {
    return this.filterList
      .find((x) => x.type === type)!
      .raw.find((x) => x.id === id)?.displayName;
  }

  setSearch(type: string) {
    this.showSearch = type;
    this.filterList.find((x) => x.type === type)!.data =
      this.helperFunctions.uniqueProProfile(
        this.filterList.find((x) => x.type === type)!.raw
      );
  }
  loadMore(value: number) {
    this.loadLength += value;
    this.contextService.listLimit.filterLength = this.loadLength;
  }
  search(e: Event, type: string) {
    const element = e.currentTarget as HTMLInputElement;
    if (element.value.length > 0) {
      const data = JSON.parse(
        JSON.stringify(this.filterList.find((x) => x.type === type)?.data)
      );

      this.filterList.find((x) => x.type === type)!.data = data.filter(
        (x: ProProfile) =>
          x.displayName.toLowerCase().includes(element.value.toLowerCase())
      );
    }
  }

  filteringList() {
    this.allCandidatesList = this.helperFunctions.filteringList(
      this.filterList,
      this.contextService
    );
  }

  select(id: string) {
    if (id === '-1') {
      if (
        this.selected.length > 0 &&
        this.selected.length === this.allCandidates.length
      ) {
        this.selected = [];
        return;
      }

      this.allCandidates.forEach((x: list) => {
        const findex = this.selected.findIndex((z) => z === x.id);
        if (findex === -1) {
          this.selected.push(x.id);
          return;
        }
      });

      return;
    }
    const findex = this.selected.findIndex((x) => x === id);
    if (findex === -1) {
      this.selected.push(id);
      return;
    }
    this.selected.splice(findex, 1);
  }
  openCandidate(value: string[]) {
    const id = value[1];
    this.activatedRoute.params.subscribe((val) => {
      val = val as routeParams;
      this.helperFunctions.route(['/' + val['slug'] + '/freelance/' + id]);
    });
  }
}
