import { Component, Input } from '@angular/core';
import { matchConsultants } from 'src/interface/shared.interface';
import { tier } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-tier',
  templateUrl: './tier.component.html',
  styleUrls: ['./tier.component.scss'],
})
export class TierComponent {
  @Input() tier: tier;
  @Input() id: string;
  @Input() expandable = true;
  @Input() class: string;
  constructor(
    private helperFunction: HelperFunctionsService,
    public contextService: ContextService
  ) {}
  isAnyRedDot() {
    if (['Tier3WithNotice', 'Tier2WithNotice'].includes(this.tier.tierStatus)) {
      return true;
    }

    return false;
  }
  isRedDot(tier: string) {
    if (
      tier === 'profileStrength' &&
      this.tier.tierStatus === 'Tier3WithNotice'
    ) {
      return true;
    }
    if (tier === 'profileUpdatedDays' && this.getRule(40).input > '180') {
      return true;
    }
    if (
      tier === 'recruited' &&
      this.getRule(40).input > '0' &&
      this.getStatus() === 'Approved'
    ) {
      return true;
    }
    return false;
  }
  getCompletedDate(recruitedUpdated?: string, amountDays?: string) {
    if (recruitedUpdated) {
      return this.helperFunction.getCompletedDate(recruitedUpdated);
    }
    if (amountDays) {
      return this.helperFunction.getCompletedDate(
        '',
        false,
        JSON.parse(amountDays)
      );
    }
    return null;
  }
  getStatus() {
    return this.helperFunction.getStatus(this.tier);
  }
  expandTier(event: Event) {
    event.stopPropagation();
    if (this.expandable) {
      if (this.contextService.expandableTier === this.id) {
        this.contextService.expandableTier = '';
      } else {
        this.contextService.expandableTier = this.id;
      }
    }
  }
  biggerThanStep(level: number) {
    const recruited = this.tier.recruitmentSequence;
    const findex = this.helperFunction.recruitedStatus.findIndex((x) =>
      [recruited].includes(x)
    );
    if (findex >= level) {
      return true;
    }
    return false;
  }
  getRule(id: number) {
    if (!this.tier.evaluatedRules) {
      return {
        input: null,
        evaluationResult: true,
      };
    }
    return (
      this.tier.evaluatedRules.find((x: any) => x.id === id) ?? {
        input: null,
        evaluationResult: true,
      }
    );
  }
  getColor() {
    if (this.getStatus() === 'Premium') {
      return 'success--Light';
    }
    if (this.getStatus() === 'Approved') {
      return 'warning--Light';
    }

    return 'neutral--Grey-300';
  }
  getIcon() {
    if (this.getStatus() === 'Premium') {
      return 'crown';
    }
    if (this.getStatus() === 'Approved') {
      return 'sparkles';
    }

    return 'user-plus';
  }
}
