import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { matchConsultants } from 'src/interface/shared.interface';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-find-consultant',
  templateUrl: './find-consultant.component.html',
  styleUrls: ['./find-consultant.component.scss'],
})
export class FindConsultantComponent implements OnInit {
  @Output() selected = new EventEmitter<matchConsultants>();

  selectedconsultant?: matchConsultants =
    this.contextService.selectedConsultant;
  clearConsultant: boolean;
  
  constructor(
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    if (this.selectedconsultant) {
      if (this.selectedconsultant.id === '-1') {
        this.clearConsultant = true;
      }
    }
  }

  cta(value: string) {
    if (value === 'close') {
      this.clearConsultant = true;
    }
  }

  selectconsultant(consultant: matchConsultants | undefined) {
    this.selectedconsultant = consultant;
    this.clearConsultant = false;
    this.selected.emit(consultant);
  }
}
