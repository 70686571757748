import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ruleLength, select } from 'src/interface/shared.interface';
import { ProProfile } from 'src/interface/shared.interface';

@Component({
  selector: 'app-checkboxSelect',
  templateUrl: './checkboxSelect.component.html',
  styleUrls: ['./checkboxSelect.component.scss'],
})
export class CheckboxSelectComponent {
  @Input() data: select[] = [];
  @Input() proData: ProProfile[] = [];
  @Input() multiselect = false;
  @Input() type!: string;
  @Input() selected: string[] = [];
  @Output() callbackFunction = new EventEmitter<string>();

  ruleLength: ruleLength = {
    languages: 1,
  };

  click(id: string) {
    this.callbackFunction.emit(id);
  }
}
