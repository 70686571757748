import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { experience, profile3 } from 'src/interface/shared.interface';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements AfterViewInit {
  @Input() consultant: profile3;
  @Input() selectedTap: number;
  @Output() downloadUrl = new EventEmitter<string>();
  @Output() openExternal = new EventEmitter<string>();
  @ViewChild('summaryText') summaryText: ElementRef<HTMLElement>;
  truncated: boolean;
  readMore: boolean;
  selectedTab = 0;
  consultantRaw: experience;
  constructor() {
    setTimeout(() => {
      this.isTruncated();
    });
  }
  observer = new ResizeObserver(() => {
    this.isTruncated();
  });
  clickReadMore() {
    this.readMore = !this.readMore;
  }
  ngAfterViewInit() {
    if (this.summaryText) {
      this.observer.observe(this.summaryText.nativeElement);
    }
  }

  isTruncated() {
    if (this.summaryText) {
      const element = this.summaryText.nativeElement;
      if (!this.readMore) {
        this.truncated = element.offsetHeight < element.scrollHeight;
      }
    }
  }
  selectTab(value: number) {
    this.selectedTab = value;
    setTimeout(() => {
      this.isTruncated();
    });
  }
}
