<div [class]="'badge ' + color">
  <ng-container *ngIf="icon?.type">
    <img *ngIf="icon.type === 'svg'" [src]="icon.icon" />
    <i-tabler *ngIf="icon?.type === 'icon'" [name]="icon.icon"></i-tabler>
  </ng-container>
  <p class="tiny" [attr.title]="label">{{ label }}</p>
  <div class="icon" (click)="callbackFunction.emit()" *ngIf="trailingIcon">
    <img *ngIf="trailingIcon.type === 'svg'" [src]="trailingIcon.icon" />
    <i-tabler
      *ngIf="trailingIcon?.type === 'icon'"
      [name]="trailingIcon.icon"
    ></i-tabler>
  </div>
  <div *ngIf="amount">
    <app-badge
      [color]="'amount highlight--Medium textLightest'"
      [label]="amount"
    ></app-badge>
  </div>
</div>
