<div [class]="openSidePanel ? 'open' : ''" class="surface--3 navigation">
  <div
    class="nav"
    [class]="openSidePanel ? 'open' : ''"
    *ngFor="let nav of navs; let i = index"
  >
    <ng-container *ngIf="nav.allowed">
      <div class="wrap-nav">
        <app-navbar
          [amount]="nav.count"
          (click)="selectNav(nav.slug, nav.submenu)"
          [number]="!nav.icon ? i + 1 + '.' : ''"
          [class]="getClasses(nav)"
          [label]="nav.displayName"
          [leadingIcon]="nav.icon ? nav.icon : ''"
          [leadingIcon]="nav.icon ?? ''"
          [trailingIcon]="nav.state ? 'check' : ''"
        ></app-navbar>
      </div>
      <div class="submenu" *ngFor="let menu of nav.submenu">
        <div (click)="selectNav(menu.slug)" class="wrap-menu">
          <app-navbar
            [amount]="menu.count"
            (click)="selectNav(menu.slug, menu.submenu)"
            [class]="getClasses(menu)"
            [label]="menu.displayName"
            [leadingIcon]="menu.icon ? menu.icon : ''"
            [trailingIcon]="menu.state ? 'check' : ''"
          ></app-navbar>
        </div>
      </div>
    </ng-container>
  </div>
</div>
