import { Component } from '@angular/core'
import { environment } from '../../../../../environments/environment'
@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})
export class CommunityComponent {
  communityUrl = environment.communityUrl
  open() {
    window.open(this.communityUrl)
  }
}
