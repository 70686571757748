import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-support',
  templateUrl: './mobile-support.component.html',
  styleUrls: ['./mobile-support.component.scss']
})
export class MobileSupportComponent {

}
