import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tier } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-recruited-steps',
  templateUrl: './recruited-steps.component.html',
  styleUrls: ['./recruited-steps.component.scss'],
})
export class RecruitedStepsComponent {
  @Input() tier: tier;
  @Input() trailingIcon: string;
  @Input() date: string;
  @Input() leadingIcon: string;
  @Output() callbackFunction = new EventEmitter();

  constructor(private helperFunctions: HelperFunctionsService) {}
  click() {
    this.callbackFunction.emit();
  }
  getStatus() {
    return this.helperFunctions.getStatus(this.tier);
  }
  biggerThanStep(level: number) {
    const recruited = this.tier.recruitmentSequence;
    const findex = this.helperFunctions.recruitedStatus.findIndex((x) =>
      [recruited].includes(x)
    );
    if (findex >= level) {
      return true;
    }
    return false;
  }
  getDate(recruitedUpdated: string) {
    if (!recruitedUpdated) {
      return null;
    }
    const timestamp = Math.round(new Date(recruitedUpdated).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false, false);
  }
}
