import { Component, Input, OnInit } from '@angular/core';
import {
  requestMatches,
  requestMatchesLevels1,
} from 'src/interface/shared.interface';
import { shortlistedSalesRequests } from 'src/interface/shared.interface';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-shortlisted',
  templateUrl: './shortlisted.component.html',
  styleUrls: ['./shortlisted.component.scss'],
})
export class ShortlistedComponent {
  @Input() shortlistedRequests: shortlistedSalesRequests[] = [];
  shortlisted: requestMatchesLevels1[] = [];
  ascDesc: boolean;
  salesStatuses: any[];
  constructor(private platformService: PlatformService) {
    this.platformService.getConsultantStatus().then((response) => {
      this.salesStatuses = response;
      this.sortBySalestatus();
    });

    this.platformService.getRequests().then((response) => {
      response.data.forEach((r: requestMatchesLevels1) => {
        if (this.shortlistedRequests.some((x) => x.id === r.id)) {
          this.shortlisted.push(r);
        }
      });
    });
  }

  // Toggles the sort order and sorts the shortlisted requests by sales status
  sortBySalestatus() {
    this.ascDesc = !this.ascDesc;
    this.shortlistedRequests.sort((a: any, b: any) => {
      return !this.ascDesc ? this.ascending(a, b) : this.decending(a, b);
    });
  }

  // Compares two shortlisted sales requests in ascending order based on their sales status
  ascending(a: shortlistedSalesRequests, b: shortlistedSalesRequests) {
    const indexA = this.salesStatuses.findIndex(
      (x) => x.name === a.salesStatus
    );
    const indexB = this.salesStatuses.findIndex(
      (x) => x.name === b.salesStatus
    );
    if (indexA > indexB) {
      return 1;
    }
    if (indexA < indexB) {
      return -1;
    }
    return 0;
  }

  // Compares two shortlisted sales requests in descending order based on their sales status
  decending(a: shortlistedSalesRequests, b: shortlistedSalesRequests) {
    const indexA = this.salesStatuses.findIndex(
      (x) => x.name === a.salesStatus
    );
    const indexB = this.salesStatuses.findIndex(
      (x) => x.name === b.salesStatus
    );
    if (indexA > indexB) {
      return -1;
    }
    if (indexA < indexB) {
      return 1;
    }
    return 0;
  }
}
