import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option, tier } from 'src/interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-tier-select',
  templateUrl: './tier-select.component.html',
  styleUrls: ['./tier-select.component.scss'],
})
export class TierSelectComponent implements OnChanges {
  @Input() clearFromParent: boolean;
  @Input() disabled: boolean;
  @Input() tiers: tier[];
  options: option[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();

  selectOption(option: option) {
    const findex = this.selected.findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected.splice(findex, 1);
    } else {
      this.selected.push(option);
    }
    this.selectedOption.emit(this.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tiers && changes['selected']) {
      this.options = [];
      this.tiers.forEach((tier: tier) => {
        const findex = this.selected.findIndex(
          (x) => x.displayName === tier.status.contractStatus
        );
        this.options.push({
          id: findex !== -1 ? this.selected[findex].id : uuidv4(),
          displayName: tier.status.contractStatus,
          name: tier.recruitmentSequence,
          type: 'tierstatus',
        });
      });
    }
  }
}
