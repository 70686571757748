import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  matchConsultants,
  matchType,
  matches,
  requestMatchesLevels1,
} from 'src/interface/shared.interface';
import { modalType, searchContext } from 'src/interface/shared.interface';
import { ContextService } from 'src/services/platform/context.service';
import countries from 'src/assets/countries';
@Component({
  selector: 'app-find-match',
  templateUrl: './find-match.component.html',
  styleUrls: ['./find-match.component.scss'],
})
export class FindMatchComponent {
  @Input() selectedRequest: requestMatchesLevels1;
  @Input() manualRequest: matches;
  @Input() matchTypeNumber: number;
  @Input() advancedSearch: searchContext;
  @Input() loading: boolean;
  @Input() isManual: boolean;
  @Input() selectedconsultant: matchConsultants;
  @Output() callbackFunction = new EventEmitter<{
    event: Event;
    type: string;
  }>();
  @Output() cta = new EventEmitter<string>();
  @Input() matchType: matchType[];
  selectedAdvanced = '';
  click: boolean;

  constructor(private contextService: ContextService) {
    this.contextService.reRender.subscribe((val) => {
      if (val === 'close') {
        this.setSelectAdvanced();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedRequest'] || changes['manualRequest']) {
      this.contextService.advancedSearch.qualification = '';
      this.contextService.advancedSearch.qualificationImportance = 'Minimal';
      this.setSelectAdvanced();
    }
    if (changes['matchTypeNumber']) {
      this.setSelectAdvanced();
    }
  }
  setSelectAdvanced() {
    const select = [];

    if (this.matchTypeNumber !== 1) {
      const findCountry = countries.list.find(
        (x) => x.value === this.contextService.advancedSearch.countryCode
      );

      if (findCountry) {
        select.push(findCountry.displayName);
      } else {
        select.push('All countries');
      }
    }
    if (this.contextService.advancedSearch.qualification) {
      select.push(this.contextService.advancedSearch.qualification);
    }
    this.selectedAdvanced = select.join(' + ');
  }

  openModal(value: modalType) {
    this.click = true;
    setTimeout(() => {
      this.click = false;
    }, 100);
    this.contextService.openMatchModal = value;
  }
}
