<div *ngIf="tier" [class]="'recruited ' + getStatus().toLocaleLowerCase()">
  <i-tabler *ngIf="leadingIcon" [name]="leadingIcon"></i-tabler>
  <span class="step">
    <span [class]="{ active: biggerThanStep(0) }"></span>
    <span [class]="{ active: biggerThanStep(1) }"></span>
    <span [class]="{ active: biggerThanStep(2) }"></span>
    <span [class]="{ active: biggerThanStep(3) }"></span>
    <span [class]="{ active: biggerThanStep(4) }"></span>
  </span>
  <p class="tiny bundle-sequence">
    {{ tier.recruitmentSequence }}
    <span *ngIf="tier.recruitedUpdated; else empty"
      >: {{ getDate(tier.recruitedUpdated) }}</span
    >
    <ng-template #empty><span></span> </ng-template>
  </p>
  <div (click)="click()" class="trailingIcon" *ngIf="trailingIcon">
    <i-tabler [name]="trailingIcon"></i-tabler>
  </div>
</div>
