import { Component, Input } from '@angular/core';
import { matchConsultants } from 'src/interface/shared.interface';
import { by, tier } from 'src/interface/shared.interface';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() consultant?: matchConsultants;
  @Input() tier: tier;
  @Input() by: by;
  @Input() tiers: boolean;
  @Input() salesAgent: boolean;
  @Input() class: string;
  isExpandTier: boolean;

  getInput(consultant: matchConsultants) {
    if (this.salesAgent) {
      return consultant.salesAgent;
    }
    return consultant;
  }
}
