import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  matchConsultants,
  matches,
  requestMatchesLevels1,
} from 'src/interface/shared.interface';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-consultant-content',
  templateUrl: './consultant-content.component.html',
  styleUrls: ['./consultant-content.component.scss'],
})
export class consultantContentComponent implements OnInit {
  toggleRequest: boolean;
  selectedRequest?: requestMatchesLevels1 = this.contextService.selectedRequest;
  allconsultants: matchConsultants[] = [];
  rawAllconsultants: matchConsultants[];
  selectedconsultant?: matchConsultants =
    this.contextService.selectedConsultant;
  salesRequests: matches[];
  clearConsultant: boolean =
    this.contextService.openMatchModal === 'addAnotherconsultant';
  @Output() selected = new EventEmitter<matchConsultants>();

  constructor(private contextService: ContextService) {}

  ngOnInit(): void {
    if (this.selectedconsultant) {
      if (this.selectedconsultant.id === '-1') {
        this.clearConsultant = true;
      }
    }
  }
  validateconsultant(selectedconsultant: matchConsultants) {
    return selectedconsultant;
  }
  selectconsultant(consultant: matchConsultants) {
    if (consultant.added) {
      return;
    }
    this.selectedconsultant = consultant;
    this.clearConsultant = false;
    this.selected.emit(consultant);
  }
  cta(value: string) {
    if (value === 'close') {
      this.clearConsultant = true;
      this.allconsultants = [];
      this.selected.emit(undefined);
    }
  }
  compare(a: matchConsultants, b: matchConsultants) {
    if (a.firstName < b.firstName) {
      return -1;
    }
    if (a.firstName > b.firstName) {
      return 1;
    }
    return 0;
  }
  removeSelected() {
    this.selectedconsultant = undefined;
    if (this.rawAllconsultants) {
      this.allconsultants = this.rawAllconsultants;
    }
  }
  searchConsultant(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!this.rawAllconsultants) {
      this.rawAllconsultants = this.allconsultants;
    }
    if (target.value.length === 0) {
      this.allconsultants = [];
    }
    if (target.value.length === 0) {
      this.allconsultants = this.rawAllconsultants;
      return;
    }

    this.allconsultants = this.rawAllconsultants.filter((x) => {
      const findbyFullName = x.firstName
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      const findbyJobTitle = x.lastRole
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      if (!findbyJobTitle) {
        return findbyFullName;
      }
      return findbyJobTitle;
    });
  }
}
