import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() label: string | number;
  @Input() color: string;
  @Input() amount: number;
  @Input() type: string;
  @Input() icon: { type: string; icon: string };
  @Input() trailingIcon: { type: string; icon: string } | undefined;
  @Output() callbackFunction = new EventEmitter();
}
