<div class="wrap-search">
  <app-input
    (inputs)="searchConsultant($event)"
    trailingIcon="chevron-down"
    type="field"
    (focus)="toggleDropdown = true"
  ></app-input>
  <div *ngIf="toggleDropdown" class="consultants surface--2">
    <div class="placeholder" *ngIf="allconsultants.length === 0 && !loading">
      <p class="s1">Start your search.</p>
      <p class="body-large">Please search by name to find consultant.</p>
    </div>
    <div class="loading" *ngIf="loading">
      <app-loader [loading]="loading"></app-loader>
    </div>
    <div *ngIf="!loading">
      <ng-container *ngFor="let consultant of allconsultants">
        <app-level0
          [shortListed]="consultant.added"
          *ngIf="dataValidator(consultant)"
          (click)="select(consultant)"
          [consultant]="consultant"
          [disable]="!consultant.applicableForC2ROrC2C && !addable"
        ></app-level0>
      </ng-container>
    </div>
  </div>
</div>
