import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { gameInstance, requestResult } from 'src/interface/shared.interface';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() title = '';
  @Input() excerpt = '';
  @Input() text = '';
  @Input() cta = '';
  @Input() type = '';
  @Input() toEdit: string[] = [];
  @Input() data: gameInstance = { gameCode: '', scores: [] };
  @Input() requestData: requestResult = { description: '', extrnalLink: '' };
  @Output() close = new EventEmitter();
  reRender = true;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;

  getCharBehav(value: string) {
    if (value === 'speed_accuracy_tradeoff') {
      return 'Speed-accuracy tradeoff';
    }
    if (value === 'problem_solving_style') {
      return 'Problem solving style';
    }
    if (value === 'problem_solving_ability') {
      return 'Problem solving ability';
    }
    if (value === 'collaboration') {
      return 'Collaboration';
    }
    if (value === 'flexibility') {
      return 'Cognitive flexibility';
    }
    return '';
  }
  closeDialog() {
    this.close.emit();
  }
  change() {
    this.reRender = false;
    setTimeout(() => {
      this.reRender = true;
    }, 0);
  }
  href(link: string | unknown) {
    if (typeof link === 'string') {
      window.open(link, '_blank');
    }
  }
}
