<div
  class="bundle-tiers-avatar"
  [class]="{ tiers: tiers, small: class === 'small' }"
>
  <div class="avatar" [class]="class">
    <ng-container *ngIf="consultant">
      <img
        *ngIf="getInput(consultant).imageUrl"
        [src]="getInput(consultant).imageUrl"
      />
      <i-tabler *ngIf="!getInput(consultant).imageUrl" name="user"></i-tabler>
    </ng-container>
    <ng-container *ngIf="by">
      <img *ngIf="by.imageUrl" [src]="by.imageUrl" />
      <p *ngIf="!by.imageUrl">
        {{ by.firstName[0] }}
      </p>
    </ng-container>
  </div>
  <div *ngIf="tiers" class="tier">
    <app-tier
      [id]="consultant?.id ?? '1'"
      [class]="class"
      *ngIf="tier"
      [tier]="tier"
    ></app-tier>
  </div>
</div>
