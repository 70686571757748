export const environment = {
  production: false,
  showDialog: true,
  apiUrl: 'https://api.staging.7N20.io',
  tenant: '7n20staging.onmicrosoft.com',
  b2c: 'https://7n20staging.b2clogin.com/',
  scopes: {
    api: 'genome',
    scope:
      'https://7n20staging.onmicrosoft.com/95d4e373-a0c9-4136-bae1-f3ddfcb4a3f7/App.Access',
  },
  clientId: '4877d5a3-49d6-4757-951b-2a5276f2ae88',
  matomo: 3,
  tracePropagationTargets: ['https://agent.7n20.io/', 'localhost:4200'],
  dsn: 'https://d92b699b70939d53bb64fa53c32a0cae@o4504593336631296.ingest.sentry.io/4506399781879808',
  communityUrl: 'https://chat.7n.community',
};
