<div class="add-notes">
  <div class="crm-content">
    <p>Notes from CRM</p>
    <app-input [disabled]="true" class="crm-content" [value]="note" type="content-field"></app-input>
  </div>
  <div>
    <p>Add sales note</p>
    <app-input [placeholder]="'Add your notes here'" (selectedCallback)="callbackFunction.emit($event)"
      type="content-field"></app-input>
  </div>
</div>
