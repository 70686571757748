<app-notification></app-notification>
<div class="consultant">
  <section class="loading" *ngIf="loading" style="height: 100%">
    <app-loader [loading]="loading"> </app-loader>
  </section>
  <ng-container *ngIf="consultant">
    <section>
      <app-page-title class="title" label="Profile"></app-page-title>
    </section>
    <section>
      <div class="top">
        <div>
          <div class="max-width">
            <app-button
              leadingIcon="chevron-left"
              label="Back to list"
              [class]="['ghost', 'small']"
              (click)="backToList()"
            ></app-button>
            <div (clickOutside)="toggleDropdown($event, '')">
              <!-- <app-cta
                classes="brand--Lightest textLink"
                [icon]="'text-plus'"
                (click)="openModal()"
              >
              </app-cta> -->
              <app-cta
                (click)="toggleDropdown($event, consultant.id)"
                [classes]="
                  (openDropdown ? 'brand--Light' : 'brand--Lightest') +
                  ' textLink'
                "
                [icon]="'dots'"
                classes="brand--Lightest textLink"
              >
              </app-cta>
              <app-simple-dropdown
                *ngIf="openDropdown === consultant.id"
                (click)="toggleDropdown($event, '')"
                [profile3]="consultant"
              ></app-simple-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="consultant-info max-width">
        <div class="bundle-name-image">
          <app-avatar [by]="by" class="xLarge"></app-avatar>
          <app-avatar
            [by]="by"
            class="small"
            [tier]="consultant.tier"
            [tiers]="true"
          ></app-avatar>

          <div class="info">
            <div>
              <div
                *ngIf="consultant.remarks"
                class="remarks warning--Medium"
              ></div>

              <ng-template #consultants>
                <app-badge
                  *ngIf="getRecruitedStatus().label"
                  [icon]="getIcon()"
                  [label]="getRecruitedStatus().label"
                  [color]="getRecruitedStatus().class"
                ></app-badge>
              </ng-template>
            </div>
            <div>
              <div class="badge-name">
                <h1>
                  {{ by.firstName }} {{ by.middleName }} {{ by.lastName }}
                </h1>
                <ng-container
                  *ngIf="
                    consultant.tier.status?.contractStatus === 'Candidate';
                    else consultants
                  "
                >
                  <app-recruited-steps
                    [tier]="consultant.tier"
                  ></app-recruited-steps>
                </ng-container>
              </div>
              <p class="s2">{{ consultant.lastRole }}</p>
            </div>
            <div class="location">
              <i-tabler class="medium" name="map-pin"></i-tabler>
              <p class="body-small">
                {{
                  getCity(consultant.location.city) +
                    getCountryState(consultant.location.stateOrProvince) +
                    getCountry(consultant.location.countryCode)
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="tier">
          <app-tier [tier]="consultant.tier" [expandable]="false"></app-tier>
        </div>
      </div>
    </section>
    <div class="row">
      <app-general-info
        class="biggerThanTablet"
        [consultant]="consultant"
        (downloadUrl)="downloadUrl()"
      ></app-general-info>
      <div class="bundle-section-tab">
        <app-tab-buttons
          classList="body-large main-tabs"
          (callbackFunction)="selectedTap = $event"
          [selected]="selectedTap"
          [tabButtons]="tabButtons"
          [type]="'neutral'"
        ></app-tab-buttons>
        <section>
          <div class="max-width">
            <app-general-info
              class="mobile"
              *ngIf="[0].includes(selectedTap)"
              [consultant]="consultant"
              (downloadUrl)="downloadUrl()"
            ></app-general-info>
            <app-experience
              [consultant]="consultant"
              [selectedTap]="selectedTap"
              *ngIf="[1].includes(selectedTap)"
              (downloadUrl)="downloadUrl()"
              (openExternal)="openExternal()"
            ></app-experience>
            <app-timeline
              [consultantId]="consultant.id"
              *ngIf="[2].includes(selectedTap)"
            ></app-timeline>
            <app-shortlisted
              [shortlistedRequests]="consultant.shortlistedSalesRequests"
              *ngIf="selectedTap === 3"
            ></app-shortlisted>
          </div>
        </section>
      </div>
    </div>
  </ng-container>
</div>
