<div class="messaging-content">
  <app-loader [loading]="loading"></app-loader>
  <ng-container *ngIf="claiming">
    <div class="message-steps">
      <div
        class="label2"
        [class]="{ active: selectedStep === i }"
        *ngFor="let step of steps; let i = index"
      >
        <div>
          <span>{{ i + 1 }}.</span>
          {{ step }}
        </div>
        <div *ngIf="i === 0" class="amount caption">
          {{ claiming.freelancers.length }}
        </div>
      </div>
    </div>
    <div class="content" *ngIf="selectedStep === 0">
      <div class="consultants">
        <div *ngFor="let consultant of claiming.freelancers">
          <div class="profile">
            <div class="image label1">{{ consultant.fullName![0] }}</div>
            <div class="label1 fullName">{{ consultant.fullName }}</div>
          </div>
          <div class="contact">
            <app-input
              [disabled]="true"
              [value]="consultant.email!"
              placeholder="Email"
            ></app-input>
            <app-input
              (inputs)="phoneNumber($event, consultant.freelancerId)"
              inputType="tel"
              [value]="beautifyNumber(consultant.mobilephone)"
              placeholder="Phone number"
            ></app-input>
          </div>
        </div>
      </div>
      <div class="bottom-area">
        <div class="sms-notificaton">
          <app-select-items
            label="Also send an SMS notification"
            (checked)="claiming.notifyThroughSms = !$event"
            [type]="'checkbox'"
            [isChecked]="claiming.notifyThroughSms"
          ></app-select-items>
        </div>
        <app-notification [message]="notification[0]"></app-notification>
      </div>
    </div>
    <div class="content" *ngIf="selectedStep === 1">
      <app-input
        (inputs)="title($event)"
        label="Request title"
        [value]="claiming.title"
      ></app-input>
      <app-input
        (selectedCallback)="description($event)"
        styles="height: 380px"
        label="Request description"
        [value]="clamingDescription"
        type="content-field"
      ></app-input>
    </div>
    <div class="content" *ngIf="selectedStep === 2">
      <div class="bundle-reset-lang">
        <app-dropdown
          trailingIcon="chevron-down"
          [selected]="selected"
          [options]="language"
          (selectedCallback)="selectOption($event)"
          [styles]="'width: fit-content'"
        ></app-dropdown>
        <div (click)="reset()" class="reset label2">Reset message</div>
      </div>
      <app-input
        [changeValue]="changeValue"
        (selectedCallback)="message($event)"
        styles="height: 380px"
        valueType="innerHtml"
        label="Message"
        [value]="messageTemplate"
        type="content-field"
      >
      </app-input>
      <app-notification [message]="notification[1]"></app-notification>
    </div>
  </ng-container>
</div>
