import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { matchConsultants, smartTip } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { MatomoTracker } from 'ngx-matomo';
import { modalType } from 'src/interface/shared.interface';
import { FeatureFlagsService } from 'src/services/feature-flags/feature-flags.service';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-level1',
  templateUrl: './level1.component.html',
  styleUrls: ['./level1.component.scss'],
})
export class Level1Component {
  @Input() consultant: matchConsultants;
  @Input() isSmartTip = true;
  @Input() selected: boolean;
  @Input() isManual: boolean;
  @Input() selectedSearchType: number;
  @Input() selectedCvType: number;
  @Input() iterateId: number | null;
  @Input() selectedNav: string;
  @Input() entityId: any | null;
  @Output() closeLevel = new EventEmitter<boolean>();
  freelanceAddedToRequest: boolean;
  expandLevel2: boolean;
  openDropdown = '';
  smartTip: smartTip;

  constructor(
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker,
    private location: Location,
    private platformService: PlatformService,
    public featureFlags: FeatureFlagsService
  ) {}
  expand() {
    this.expandLevel2 = !this.expandLevel2;
    if (
      this.iterateId &&
      this.featureFlags.isFeatureEnabled('explainAbility')
    ) {
      let iterationQuery = '';
      if (this.iterateId !== 1) {
        iterationQuery = '&iterationId=' + this.iterateId;
      }
      let consultantId = '';
      if (this.selectedSearchType === 2) {
        consultantId = 'otherConsultantId=' + this.consultant.id;
        iterationQuery = '';
      } else {
        consultantId = 'consultantId=' + this.consultant.id;
      }

      this.platformService
        .getSmartTip(this.entityId, consultantId + iterationQuery)
        .then((respond: smartTip) => {
          const entries = Object.entries(respond.keywords);
          const sortedEntries = entries.sort((a: any, b: any) => b[1] - a[1]);
          respond.keywords = sortedEntries;
          this.smartTip = respond;
        });
    }
  }

  getFullName() {
    return (
      this.consultant.firstName +
      ' ' +
      this.consultant.middleName +
      ' ' +
      this.consultant.lastName
    );
  }

  isTruncate(value: string) {
    if (value === 'name') {
      if (!this.consultant.lastRole) {
        return true;
      }
      if (this.consultant.lastRole.length < this.getFullName().length) {
        return true;
      }
    }
    if (value === 'lastRole') {
      if (!this.consultant.lastRole) {
        return false;
      }
      if (this.consultant.lastRole.length > this.getFullName().length) {
        return true;
      }
    }
    return false;
  }
  getCvTypeColor(cvFile: any) {
    if (this.selectedCvType === 0 || cvFile) {
      return 'brand--Light textLink';
    }

    return 'neutral--Grey-300';
  }
  getCvTypeLabel(cvFile: any) {
    if (cvFile) {
      const split = cvFile.filename.split('.');
      if (split[split.length - 1] !== 'pdf') {
        return 'Word CV';
      }
      return 'PDF CV';
    }
    if (this.selectedCvType === 1) {
      return 'No CV';
    }

    return 'My7N CVs';
  }
  getCvTypeIcon(cvFile: any) {
    if (cvFile) {
      return {
        type: 'icon',
        icon: 'file-cv',
      };
    }
    if (this.selectedCvType === 1) {
      return {
        type: 'icon',
        icon: 'info-circle',
      };
    }
    return {
      type: 'icon',
      icon: 'discount-check',
    };
  }
  ngOnInit() {
    this.contextService.reRender.subscribe((val) => {
      if (val === 'longShortList') {
        if (
          this.contextService.selectedConsultant?.id === this.consultant.id &&
          this.contextService.selectedRequest
        ) {
          this.consultant.shortlistedSalesRequests.push({
            id: this.contextService.selectedRequest.id,
          });
        }
      }
    });
    this.checkSortList();
  }
  checkSortList() {
    if (this.consultant.shortlistedSalesRequests) {
      this.freelanceAddedToRequest =
        this.consultant.shortlistedSalesRequests.some(
          (x) => x.id === this.contextService.selectedRequest?.id
        );
    }
  }
  getCity(value: string) {
    if (value) {
      return value + ', ';
    }
    return '';
  }
  getCountryState(value: string | undefined) {
    if (value) {
      return value.toLocaleUpperCase() + ', ';
    }
    return '';
  }
  getCountry(value: string) {
    if (value) {
      return this.helperFunctions.getCountry(value.toLocaleUpperCase());
    }
    return '';
  }

  getDate(value: string) {
    if (!value) {
      return '-';
    }
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }

  toggleSmartTips(event: Event, id: string) {
    event.stopPropagation();
    if (this.contextService.expandSmartTip !== id) {
      this.contextService.expandSmartTip = id;
    } else {
      this.contextService.expandSmartTip = '';
    }
  }
  toggleDropdown(event: Event, id: string) {
    event.stopPropagation();
    this.openDropdown = id;
  }
  openProfile(event: Event, id: string) {
    event.stopPropagation();
    this.location.go(
      '/' + (this.selectedNav ?? 'matching') + '/consultant/' + id
    );
  }
  openMatchModal(event: Event, modal: modalType = 'longlist') {
    event.stopPropagation();
    if (this.selectedNav === 'consultants') {
      this.contextService.selectedRequest = <any>undefined;
    }
    this.contextService.selectedConsultant = { ...this.consultant };
    this.contextService.openMatchModal = modal;
  }
  openExternal(link: string, text: string, event: Event) {
    event.stopPropagation();

    if (link) {
      this.matomoTracker.trackEvent('Matching', 'External Link - ' + text);
      window.open(link, '_blank');
    }
  }
  validateRate(expectedRate: { currency: string; ratePerHour: number }) {
    if (expectedRate.currency && expectedRate.ratePerHour) {
      return expectedRate.ratePerHour + ' ' + expectedRate.currency;
    }
    return '-';
  }
  getStatus() {
    return this.helperFunctions.getStatus(this.consultant.tier);
  }
  getClass(notice: string) {
    if (notice === 'Danger') {
      return 'danger--Lighter';
    }
    if (notice === 'Warning') {
      return 'warning--Lighter';
    }
    if (notice === 'Ready') {
      return 'success--Lighter';
    }
    if (['New', 'Success'].includes(notice)) {
      return 'info--Lighter';
    }
    return '';
  }
  getRecruitedStatus() {
    if (this.consultant.tier.status) {
      const result = {
        label: this.consultant.tier.status.description,
        class: '',
      };
      if (
        ['ApproachingDeadline'].includes(
          this.consultant.tier.status.contractStatus
        )
      ) {
        result.class = 'warning--Lighter warningText--Dark';
        return result;
      }
      if (
        ['FinalCountdown'].includes(this.consultant.tier.status.contractStatus)
      ) {
        result.class = 'urgent--Lighter urgentText--Dark';
        return result;
      }
      if (['OutOfWork'].includes(this.consultant.tier.status.contractStatus)) {
        result.class = 'danger--Lighter dangerText--Dark';
        return result;
      }
      if (['OnContract'].includes(this.consultant.tier.status.contractStatus)) {
        result.class = 'info--Lighter infoText--Dark';
        return result;
      }
      if (
        ['ReadyToWork'].includes(this.consultant.tier.status.contractStatus)
      ) {
        result.class = 'success--Lighter  successText--Dark';
        return result;
      }
      if (['Candidate'].includes(this.consultant.tier.status.contractStatus)) {
        result.class = 'success--Lighter';
        return result;
      }
    }

    return { label: '', class: '' };
  }

  getIcon() {
    let result = { type: 'icon', icon: '' };
    if (this.consultant.tier.status) {
      result = { type: 'icon', icon: 'info-circle' };
      if (
        ['FinalCountdown', 'ApproachingDeadline'].includes(
          this.consultant.tier.status.contractStatus
        )
      ) {
        result.icon = 'alert-triangle';
        return result;
      }
      if (['OnContract'].includes(this.consultant.tier.status.contractStatus)) {
        result.icon = 'loader';
        return result;
      }
      if (
        ['ReadyToWork'].includes(this.consultant.tier.status.contractStatus)
      ) {
        result.icon = 'circle-check';
        return result;
      }
    }

    return result;
  }
}
