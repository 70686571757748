import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConsultantInfo } from 'src/interface/shared.interface';

@Component({
  selector: 'app-selected-notification',
  templateUrl: './selected-notification.component.html',
  styleUrls: ['./selected-notification.component.scss'],
})
export class SelectedNotificationComponent {
  @Input() selected: ConsultantInfo[] = [];
  @Output() clear = new EventEmitter<string>();
  @Output() openMessage = new EventEmitter<string>();
}
