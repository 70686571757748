import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { option } from 'src/interface/shared.interface';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
})
export class AvailabilityComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() selected: {
    option: option;
    range?: { startDate: Date; endDate: Date };
  };
  isRange: string;
  @Output() selectedOption = new EventEmitter<{
    option: option;
    range?: { startDate: Date; endDate: Date };
  }>();
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(private fb: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      if (this.selected.option.id === '-1') {
        this.clear();
      }
      if (this.selected.option.range) {

        this.range = this.fb.group({
          start: this.selected.option.range.startDate,
          end: this.selected.option.range.endDate,
        });
      }
    }
  }

  clear() {
    if (this.selected.option.id !== '-1') {
      this.selectedOption.emit({
        option: { id: '-1', displayName: '', type: '' },
      });
    }
    this.range.reset();
    this.isRange = '';
  }

  dateChange() {
    if (this.range.value.start && this.range.value.end) {
      const availability = {
        option: {
          id: '3available',
          displayName: 'Custom range',
          type: 'availability',
        },
        range: {
          startDate: this.range.value.start,
          endDate: this.range.value.end,
        },
      };
      this.selectedOption.emit(availability);
    }
  }
  selectAvailability(availability: option) {
    this.range.reset();
    this.selectedOption.emit({ option: availability });
  }
}
