<div class="consultant">
  <app-search-consultant
    *ngIf="clearConsultant || !selectedconsultant"
    (selectconsultant)="selectconsultant($event)"
    [selectedRequest]="undefined"
  ></app-search-consultant>
  <div
    *ngIf="selectedconsultant && !clearConsultant"
    class="selectedconsultant"
  >
    <app-level0
      [consultant]="selectedconsultant"
      [ctas]="{ selected: true }"
      (cta)="cta($event)"
    ></app-level0>
  </div>
</div>
