<div class="dropdownSearch" (clickOutside)="toggleDropdown = false">
  <div class="selection">
    <div class="inputButton" (click)="toggleDropdown = !toggleDropdown">
      <div>{{ getSelected(selected) }}</div>
    </div>
    <div *ngIf="trailingIcon && allCountries" class="icon">
      <i-tabler [name]="trailingIcon"></i-tabler>
    </div>
    <div
      *ngIf="!allCountries"
      (click)="selectedCallback.emit({ value: '', displayName: '' })"
      class="icon clickable"
    >
      <i-tabler name="circle-x"></i-tabler>
    </div>
  </div>
  <div *ngIf="toggleDropdown" class="dropdown surface--1">
    <div class="search">
      <input autofocus (input)="search($event)" [placeholder]="placeholder" />
      <i-tabler name="search"></i-tabler>
    </div>
    <div class="dropdownList">
      <div
        (click)="selectOption(option)"
        class="body-small"
        *ngFor="let option of options"
      >
        <div></div>
        <p>{{ option.displayName }}</p>
      </div>
    </div>
  </div>
</div>
