export default {
  list: [
    {
      value: 'en',
      id:2,
      label: 'English',
    },
    {
      value: 'da',
      id:3,
      label: 'Danish',
    },
    {
      value: 'aa',
      id:4,
      label: 'Afar',
    },
    {
      value: 'ab',
      id:5,
      label: 'Abkhazian',
    },
    {
      value: 'ae',
      id:6,
      label: 'Avestan',
    },
    {
      value: 'af',
      id:7,
      label: 'Afrikaans',
    },
    {
      value: 'ak',
      id:8,
      label: 'Akan',
    },
    {
      value: 'am',
      id:9,
      label: 'Amharic',
    },
    {
      value: 'an',
      id:10,
      label: 'Aragonese',
    },
    {
      value: 'ar',
      id:11,
      label: 'Arabic',
    },
    {
      value: 'as',
      id:12,
      label: 'Assamese',
    },
    {
      value: 'av',
      id:13,
      label: 'Avaric',
    },
    {
      value: 'ay',
      id:14,
      label: 'Aymara',
    },
    {
      value: 'az',
      id:15,
      label: 'Azerbaijani',
    },
    {
      value: 'ba',
      id:16,
      label: 'Bashkir',
    },
    {
      value: 'be',
      id:17,
      label: 'Belarusian',
    },
    {
      value: 'bg',
      id:18,
      label: 'Bulgarian',
    },
    {
      value: 'bh',
      id:19,
      label: 'Bihari languages',
    },
    {
      value: 'bi',
      id:20,
      label: 'Bislama',
    },
    {
      value: 'bm',
      id:21,
      label: 'Bambara',
    },
    {
      value: 'bn',
      id:22,
      label: 'Bengali',
    },
    {
      value: 'bo',
      id:23,
      label: 'Tibetan',
    },
    {
      value: 'br',
      id:24,
      label: 'Breton',
    },
    {
      value: 'bs',
      id:25,
      label: 'Bosnian',
    },
    {
      value: 'ca',
      id:26,
      label: 'Catalan; Valencian',
    },
    {
      value: 'ce',
      id:27,
      label: 'Chechen',
    },
    {
      value: 'ch',
      id:28,
      label: 'Chamorro',
    },
    {
      value: 'co',
      id:29,
      label: 'Corsican',
    },
    {
      value: 'cr',
      id:30,
      label: 'Cree',
    },
    {
      value: 'cs',
      id:31,
      label: 'Czech',
    },
    {
      value: 'cu',
      id:32,
      label:
        'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    },
    {
      value: 'cv',
      id:33,
      label: 'Chuvash',
    },
    {
      value: 'cy',
      id:34,
      label: 'Welsh',
    },

    {
      value: 'de',
      id:35,
      label: 'German',
    },
    {
      value: 'dv',
      id:36,
      label: 'Divehi; Dhivehi; Maldivian',
    },
    {
      value: 'dz',
      id:37,
      label: 'Dzongkha',
    },
    {
      value: 'ee',
      id:38,
      label: 'Ewe',
    },
    {
      value: 'el',
      id:39,
      label: 'Greek, Modern',
    },
    {
      value: 'eo',
      id:40,
      label: 'Esperanto',
    },
    {
      value: 'es',
      id:41,
      label: 'Spanish; Castilian',
    },
    {
      value: 'et',
      id:42,
      label: 'Estonian',
    },
    {
      value: 'eu',
      id:43,
      label: 'Basque',
    },
    {
      value: 'fa',
      id:44,
      label: 'Persian',
    },
    {
      value: 'ff',
      id:45,
      label: 'Fulah',
    },
    {
      value: 'fi',
      id:46,
      label: 'Finnish',
    },
    {
      value: 'fj',
      id:47,
      label: 'Fijian',
    },
    {
      value: 'fo',
      id:48,
      label: 'Faroese',
    },
    {
      value: 'fr',
      id:49,
      label: 'French',
    },
    {
      value: 'fy',
      id:50,
      label: 'Western Frisian',
    },
    {
      value: 'ga',
      id:51,
      label: 'Irish',
    },
    {
      value: 'gd',
      id:52,
      label: 'Gaelic; Scomttish Gaelic',
    },
    {
      value: 'gl',
      id:53,
      label: 'Galician',
    },
    {
      value: 'gn',
      id:54,
      label: 'Guarani',
    },
    {
      value: 'gu',
      id:55,
      label: 'Gujarati',
    },
    {
      value: 'gv',
      id:56,
      label: 'Manx',
    },
    {
      value: 'ha',
      id:57,
      label: 'Hausa',
    },
    {
      value: 'he',
      id:58,
      label: 'Hebrew',
    },
    {
      value: 'hi',
      id:59,
      label: 'Hindi',
    },
    {
      value: 'ho',
      id:60,
      label: 'Hiri Motu',
    },
    {
      value: 'hr',
      id:61,
      label: 'Croatian',
    },
    {
      value: 'ht',
      id:62,
      label: 'Haitian; Haitian Creole',
    },
    {
      value: 'hu',
      id:63,
      label: 'Hungarian',
    },
    {
      value: 'hy',
      id:64,
      label: 'Armenian',
    },
    {
      value: 'hz',
      id:65,
      label: 'Herero',
    },
    {
      value: 'ia',
      id:66,
      label: 'Interlingua (International Auxiliary Language Association)',
    },
    {
      value: 'id',
      id:67,
      label: 'Indonesian',
    },
    {
      value: 'ie',
      id:68,
      label: 'Interlingue; Occidental',
    },
    {
      value: 'ig',
      id:69,
      label: 'Igbo',
    },
    {
      value: 'ii',
      id:70,
      label: 'Sichuan Yi; Nuosu',
    },
    {
      value: 'ik',
      id:71,
      label: 'Inupiaq',
    },
    {
      value: 'io',
      id:72,
      label: 'Ido',
    },
    {
      value: 'is',
      id:73,
      label: 'Icelandic',
    },
    {
      value: 'it',
      id:74,
      label: 'Italian',
    },
    {
      value: 'iu',
      id:75,
      label: 'Inuktitut',
    },
    {
      value: 'ja',
      id:76,
      label: 'Japanese',
    },
    {
      value: 'jv',
      id:77,
      label: 'Javanese',
    },
    {
      value: 'ka',
      id:78,
      label: 'Georgian',
    },
    {
      value: 'kg',
      id:79,
      label: 'Kongo',
    },
    {
      value: 'ki',
      id:80,
      label: 'Kikuyu; Gikuyu',
    },
    {
      value: 'kj',
      id:81,
      label: 'Kuanyama; Kwanyama',
    },
    {
      value: 'kk',
      id:82,
      label: 'Kazakh',
    },
    {
      value: 'kl',
      id:83,
      label: 'Kalaallisut; Greenlandic',
    },
    {
      value: 'km',
      id:84,
      label: 'Central Khmer',
    },
    {
      value: 'kn',
      id:85,
      label: 'Kannada',
    },
    {
      value: 'ko',
      id:86,
      label: 'Korean',
    },
    {
      value: 'kr',
      id:87,
      label: 'Kanuri',
    },
    {
      value: 'ks',
      id:88,
      label: 'Kashmiri',
    },
    {
      value: 'ku',
      id:89,
      label: 'Kurdish',
    },
    {
      value: 'kv',
      id:90,
      label: 'Komi',
    },
    {
      value: 'kw',
      id:91,
      label: 'Cornish',
    },
    {
      value: 'ky',
      id:92,
      label: 'Kirghiz; Kyrgyz',
    },
    {
      value: 'la',
      id:93,
      label: 'Latin',
    },
    {
      value: 'lb',
      id:94,
      label: 'Luxembourgish; Letzeburgesch',
    },
    {
      value: 'lg',
      id:95,
      label: 'Ganda',
    },
    {
      value: 'li',
      id:96,
      label: 'Limburgan; Limburger; Limburgish',
    },
    {
      value: 'ln',
      id:97,
      label: 'Lingala',
    },
    {
      value: 'lo',
      id:98,
      label: 'Lao',
    },
    {
      value: 'lt',
      id:99,
      label: 'Lithuanian',
    },
    {
      value: 'lu',
      id:100,
      label: 'Luba-Katanga',
    },
    {
      value: 'lv',
      id:101,
      label: 'Latvian',
    },
    {
      value: 'mg',
      id:102,
      label: 'Malagasy',
    },
    {
      value: 'mh',
      id:103,
      label: 'Marshallese',
    },
    {
      value: 'mi',
      id:104,
      label: 'Maori',
    },
    {
      value: 'mk',
      id:105,
      label: 'Macedonian',
    },
    {
      value: 'ml',
      id:106,
      label: 'Malayalam',
    },
    {
      value: 'mn',
      id:107,
      label: 'Mongolian',
    },
    {
      value: 'mr',
      id:108,
      label: 'Marathi',
    },
    {
      value: 'ms',
      id:109,
      label: 'Malay',
    },
    {
      value: 'mt',
      id:110,
      label: 'Maltese',
    },
    {
      value: 'my',
      id:111,
      label: 'Burmese',
    },
    {
      value: 'na',
      id:112,
      label: 'Nauru',
    },
    {
      value: 'nb',
      id:113,
      label: 'Bokmål, Norwegian; Norwegian Bokmål',
    },
    {
      value: 'nd',
      id:114,
      label: 'Ndebele, North; North Ndebele',
    },
    {
      value: 'ne',
      id:115,
      label: 'Nepali',
    },
    {
      value: 'ng',
      id:116,
      label: 'Ndonga',
    },
    {
      value: 'nl',
      id:117,
      label: 'Dutch; Flemish',
    },
    {
      value: 'nn',
      id:118,
      label: 'Norwegian Nynorsk; Nynorsk, Norwegian',
    },
    {
      value: 'no',
      id:119,
      label: 'Norwegian',
    },
    {
      value: 'nr',
      id:120,
      label: 'Ndebele, South; South Ndebele',
    },
    {
      value: 'nv',
      id:121,
      label: 'Navajo; Navaho',
    },
    {
      value: 'ny',
      id:122,
      label: 'Chichewa; Chewa; Nyanja',
    },
    {
      value: 'oc',
      id:123,
      label: 'Occitan',
    },
    {
      value: 'oj',
      id:124,
      label: 'Ojibwa',
    },
    {
      value: 'om',
      id:125,
      label: 'Oromo',
    },
    {
      value: 'or',
      id:126,
      label: 'Oriya',
    },
    {
      value: 'os',
      id:127,
      label: 'Ossetian; Ossetic',
    },
    {
      value: 'pa',
      id:128,
      label: 'Panjabi; Punjabi',
    },
    {
      value: 'pi',
      id:129,
      label: 'Pali',
    },
    {
      value: 'pl',
      id:130,
      label: 'Polish',
    },
    {
      value: 'ps',
      id:131,
      label: 'Pushto; Pashto',
    },
    {
      value: 'pt',
      id:132,
      label: 'Portuguese',
    },
    {
      value: 'qu',
      id:133,
      label: 'Quechua',
    },
    {
      value: 'rm',
      id:134,
      label: 'Romansh',
    },
    {
      value: 'rn',
      id:135,
      label: 'Rundi',
    },
    {
      value: 'ro',
      id:136,
      label: 'Romanian; Moldavian; Moldovan',
    },
    {
      value: 'ru',
      id:137,
      label: 'Russian',
    },
    {
      value: 'rw',
      id:138,
      label: 'Kinyarwanda',
    },
    {
      value: 'sa',
      id:139,
      label: 'Sanskrit',
    },
    {
      value: 'sc',
      id:140,
      label: 'Sardinian',
    },
    {
      value: 'sd',
      id:141,
      label: 'Sindhi',
    },
    {
      value: 'se',
      id:142,
      label: 'Northern Sami',
    },
    {
      value: 'sg',
      id:143,
      label: 'Sango',
    },
    {
      value: 'si',
      id:144,
      label: 'Sinhala; Sinhalese',
    },
    {
      value: 'sk',
      id:145,
      label: 'Slovak',
    },
    {
      value: 'sl',
      id:146,
      label: 'Slovenian',
    },
    {
      value: 'sm',
      id:147,
      label: 'Samoan',
    },
    {
      value: 'sn',
      id:148,
      label: 'Shona',
    },
    {
      value: 'so',
      id:149,
      label: 'Somali',
    },
    {
      value: 'sq',
      id:150,
      label: 'Albanian',
    },
    {
      value: 'sr',
      id:151,
      label: 'Serbian',
    },
    {
      value: 'ss',
      id:152,
      label: 'Swati',
    },
    {
      value: 'st',
      id:153,
      label: 'Sotho, Southern',
    },
    {
      value: 'su',
      id:154,
      label: 'Sundanese',
    },
    {
      value: 'sv',
      id:155,
      label: 'Swedish',
    },
    {
      value: 'sw',
      id:156,
      label: 'Swahili',
    },
    {
      value: 'ta',
      id:157,
      label: 'Tamil',
    },
    {
      value: 'te',
      id:158,
      label: 'Telugu',
    },
    {
      value: 'tg',
      id:159,
      label: 'Tajik',
    },
    {
      value: 'th',
      id:160,
      label: 'Thai',
    },
    {
      value: 'ti',
      id:161,
      label: 'Tigrinya',
    },
    {
      value: 'tk',
      id:162,
      label: 'Turkmen',
    },
    {
      value: 'tl',
      id:163,
      label: 'Tagalog',
    },
    {
      value: 'tn',
      id:164,
      label: 'Tswana',
    },
    {
      value: 'to',
      id:165,
      label: 'Tonga (Tonga Islands)',
    },
    {
      value: 'tr',
      id:166,
      label: 'Turkish',
    },
    {
      value: 'ts',
      id:167,
      label: 'Tsonga',
    },
    {
      value: 'tt',
      id:168,
      label: 'Tatar',
    },
    {
      value: 'tw',
      id:169,
      label: 'Twi',
    },
    {
      value: 'ty',
      id:170,
      label: 'Tahitian',
    },
    {
      value: 'ug',
      id:171,
      label: 'Uighur; Uyghur',
    },
    {
      value: 'uk',
      id:172,
      label: 'Ukrainian',
    },
    {
      value: 'ur',
      id:173,
      label: 'Urdu',
    },
    {
      value: 'uz',
      id:174,
      label: 'Uzbek',
    },
    {
      value: 've',
      id:175,
      label: 'Venda',
    },
    {
      value: 'vi',
      id:176,
      label: 'Vietlabelse',
    },
    {
      value: 'vo',
      id:177,
      label: 'Volapük',
    },
    {
      value: 'wa',
      id:178,
      label: 'Walloon',
    },
    {
      value: 'wo',
      id:179,
      label: 'Wolof',
    },
    {
      value: 'xh',
      id:180,
      label: 'Xhosa',
    },
    {
      value: 'yi',
      id:181,
      label: 'Yiddish',
    },
    {
      value: 'yo',
      id:182,
      label: 'Yoruba',
    },
    {
      value: 'za',
      id:183,
      label: 'Zhuang; Chuang',
    },
    {
      value: 'zh',
      id:184,
      label: 'Chinese',
    },
    {
      value: 'zu',
      id:1,
      label: 'Zulu',
    },
  ],
}
