<div class="longlist-content">
  <div class="selectedFreelance">
    <app-level0 [consultant]="selectedConsultant"></app-level0>
    <!-- <app-list-card [longList]="true" [potentialResults]="[selectedConsultant]"></app-list-card> -->
  </div>

  <div class="selectRequest">
    <p class="body-large bold">Choose request</p>
    <div>
      <app-search-request
        [type]="'longlist'"
        [selectedSearchType]="0"
        [selectedRequest]="selectedRequest"
        (selectrequest)="selectRequest($event)"
      ></app-search-request>
      <!-- <div (clickOutside)="toggleDropdown = false" class="wrap-search">
        <app-input
          (input)="search($event)"
          trailingIcon="chevron-down"
          type="field"
          (focus)="toggleDropdown = true; getSearchRequests()"
        ></app-input>
        <div *ngIf="toggleDropdown" class="requests surface--2">
          <div class="placeholder" *ngIf="!salesRequests && !loading">
            <p class="s1">Start your search.</p>
            <p class="body-large">Please search by name to find consultant.</p>
          </div>
          <div class="loading" *ngIf="loading">
            <app-loader [loading]="loading"></app-loader>
          </div>
          <div *ngIf="!loading && salesRequests">
            <ng-container *ngFor="let request of salesRequests | slice : 0 : 20">
              <app-request-level0
                (click)="select(request)"
                [request]="request"
              ></app-request-level0>
            </ng-container>
          </div>
        </div>
      </div> -->

      <!-- <app-button
        *ngIf="!toggleRequest && !selectedRequest"
        (click)="toggleRequest = true; getSearchRequests()"
        label="Add request"
        leadingIcon="plus"
      ></app-button>
      <div class="wrap-search" *ngIf="toggleRequest && !selectedRequest">
        <app-input
          (input)="search($event)"
          inputType="search"
          leadingIcon="search"
          type="field"
        ></app-input>
        <div class="matches">
          <div
            [class]="{ disabled: match.alreadyLonglist }"
            (click)="selectRequest(match)"
            *ngFor="let match of salesRequests"
            [attr.name]="match.title"
          >
            <div class="match">
              <i-tabler name="file-text"></i-tabler>
              <div>
                <p class="label2">{{ match.title }}</p>
                <p class="body2">{{ match.customerName }}</p>
              </div>
            </div>
            <p *ngIf="match.alreadyLonglist" class="alreadyLonglist caption">
              Already longlisted
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
