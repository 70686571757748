<div class="statuses">
  <app-dropdown-v2
    trailingIcon="chevron-down"
    [selected]="selected"
    [options]="options"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [type]="'statuses'"
    [placeholder]="'Choose 7N status'"
    [disabled]="disabled"
    [searchable]="false"
  ></app-dropdown-v2>
</div>
