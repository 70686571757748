import { Component, Input } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import { matchConsultants } from 'src/interface/shared.interface';
import { modalType, profile3 } from 'src/interface/shared.interface';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
})
export class SimpleDropdownComponent {
  @Input() consultant: matchConsultants;
  @Input() profile3: profile3;
  @Input() isManual: boolean;
  @Input() selectedSearchType: number;
  consultantId = '';
  constructor(
    private contextService: ContextService,
    private matomoTracker: MatomoTracker
  ) {}
  openMatchModal(event: Event, modal: modalType = 'longlist') {
    event.stopPropagation();

    this.contextService.selectedConsultant = this.consultant ?? this.profile3;
    this.contextService.openMatchModal = modal;
  }
  openExternal(link: string, text: string, event: Event) {
    event.stopPropagation();

    if (link) {
      this.matomoTracker.trackEvent('Matching', 'External Link - ' + text);
      window.open(link, '_blank');
    }
  }
}
