import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bars',
  templateUrl: './progress-bars.component.html',
  styleUrls: ['./progress-bars.component.scss'],
})
export class ProgressBarsComponent {
  @Input() steps: { label: string; state: boolean }[] = [];
  getCurrentStep() {
    let currentStep = 0;
    this.steps.forEach((value) => {
      if (value.state) {
        currentStep++;
      }
    });
    return currentStep;
  }
}
