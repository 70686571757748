import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option } from 'src/interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnChanges {
  @Input() location: { displayName: string; subNames: string[] }[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();
  options: option[] = [];
  @Input() disabled: boolean;

  selectOption(option: option) {
    const findex = this.selected.findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected.splice(findex, 1);
    } else {
      this.selected.push(option);
    }
    this.selectedOption.emit(this.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.location && changes['selected']) {
      this.options = [];

      this.location.forEach((location: any) => {
        const subNames: option[] = [];
        location.subNames.forEach((subName: string) => {
          const findex = this.selected.findIndex(
            (x) => x.displayName === subName
          );
          subNames.push({
            id: findex !== -1 ? this.selected[findex].id : uuidv4(),
            displayName: subName,
            type: 'office',
          });
        });
        const findex = this.selected.findIndex(
          (x) => x.displayName === location.displayName
        );
        this.options.push({
          id: findex !== -1 ? this.selected[findex].id : uuidv4(),
          displayName: location.displayName,
          type: 'country',
          subNames,
        });
      });
    }
  }
}
