<div class="options">
  <div *ngFor="let l of data">
    <div [class]="{'selected': selected.includes(l.id), 'subValue': l.subValue}" #option (click)="click(l.id);" class="option">
      <div *ngIf="multiselect">
        <input #checkboxInput [checked]="selected.includes(l.id)" type="checkbox" />
        <span class="checkmark"></span>
      </div>
      <div>
        <p *ngFor="let label of l.label; let i = index" [class]="{'subtitle': i%2 }">{{label}}</p>
      </div>
    </div>
  </div>

  <div *ngFor="let l of proData">
    <div [class]="{'selected': selected.includes(l.id)}" #option (click)="click(l.id);" class="option">
      <div *ngIf="multiselect">
        <input #checkboxInput [checked]="selected.includes(l.id)" type="checkbox" />
        <span class="checkmark"></span>
      </div>
      <p [class]="{'cursive': l.empty}">{{l.displayName}} <span *ngIf="l.year">- {{l.year}}</span></p>
    </div>
  </div>
</div>
