import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-notes-content',
  templateUrl: './add-notes-content.component.html',
  styleUrls: ['./add-notes-content.component.scss'],
})
export class AddNotesContentComponent {
  @Input() note = '';
  @Output() callbackFunction = new EventEmitter<any>();
}
