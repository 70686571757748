<div class="job-experiences">
  <ng-container *ngFor="let job of experience.experiences">
    <div *ngIf="filter(job)" class="card">
      <div class="line-dot">
        <span class="li"></span>
        <span class="line"></span>
      </div>
      <div class="info Surface-1" [class]="job.experienceType">
        <div class="date-status">
          <div class="body-small textSecondary">
            {{ getDate(job.startDate) }}
            -
            {{ getDate(job.endDate, true) }}
          </div>
          <app-badge
            [label]="getLabel('label', job.experienceType)"
            [icon]="getIcon('icon', job.experienceType)"
            [color]="getColor('color', job.experienceType)"
          ></app-badge>
        </div>
        <h3>
          {{ job.customerName }}
        </h3>
        <p class="s3">
          {{ job.contractName }}
        </p>
        <p class="s3" *ngIf="job.jobTitle">{{ job.jobTitle }}</p>
        <p
          *ngIf="job.description"
          class="body-small"
          [innerText]="job.description"
        ></p>
        <div *ngIf="job.keySkills" class="keyRoles">
          <div>
            <p class="body-large">Key Skills</p>
            <app-badge
              [color]="'amount neutral--Grey-300 textSecondary'"
              [label]="job.keySkills.length"
            ></app-badge>
          </div>
          <div>
            <app-badge
              *ngFor="let skills of job.keySkills"
              [label]="skills"
              [color]="'neutral--Grey-300'"
            ></app-badge>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
