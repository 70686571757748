<div class="statuses" *ngIf="tiers">
  <app-dropdown-v2
    trailingIcon="chevron-down"
    [selected]="selected"
    [tiers]="tiers"
    [options]="options"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [type]="'tiers'"
    [placeholder]="'Choose consultant status'"
    [disabled]="disabled"
    [searchable]="false"
  ></app-dropdown-v2>
</div>
