import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import {
  requestMatches,
} from 'src/interface/shared.interface';
import { freeStatus } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnChanges {
  salesRequests: requestMatches[];
  selectedRequest: requestMatches;
  viewLess = true;
  selectedList = 'longlist';

  loadLength = 10;
  consultantstatus: freeStatus[];
  @Input() rerender: boolean;
  @Input() requestId: string;
  @Input() loading = true;
  @Output() callbackFunction = new EventEmitter<boolean>();
  constructor(
    private platformService: PlatformService,
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rerender']) {
      this.getRequest();
    }
  }
  ngOnInit(): void {
    this.getRequest();
  }
  loadMore(event: number) {
    this.loadLength += event;
  }
  getRequest() {

    if (this.requestId) {
      this.platformService.requestId(this.requestId, 'v2').then((response) => {
        this.selectedRequest = response;
        if (!this.selectedRequest.summary) {
          this.selectedRequest.summary = '';
        }
        if (!this.selectedRequest.description) {
          this.selectedRequest.description = '';
        }
        this.platformService
          .getRequests(this.selectedRequest.id)
          .then((response) => {
            this.contextService.selectedRequest = response.data[0];
            // this endpoint is not returning the external link, but we have it in the selectedRequest
            if(this.contextService.selectedRequest && this.selectedRequest.externalLink) {
              this.contextService.selectedRequest.externalLink = this.selectedRequest.externalLink;
            }
          });
        this.callbackFunction.emit(false);

        this.contextService.openMatchModal = 'close';
        this.platformService.getConsultantStatus().then((response) => {
          this.consultantstatus = response;
          this.consultantstatus.map((x, i) => {
            x.trailingInfo = 'LONGLIST';
            if (i > 1) {
              x.trailingInfo = 'SHORTLIST';
            }
            if (i > 2) {
              x.trailingInfo = 'CRM';
            }
            if (x.name === 'CVSent') {
              x.displayName = 'CV Sent';
              return x;
            }
            if (i > 2) {
              return (x.displayName =
                x.name.split(/(?=[A-Z])/).join(' ') + ' (Only be set in CRM)');
            }
            return (x.displayName = x.name.split(/(?=[A-Z])/).join(' '));
          });
        });
      });
    }
  }

  addconsultant() {
    this.matomoTracker.trackEvent('Request', 'Open modal - Add consultant');
    this.contextService.selectedConsultant = <any>undefined;
    this.contextService.openMatchModal = 'addconsultant';
  }
  searchInMathing() {
    this.matomoTracker.trackEvent('Request', 'Clicked - Open in matching');
    this.helperFunctions.route(['matching', 'rtc', this.selectedRequest.id]);
  }
  view() {
    this.viewLess = !this.viewLess;
  }

  getDate(date?: number) {
    if (date) {
      return this.helperFunctions.getDate(date);
    }
    return '-';
  }
  ifEmptyReturnDash(value: string) {
    return value ?? '-';
  }
  selectList(list: string) {
    this.selectedList = list;
  }
  potentialResults() {
    if (this.selectedList === 'longlist' && this.selectedRequest.longlist) {
      return this.selectedRequest.longlist;
    }
    if (this.selectedList === 'shortlist') {
      return this.selectedRequest.shortlist!;
    }
    return [];
  }
}
