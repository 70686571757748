<div (clickOutside)="dropdown = false" class="dropdown-v2">
  <div
    (click)="dropdown = true"
    class="search-input"
    [class]="{ disabled: disabled }"
  >
    <div
      class="input body-small"
      [attr.placeholder]="placeholder"
      [class]="{ placeholder: selected.length > 0, singleSelect: !multiselect }"
    >
      <ng-container *ngFor="let select of selected">
        <app-badge
          (callbackFunction)="selectFunc(select)"
          [label]="select.displayName"
          color="neutral--Grey-300"
          [trailingIcon]="multiselect ? { type: 'icon', icon: 'X' } : undefined"
        ></app-badge>
      </ng-container>

      <div
        class="search"
        [class]="{ notSearchable: !searchable }"
        #searchInput
        (click)="dropdown = true"
        [contentEditable]="!disabled && searchable"
        (input)="search($event)"
      ></div>
    </div>
    <div (click)="dropdown = !dropdown" class="icons textSecondary">
      <i-tabler *ngIf="searchable" class="xs" [name]="'search'"></i-tabler>
      <i-tabler
        *ngIf="!searchable"
        class="xs"
        [name]="'chevron-' + (dropdown ? 'up' : 'down')"
      ></i-tabler>
    </div>
  </div>

  <div class="dropdown Surface-1" *ngIf="dropdown">
    <div class="option" *ngFor="let option of options; let i = index">
      <div (click)="selectFunc(option, true)" class="selection">
        <div *ngIf="multiselect">
          <input #checkboxInput [checked]="isSlected(option)" type="checkbox" />
          <span class="checkmark"></span>
        </div>
        <p class="body-small">
          {{ option.displayName }}
        </p>
      </div>
      <div class="option sub" *ngFor="let value of option.subNames">
        <div (click)="selectFunc(value, false, option)" class="selection">
          <div *ngIf="multiselect">
            <input
              #checkboxInput
              [checked]="isSlected(value) || isSlected(option)"
              type="checkbox"
            />
            <span class="checkmark"></span>
          </div>
          <p class="body-small">
            {{ value.displayName }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
