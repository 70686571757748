<div class="team-filter" [class]="{disabled}">
  <app-team
    [disabled]="disabled"
    [selected]="selectedTeam"
    (selectedOption)="selectedOption($event, 'team')"
    [team]="filterData ? filterData.team : []"
  ></app-team>

  <app-location
    [disabled]="disabled"
    [selected]="selectedLocation"
    (selectedOption)="selectedOption($event, 'location')"
    [location]="filterData ? filterData.location : []"
  >
  </app-location>
</div>
