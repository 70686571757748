<div class="selected-notification">
  <div class="container">
    <div>

      <div (click)="clear.emit('close')" class="close">
        <i-tabler name="X"></i-tabler>
      </div>

      <div class="amount"><span class="caption">{{selected.length}}</span>
        <p class="label1">Selected consultants</p>
      </div>

      <ng-container *ngFor="let freelance of selected | slice:0:5; let i = index">
        <div [style]="'z-index:'+(5-i)" class="profile">
          <div class="image label1">{{freelance.fullName[0]}}</div>
        </div>
        <div *ngIf="i === 4" class="profile">
          <div class="image label1">...</div>
        </div>
      </ng-container>

    </div>
    <app-button (click)="openMessage.emit('openMessage')" trailingIcon="send" label="Send message"
      [class]="['inverted-white']"></app-button>
  </div>
</div>
