<div class="month-year-select">

  <div [class]="{'clickOutside': true}" [myDirective]="selectedPicker.index === index && selectedPicker.type === type"
    (clickOutside)="closePicker();">
    <app-input [disabled]="disabled" [trailingIcon]="trailingIcon" [label]="label" (click)="openPicker()"
      [placeholder]="placeholder" [class]="'button'" />
    <div *ngIf="selectedPicker.index === index && selectedPicker.type === type" class="dropDown" [style]="styles">
      <div class="left">
        <app-buttons [selected]="range.id === selectedMonthRange" [styles]="'background-color:unset'" [single]="true"
          *ngFor="let range of monthRange" [data]="range" (callbackFunction)="setMonthRange($event)"
          type="monthRange"></app-buttons>
      </div>
      <div *ngIf="openCustomMonth" class="right">
        <div class="year">
          <div (click)="changeYear(-1)"><i-tabler [name]="'chevron-left'"></i-tabler></div>
          <p class="label2">{{year}}</p>
          <div (click)="changeYear(1)"><i-tabler [name]="'chevron-right'"></i-tabler></div>
        </div>
        <div class="months">
          <div [class]="{'active': selectedDate.getMonth() === i && value}" (click)="selectMonth(i)" class="month"
            *ngFor="let month of months; let i = index">
            <p class="label2">{{month}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
