<div class="smart-tip body-small highlight--Lighter textHighlightDark">
  <div class="head">
    <div>
      <i-tabler name="sparkles"></i-tabler>
      <p class="bold">Why could this be a good match?</p>
    </div>
    <div class="explainerInfo" (click)="openExplainerInfo()">
      <i-tabler name="info-circle"></i-tabler>
    </div>
  </div>
  <div class="explainer">
    <ng-container *ngIf="smartTip">
      <p [innerText]="smartTip.explanation"></p>
    </ng-container>
    <app-loader
      *ngIf="!smartTip"
      [loading]="!smartTip"
      [class]="['highlight', 'small']"
    ></app-loader>
  </div>
  <div>
    <p class="bold">Matching keywords</p>
    <app-badge
      [color]="'amount highlight--Light'"
      [label]="smartTip ? getKeywordsLength() : 0"
    ></app-badge>
  </div>
  <div class="keywords">
    <div #flexContainer [class]="{ allKeywords: allKeywords }">
      <ng-container *ngIf="smartTip">
        <app-badge
          *ngFor="let keyword of smartTip.keywords | slice : 0 : 20"
          [color]="'highlight--Light'"
          [label]="keyword[0]"
          [amount]="keyword[1]"
        ></app-badge>
      </ng-container>
    </div>
    <app-button
      *ngIf="showReadMoreBtn"
      [style]="'min-width: unset'"
      [class]="['xsmall']"
      [label]="allKeywords ? 'Less +' : 'More +'"
      (click)="allKeywords = !allKeywords"
    ></app-button>
  </div>
</div>
