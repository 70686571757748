import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  by,
  profile3,
  routeParams,
  tabButtons,
} from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { PlatformService } from 'src/services/platform/platform-service.service';
import { Location } from '@angular/common';
import { ContextService } from 'src/services/platform/context.service';
import * as saveAs from 'file-saver';
@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss'],
})
export class ConsultantComponent implements OnChanges {
  @Input() id: string;
  selectedTap = 1;

  consultant: profile3;
  tabButtons: tabButtons[] = [
    {
      displayName: 'General Information',
      icon: 'info-circle',
      mobile: true,
    },
    { displayName: 'Experience', icon: 'user' },
    { displayName: 'Timeline', icon: 'edit' },
    {
      displayName: 'Shortlisted',
      icon: 'checklist',
      mobile: false,
    },
  ];
  by: by = {
    firstName: '',
    middleName: '',
    lastName: '',
    imageUrl: '',
  };
  openDropdown: string;
  loading: boolean;
  constructor(
    private helperFunctions: HelperFunctionsService,
    private platformService: PlatformService,
    private contextService: ContextService,
    private location: Location
  ) {}
  // Lifecycle hook to detect changes in the component's input properties
  ngOnChanges(changes: SimpleChanges): void {
    // If there is a change in the 'id' property, fetch the consultant details
    if (changes['id']) {
      this.getconsultant(this.id);
    }
  }

  // Opens an external link in a new browser tab
  openExternal(link?: string) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  // Downloads the consultant's CV file
  downloadUrl() {
    if (!this.consultant.cvFile) {
      return;
    }
    // Fetch the download URL for the consultant's CV file
    this.platformService
      .getDownloadUrl(this.consultant.cvFile.annotationId)
      .then((response) => {
        if (response) {
          saveAs(response.data, this.consultant.cvFile.filename);
        } else {
          // Show an error notification if unable to download the file
          this.contextService.notification.next({
            description: 'Oops, unable downloading file',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
      });
  }

  // Fetches consultant details based on the provided ID
  getconsultant(id: string) {
    this.loading = true;
    this.platformService.profile(id).then((response) => {
      this.loading = false;
      if (response) {
        this.consultant = response;
        this.tabButtons[1].amount = this.consultant.experience.experienceCount;
        this.tabButtons[3].amount =
          this.consultant.shortlistedSalesRequests.length;
        this.by = {
          firstName: this.consultant.firstName,
          middleName: this.consultant.middleName,
          lastName: this.consultant.lastName,
          imageUrl: this.consultant.imageUrl,
        };
      } else {
        // Navigate back and show an error notification if unable to fetch consultant details
        this.location.back();
        this.contextService.notification.next({
          description:
            'Oops, unable getting CV for consultant with id ' + this.id,
          type: 'error',
          show: true,
          icon: 'info-circle-filled',
        });
      }
    });
  }

  openModal() {
    this.consultant.id = this.id;
    this.contextService.selectedConsultant = this.consultant;
    this.contextService.openMatchModal = 'longlist';
  }
  // Toggles the dropdown menu for the specified element
  toggleDropdown(event: Event, id: string) {
    event.stopPropagation();
    this.openDropdown = id;
  }

  // Navigates back to the previous page
  backToList() {
    this.location.back();
  }

  // Returns the city name followed by a comma, or an empty string if no city is provided
  getCity(value: string) {
    if (value) {
      return value + ', ';
    }
    return '';
  }

  getCountryState(value: string | undefined) {
    if (value) {
      return value + ', ';
    }
    return '';
  }

  // Returns the formatted country name based on the provided value
  getCountry(value: string) {
    if (value) {
      return this.helperFunctions.getCountry(value.toLocaleUpperCase());
    }
    return '';
  }

  // Determines the recruitment status and returns an object with label and CSS class
  getRecruitedStatus() {
    if (this.consultant.tier.status) {
      const result = {
        label: this.consultant.tier.status.description,
        class: '',
      };
      if (
        ['ApproachingDeadline'].includes(
          this.consultant.tier.status.contractStatus
        )
      ) {
        result.class = 'warning--Lighter warningText--Dark';
        return result;
      }
      if (
        ['FinalCountdown'].includes(this.consultant.tier.status.contractStatus)
      ) {
        result.class = 'urgent--Lighter urgentText--Dark';
        return result;
      }
      if (['OutOfWork'].includes(this.consultant.tier.status.contractStatus)) {
        result.class = 'danger--Lighter dangerText--Dark';
        return result;
      }
      if (['OnContract'].includes(this.consultant.tier.status.contractStatus)) {
        result.class = 'info--Lighter infoText--Dark';
        return result;
      }
      if (
        ['ReadyToWork'].includes(this.consultant.tier.status.contractStatus)
      ) {
        result.class = 'success--Lighter  successText--Dark';
        return result;
      }
      if (['Candidate'].includes(this.consultant.tier.status.contractStatus)) {
        result.class = 'success--Lighter';
        return result;
      }
    }

    return { label: '', class: '' };
  }

  // Determines the icon to display based on the consultant's contract status
  getIcon() {
    let result = { type: 'icon', icon: '' };
    if (this.consultant.tier.status) {
      result = { type: 'icon', icon: 'info-circle' };
      if (
        ['FinalCountdown'].includes(this.consultant.tier.status.contractStatus)
      ) {
        result.icon = 'alert-triangle';
        return result;
      }
      if (['OnContract'].includes(this.consultant.tier.status.contractStatus)) {
        result.icon = 'loader';
        return result;
      }
      if (
        ['ReadyToWork'].includes(this.consultant.tier.status.contractStatus)
      ) {
        result.icon = 'circle-check';
        return result;
      }
    }

    return result;
  }
}
