<div *ngIf="timeline" class="timeline-card">
  <div class="line-dot">
    <div class="icon brand--Light">
      <i-tabler
        *ngIf="timeline.type"
        [name]="getIcon(timeline.type)"
      ></i-tabler>
    </div>
    <span class="line"></span>
  </div>
  <div
    (click)="expand = !expand"
    class="card surface1"
    [class]="{ cursor: expandable }"
  >
    <div class="top textSecondary">
      <p class="tiny">{{ getDate(timeline.createdOn) }}</p>
      <div *ngIf="checkTypeAgent()" class="createdBy tiny">
        <p class="agent-type">{{ checkTypeAgent() }}</p>
        <app-avatar
          [salesAgent]="true"
          [by]="getAgent()"
          class="xs"
        ></app-avatar>
        <p>{{ getFullname() }}</p>
        <li *ngIf="timeline.businessUnit"></li>
        <p class="business-unit">{{ timeline.businessUnit }}</p>
      </div>
    </div>
    <div
      #info
      class="info"
      [class]="{ expand: expand, collapse: expandable }"
    >
      <div
        *ngIf="
          timeline.type === 'TimelineNotes' || timeline.type === 'NotesDocument'
        "
      >
        <p *ngIf="timeline.subject" class="body-small bold">
          {{ timeline.subject }}
        </p>
        <p class="body-small content">
          {{ timeline.content }}
        </p>
        <app-button
          *ngIf="timeline.filename"
          [label]="timeline.filename"
          trailingIcon="download"
          [class]="['xsmall', 'brand--Lightest', 'link']"
          (click)="downloadUrl($event, timeline.annotationId)"
        ></app-button>
      </div>
      <div *ngIf="timeline.type === 'TimelineEmail'">
        <p *ngIf="timeline.subject" class="body-small bold">
          Subject: {{ timeline.subject }}
        </p>
        <p class="tiny textSecondary">
          To: {{ timeline.to }} | From: {{ timeline.from }}
        </p>
        <p class="body-small content" [innerHTML]="timeline.content"></p>
        <app-button
          *ngIf="timeline.document"
          [label]="timeline.document"
          trailingIcon="download"
          [class]="['xsmall', 'brand--Lightest', 'link']"
        ></app-button>
      </div>
      <div *ngIf="timeline.type === 'TimelineAppointment'" class="appointment">
        <div class="calendar">
          <div class="month tiny">{{ getMonth() }}</div>
          <div class="date-time">
            <h3 class="date text textLink">{{ getDay() }}</h3>
            <div class="time tiny textLink">{{ getTime() }}</div>
          </div>
        </div>
        <div class="description">
          <p *ngIf="timeline.subject" class="body-small bold">
            {{ timeline.subject }}
          </p>
          <p class="body-small">
            {{ getTime() }}
            -
            {{ getTime(timeline.scheduledDurationMinutes) }}
          </p>
          <p class="body-small content" [innerHTML]="timeline.content"></p>
        </div>
      </div>
      <div class="expand-icon">
        <i-tabler
          *ngIf="expandable"
          [name]="'chevron-' + (expand ? 'up' : 'down')"
        ></i-tabler>
      </div>
    </div>
  </div>
</div>
