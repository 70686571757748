import { Component, Input } from '@angular/core';
import { classes } from 'src/interface/shared.interface';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() class: classes[] = [];
  @Input() label = '';
  @Input() leadingIcon = '';
  @Input() trailingIcon = '';
  @Input() trailingInfo = '';
  @Input() style: string;
  @Input() disable: boolean;
  @Input() spinner: boolean;

  tooltipText(icon: string) {
    if (icon === 'info-circle-filled') {
      return 'You can only add consultants to the longlist. To add consultants to the shortlist, you’ll have to select the appropriate status.';
    }
    return '';
  }
}
