<app-selected-notification
  *ngIf="bulkSelect.length > 0 && !hideSelectNotification"
  (clear)="bulkSelect = []"
  (openMessage)="openBulkMessaging()"
  [selected]="bulkSelect"
></app-selected-notification>
<div class="list-card surface--2" [class]="{ longlist: longList }">
  <div *ngFor="let result of potentialResults | slice : 0 : listLimit ?? 1">
    <app-select-items
      (click)="checked(result)"
      [isChecked]="bulkSelect.includes(result)"
      *ngIf="type === 'requests'"
      [disabled]="result.declarationInformation ? true : false"
      type="checkbox"
    ></app-select-items>
    <div
      class="consultant-card"
      [class]="{ selected: bulkSelect.includes(result) }"
    >
      <div class="profile">
        <div class="image label1">{{ result.fullName[0] }}</div>
        <div
          *ngIf="result.score > 0"
          matTooltipClass="mat-tooltip "
          matTooltipPosition="below"
          matTooltip="Score"
          class="score caption"
        >
          {{ beautifyScore(result.score) }}
        </div>
      </div>
      <div class="content">
        <div class="top">
          <div class="info">
            <p class="subtitle3">
              {{ result.fullName }}
              <span
                matTooltipClass="mat-tooltip "
                matTooltipPosition="above"
                matTooltip="7N status"
                class="caption"
                >{{ result.crmStatus }}</span
              >
            </p>
          </div>
          <div class="links">
            <app-button
              matTooltipClass="mat-tooltip"
              (click)="openMessaging(result, 'replyMessage')"
              matTooltipPosition="above"
              matTooltip="{{ getDate(result.declarationInformation) }}"
              *ngIf="result.declarationInformation"
              [style]="'font-size:12px'"
              leadingIcon="message-dots"
              [label]="getStatus(result.declarationInformation)"
              trailingInfo="VIEW MESSAGE"
              [class]="['ghost', 'small']"
            ></app-button>
            <app-button
              *ngIf="type === 'requests' && !result.declarationInformation"
              matTooltipClass="mat-tooltip "
              matTooltipPosition="above"
              matTooltip="Send message"
              (click)="openMessaging(result, 'messaging')"
              [class]="['ghost', 'small']"
              trailingIcon="send"
            ></app-button>
            <app-button
              *ngIf="type !== 'requests'"
              matTooltipClass="mat-tooltip "
              matTooltipPosition="above"
              matTooltip="Add to longlist"
              (click)="openModal()"
              [class]="['ghost', 'small']"
              trailingIcon="playlist-add"
            ></app-button>
            <app-dropdown
              (click)="getExternalLinks(result)"
              [styles]="'width: fit-content; right: 0'"
              (selectedCallback)="externalLink($event)"
              [iconOnly]="true"
              trailingIcon="external-link"
              [options]="externalLinks"
            ></app-dropdown>
          </div>
        </div>
        <div class="contact-info">
          <div
            *ngFor="
              let info of ['map-pin', 'phone', 'at', 'user-circle'];
              let i = index
            "
          >
            <div
              matTooltipClass="mat-tooltip "
              matTooltipPosition="above"
              matTooltip="Location"
              class="body2 location"
              *ngIf="i === 0"
            >
              <i-tabler [name]="info"></i-tabler>
              <span class="city" *ngIf="result.city">{{ result.city }}, </span>
              <span class="country">{{ result.country }}</span>
            </div>
            <div
              matTooltipClass="mat-tooltip "
              matTooltipPosition="above"
              matTooltip="Click to copy"
              [cdkCopyToClipboard]="result.phoneNumber"
              class="body2 copy"
              *ngIf="i === 1"
            >
              {{ result.phoneNumber }}
            </div>
            <div
              matTooltipClass="mat-tooltip "
              matTooltipPosition="above"
              matTooltip="Click to copy"
              [cdkCopyToClipboard]="result.email"
              class="body2 copy"
              *ngIf="i === 2"
            >
              {{ result.email }}
            </div>
            <div
              matTooltipClass="mat-tooltip "
              matTooltipPosition="above"
              matTooltip="Owned by"
              class="body2"
              *ngIf="i === 3"
            >
              {{ result.ownedBy }}
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="roles">
            <div>
              <p class="caption">Main consultancy category</p>
              <div class="body2">
                {{ returnString(result.mainConsultancyCategory) }}
              </div>
            </div>
            <div>
              <p class="caption">IT role</p>
              <div class="body2">{{ returnString(result.itRole) }}</div>
            </div>
            <div>
              <p class="caption">Job title</p>
              <div class="body2">{{ returnString(result.jobTitle) }}</div>
            </div>
          </div>
          <div class="rate">
            <div>
              <p class="caption">Rate/hour</p>
              <div class="body2">
                {{ returnString(result.ratePerHour) }}
                {{ returnString(result.currency) }}
              </div>
            </div>
            <div>
              <p class="caption">Available from</p>
              <div class="body2">{{ returnString(result.availability) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="type === 'requests'" class="longListShort">
        <div class="agent">
          <!-- <i-tabler name="user-circle"></i-tabler> -->
          <div>Added by {{ result.addedBy }}</div>
        </div>
        <app-dropdown
          [styles]="'width: fit-content; right: 0'"
          trailingIcon="chevron-down"
          type="salesNotes"
          (selectedCallback)="selectedSaleNotes(result, $event)"
          [selected]="getSelected(result.salesStatus)"
          [salesStatus]="consultantstatus"
        ></app-dropdown>
        <app-input
          matTooltipClass="mat-tooltip "
          matTooltipPosition="above"
          matTooltip="Add/edit notes"
          (click)="openMatchModal(result)"
          [class]="'button'"
          [trailingIcon]="'pencil'"
          [value]="result.salesNotes ?? ''"
        ></app-input>
      </div>
    </div>
  </div>

  <ng-container *ngIf="potentialResults">
    <app-load-more
      *ngIf="listLimit <= potentialResults.length"
      [loadMoreLength]="10"
      (loadMore)="loadMore.emit(10)"
    ></app-load-more>
  </ng-container>
</div>
