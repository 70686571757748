<div class="request" *ngIf="!loading">
  <div>
    <div class="top surface--2">
      <app-button
        routerLink=".."
        [class]="['ghost']"
        leadingIcon="chevron-left"
        label="back to all requests"
      ></app-button>
      <app-button
        (click)="searchInMathing()"
        [class]="['primary']"
        label="Open in matching"
      ></app-button>
    </div>
    <div class="content">
      <div class="request-info surface--3">
        <div class="companyLogo">
          <i-tabler name="file-text"></i-tabler>
        </div>
        <div class="center">
          <div class="head">
            <div class="title">
              <div>
                <p class="label1">{{ selectedRequest.title }}</p>
                <div class="status">
                  <span
                    class="caption"
                    matTooltipClass="mat-tooltip "
                    matTooltipPosition="above"
                    matTooltip="status"
                    >{{ selectedRequest.status }}</span
                  >
                  <span
                    [class]="selectedRequest.priority.toLocaleLowerCase()"
                    class="caption"
                    >{{ selectedRequest.priority }}</span
                  >
                </div>
              </div>
              <div class="customer">
                <p class="body2">{{ selectedRequest.customer }}</p>
              </div>
            </div>
            <div class="agent">
              <div class="image label1">{{ selectedRequest.owner[0] }}</div>
              <div>
                <p class="body3">Sales agent</p>
                <p>{{ selectedRequest.owner }}</p>
              </div>
            </div>
          </div>
          <div class="info">
            <div class="profile">
              <div>
                <p class="body3">Main consultancy category:</p>
                <p class="body2">
                  {{ selectedRequest.mainConsultancyCategory }}
                </p>
              </div>
              <div>
                <p class="body3">It role:</p>
                <p class="body2">{{ selectedRequest.itRole }}</p>
              </div>
            </div>
            <div class="availability">
              <div>
                <p class="body3">Start date:</p>
                <p class="body2">
                  {{ ifEmptyReturnDash(selectedRequest.startDate) }}
                </p>
              </div>
              <div>
                <p class="body3">Salary:</p>
                <p class="body2">
                  {{ selectedRequest.maxCostPerHour }}
                  {{ selectedRequest.maxCostPerHourCurrency }}
                </p>
              </div>
            </div>
            <div class="responsible">
              <div
                matTooltipClass="mat-tooltip "
                matTooltipPosition="below"
                matTooltip="Created by"
                class="created-by"
              >
                <div class="image caption">
                  {{ selectedRequest.createdByName[0] }}
                </div>
                <div class="body2">
                  {{ selectedRequest.createdByName
                  }}<i>{{ getDate(selectedRequest.createdOn) }}</i>
                </div>
              </div>

              <div class="update-by">
                <div class="body2">
                  Updated at<i>{{ getDate(selectedRequest.modifiedOn) }}</i>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="viewLess" class="sum-desc">
            <div class="summary">
              <p class="label2">Summary</p>
              <p
                *ngIf="selectedRequest"
                [innerText]="selectedRequest.summary"
              ></p>
            </div>
            <div class="description">
              <p class="label2">Description</p>
              <p [innerText]="selectedRequest.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="taps surface--2">
    <div class="list-select">
      <div>
        <div
          (click)="selectList('longlist')"
          class="long-list"
          [class]="{ active: selectedList === 'longlist' }"
        >
          <p class="label2">Longlist</p>
          <span class="amount caption">{{
            selectedRequest.longlist.length
          }}</span>
        </div>
        <div
          (click)="selectList('shortlist')"
          class="short-list"
          [class]="{ active: selectedList === 'shortlist' }"
        >
          <p class="label2">Shortlist</p>
          <span class="amount caption">{{
            selectedRequest.shortlist.length
          }}</span>
        </div>
      </div>
      <p class="body2"><i>All content is synced with CRM</i></p>
    </div>
    <app-button
      (click)="addconsultant()"
      [class]="['primary']"
      label="Add consultant"
      leadingIcon="plus"
      trailingIcon="info-circle-filled"
    ></app-button>
  </div>
  <app-notification></app-notification>
  <!-- <div *ngFor="let result of potentialResults()">
    <app-level1 [consultant]="result"></app-level1>
  </div> -->
  <app-list-card
    (loadMore)="loadMore($event)"
    [listLimit]="loadLength"
    (updateResults)="getRequest()"
    [consultantstatus]="consultantstatus"
    type="requests"
    [potentialResults]="potentialResults()"
  ></app-list-card>
</div>
