import { Component, EventEmitter, Input, Output } from '@angular/core';
import { defaultDropdown } from 'src/interface/shared.interface';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
})
export class DropdownSearchComponent {
  @Input() searchable: boolean;
  @Input() placeholder: string;
  @Input() options: defaultDropdown[];
  @Input() selected: string;
  @Input() trailingIcon: string;
  @Input() multiSelect: boolean;
  @Output() selectedCallback = new EventEmitter<defaultDropdown>();
  toggleDropdown: boolean;
  allCountries = true;
  rawOptions: defaultDropdown[];
  search(event: Event) {
    const input = (event.target as HTMLInputElement).value.toLocaleLowerCase();
    if (!this.rawOptions) {
      this.rawOptions = this.options;
    }
    this.options = this.rawOptions.filter((x) =>
      x.displayName.toLocaleLowerCase().includes(input)
    );
  }
  getSelected(selected: string) {
    if (selected) {
      this.allCountries = false;
      const find = this.options.find((x) => x.value === selected) ;
      if (find) {
        return find.displayName;
      }
    }
    this.allCountries = true;
    return 'All countries';
  }

  selectOption(option: defaultDropdown) {
    this.selectedCallback.emit(option);
    this.toggleDropdown = !this.toggleDropdown;
    if (this.rawOptions) {
      this.options = this.rawOptions;
    }
  }
}
