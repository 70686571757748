import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { classes, nav } from 'src/interface/shared.interface';
import { FeatureFlagsService } from 'src/services/feature-flags/feature-flags.service';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() navs: nav[] = [];
  @Output() step = new EventEmitter<string>();
  @Input() selectedNav = '';
  @Input() type = '';
  @Input() openSidePanel: boolean;
  mobile = false;
  faPlus = faPlus;
  faMinus = faMinus;
  constructor(
    public contextService: ContextService,
    private featureFlags: FeatureFlagsService
  ) {
    window.onresize = () => (this.mobile = window.innerWidth <= 1180);
  }

  isNavMenu(nav: string) {
    return this.selectedNav.includes(nav);
  }
  selectNav(slug: string, submenu?: nav[]) {
    this.step.emit(slug);
    this.selectedNav = slug;
    window.scrollTo(0, 0);
    if (window.innerWidth <= 1180) {
      this.contextService.openSidePanel.next(false);
    }
    if (submenu) {
      const findex = this.contextService.toggleNav.findIndex((x) => x === slug);
      if (findex === -1) {
        this.contextService.toggleNav.push(slug);
      } else {
        this.contextService.toggleNav.splice(findex, 1);
      }
    }
  }
  getClasses(nav: nav) {
    const classes: classes[] = [nav.state ? 'completed' : 'default'];

    if (nav.slug === this.selectedNav) {
      classes.push('active');
    }
    return classes;
  }
  findSelectedNav(selectedNav: string, navs?: nav[] | undefined) {
    return navs?.some((x) => x.slug === selectedNav);
  }
}
