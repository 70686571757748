import { MatomoTracker } from 'ngx-matomo';
import { searchContext } from 'src/interface/shared.interface';

export function matomoMatching(
  matomoTracker: MatomoTracker,
  searchType: string,
  searchContext: searchContext,
) {
  matomoTracker.trackEvent('Matching', 'Hit search - ' + searchType);
  let agent = false;
  let weight = false;
  if (searchContext.qualification.length > 0) {
    agent = true;
  }
  if (searchContext.qualificationImportance.length > 0) {
    weight = true;
  }
  let trackString = '';
  if (agent && weight) {
    trackString = 'Incl. Agent input and weight';
  }
  if (!trackString) {
    if (agent) {
      trackString = 'Incl. Agent input';
    }
    if (weight) {
      trackString = 'Incl. weight';
    }
  }
  matomoTracker.trackEvent(
    'Matching',
    'Hit search - ' + searchType + ' ' + trackString
  );
}
