import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import {
  ConsultantInfo,
  declarationInformation,
  declarationOfInterest,
  declarationOfInterestReply,
  matchConsultants,
  matches,
} from 'src/interface/shared.interface';
import {
  dropdown,
  modalType,
} from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
})
export class ListCardComponent {
  @Input() potentialResults: ConsultantInfo[];
  @Input() consultantstatus: dropdown[];
  @Input() longList: boolean;
  @Input() listLimit = 10;
  @Input() type: string;
  @Output() updateResults = new EventEmitter();
  @Output() loadMore = new EventEmitter<number>();
  externalLinks: dropdown[] = [];
  count = 0;
  bulkSelect: ConsultantInfo[] = [];
  hideSelectNotification = false;
  constructor(
    private contextService: ContextService,
    private platformService: PlatformService,
    private matomoTracker: MatomoTracker,
    private helperFunctions: HelperFunctionsService
  ) {
    this.contextService.reRender.subscribe((val) => {
      if (val === 'sentMessage') {
        this.bulkSelect = [];
      }
      if (val === 'close') {
        this.hideSelectNotification = false;
      }
    });
  }

  checked(result: ConsultantInfo) {
    if (result.declarationInformation) {
      return;
    }
    const findex = this.bulkSelect.findIndex((x) => x === result);
    if (findex === -1) {
      this.bulkSelect.push(result);
      return;
    }
    this.bulkSelect.splice(findex, 1);
  }
  openMatchModal(result: ConsultantInfo | matchConsultants) {
    result = result as matchConsultants;
    this.contextService.selectedConsultant = result;
    this.contextService.openMatchModal = 'addNotes';
    this.matomoTracker.trackEvent('Request', 'Open modal - Add to notes');
  }
  selectedSaleNotes(result: ConsultantInfo, event: dropdown | matches) {
    event = event as dropdown;
    result.salesStatus = event.name!;

    this.selected(result);
  }
  selected(value: ConsultantInfo) {
    this.matomoTracker.trackEvent(
      'Request',
      'Changed sale status to - ' + value.salesStatus
    );
    this.platformService
      .putConsultantStatus(this.contextService.selectedRequest!.id, value)
      .then((response) => {
        if (response.status === 202) {
          const findex = this.consultantstatus.findIndex(
            (x) => x.name === value.salesStatus
          );
          if (findex < 2) {
            this.contextService.notification.next({
              description: 'Updated sales status for ' + value.fullName,
              type: 'success',
              show1: true,
              icon: 'sparkles',
            });
          } else {
            this.contextService.notification.next({
              description: value.fullName + ' is now moved to the shortlist',
              type: 'success',
              show1: true,
              icon: 'sparkles',
            });
          }
          this.updateResults.emit();
        }
      });
  }
  getDate(value: declarationInformation) {
    let text = 'Sent:';

    if (value.status === 'Interested' || value.status === 'NotInterested') {
      text = 'Received:';
    }

    if (value.statusChangedAt) {
      return text + ' ' + this.helperFunctions.getDate(value.statusChangedAt);
    }
    return text + ' ' + value.statusChangedAt;
  }
  getStatus(value: declarationInformation) {
    if (value.status === 'PendingReply') {
      return 'PENDING REPLY';
    }
    if (value.status === 'Interested') {
      return 'INTERESTED';
    }
    if (value.status === 'NotInterested') {
      return 'NOT INTERESTED';
    }
    if (value.status === 'NoReply') {
      return 'NO REPLY';
    }
    return '';
  }
  beautifyScore(score: number) {
    return score.toFixed(2).substring(2);
  }
  returnString(value: number | string) {
    if (!value || value === '') {
      return '-';
    }
    return value;
  }
  getSelected(value: string) {
    if (this.consultantstatus) {
      const find = this.consultantstatus.find((x) => x.name === value);
      return find;
    }
    return;
  }

  openBulkMessaging() {
    this.contextService.openMatchModal = 'messaging';
    const freelancers: string[] = [];
    this.bulkSelect.forEach((val: ConsultantInfo) => {
      freelancers.push(val.crmId);
    });
    this.platformService
      .declarationOfInterest(
        this.contextService.selectedRequest!.id,
        'post',
        undefined,
        { freelancers, languageCode: '' }
      )
      .then((response: declarationOfInterest) => {
        this.hideSelectNotification = true;
        this.contextService.claiming = response;
        this.contextService.reRender.next('messaging');
      });
  }

  openMessaging(result: ConsultantInfo, modal: modalType) {
    this.contextService.openMatchModal = modal;
    if (modal === 'replyMessage' && result.declarationInformation) {
      this.platformService
        .declarationOfInterest(
          result.declarationInformation.freelancerDeclarationOfInterestId,
          'getFree'
        )
        .then((response: declarationOfInterestReply) => {
          this.contextService.declarationOfInterestReply = response;
          this.contextService.reRender.next(modal);
        });
    }
    if (modal === 'messaging') {
      this.platformService
        .declarationOfInterest(
          this.contextService.selectedRequest!.id,
          'post',
          undefined,
          { freelancers: [result.crmId], languageCode: 'ENG' }
        )
        .then((response: declarationOfInterest) => {
          this.contextService.claiming = response;
          this.contextService.reRender.next(modal);
        });
    }
  }
  openModal() {
    this.contextService.openMatchModal = 'longlist';
    this.matomoTracker.trackEvent('Matching', 'Open modal - Add to longlist');
  }
  externalLink(value: matches | dropdown) {
    value = value as dropdown;
    if (value.link) {
      this.matomoTracker.trackEvent(
        this.type === 'requests' ? 'Request' : 'Matching',
        'External Link - ' + value.displayName
      );
      window.open(value.link);
    }
  }
  getExternalLinks(result: ConsultantInfo) {
    this.externalLinks = [];

    this.externalLinks.push({
      displayName: 'Open in CRM',
      imageIcon: 'assets/svg/table.svg',
      link: result.externalLink,
      disable: !result.externalLink,
    });

    this.externalLinks.push({
      displayName: 'Open in LinkedIn',
      icon: 'brand-linkedin',
      link: result.linkedIn,
      disable: !result.linkedIn,
    });


    this.externalLinks.push({
      displayName: 'Open in My7N',
      imageIcon: 'assets/svg/7n.svg',
      link: result?.my7N,
      disable: !result.my7N,
    });

    return this.externalLinks;
  }
}
