<div class="add-request">
  <app-search-request
    [selectedSearchType]="selectedSearchType"
    [selectedRequest]="selectedRequest"
    (selectrequest)="selectRequest.emit($event)"
    (manualRequest)="manualRequest.emit($event)"
  ></app-search-request>

  <div *ngIf="selectedRequest" class="selectedRequest">
    <!-- <app-level0
      (click)="selectconsultant(selectedconsultant)"
      [consultant]="selectedconsultant"
      [selected]="true"
      (close)="selectedconsultant = undefined; allconsultants = []"
    ></app-level0> -->
  </div>
</div>
